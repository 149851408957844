import React, { useState } from 'react'
import { useUIActions } from '../../store/UI/UIActions'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core'
// import { FilePicker } from 'react-file-picker'
import FilePicker from '../elements/FilePicker'
import SpinnerButton from '../elements/SpinnerButton'
import { useForm } from '../../hooks/Forms'
import { useStateContext } from '../../store/stateContext'
import { useProjectActions } from '../../store/Project/ProjectActions'
import KmlFile from '../../models/KmlFile'

const UploadOverlayDialog = ({ project, onSubmit }) => {
  const { state } = useStateContext()
  const { uploadOverlay } = useProjectActions()
  const { closeUploadOverlayDialog } = useUIActions()
  const {
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    file: 'required',
  })

  const [sending, setSending] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const closeForm = () => {
    closeUploadOverlayDialog()
  }

  const submitForm = async ({ file }) => {
    try {
      setSending(true)

      const kmlFile = new KmlFile(file)
      const structure = await kmlFile.toGeoJsonWithStructure()
      console.log(structure)

      const updatedProject = await uploadOverlay({
        project,
        name: `${project.id}-${file.name}`,
        directory: `${project.customerId}/${project.id}`,
        file,
        structure,
        progressCallback({ loaded, total }) {
          setUploadProgress(100 * (loaded / total))
        },
      })

      closeForm()
      onSubmit(updatedProject)
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll="body"
      open={state.ui.uploadOverlayDialogOpen}
      onClose={closeUploadOverlayDialog}
    >
      <form onSubmit={e => handleSubmit(submitForm, e)}>
        <DialogContent>
          <DialogTitle>Upload Overlay</DialogTitle>
          {sending ? (
            <LinearProgress variant={'determinate'} value={uploadProgress} />
          ) : (
            <Grid container spacing={1} alignItems="center">
              {formState.error && (
                <Grid item>
                  <Typography variant="body1" color="error">
                    {formState.error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <FilePicker
                  error={!!getFieldError('file')}
                  extensions={['kml', 'kmz']}
                  file={getValue('file')}
                  label="Overlay File"
                  helperText="Upload a kml or kmz file"
                  onChange={handleInputChange('file')}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeForm}>
            Cancel
          </Button>
          <SpinnerButton loading={sending} type="submit" valid={isValid()}>
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UploadOverlayDialog
