import React, { useState, useEffect } from 'react'
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/styles'
import AssetIcon from './AssetIcon'
import SystemIcon from './SystemIcon'
import { safeGet } from '../../util'

const useStyles = makeStyles(theme => ({
  cell: {
    border: 'none',
  },
  list: {
    maxHeight: '340px',
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      paddingRight: theme.spacing(1),
    },
  },
  parentItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  childItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  text: {
    paddingRight: theme.spacing(1),
  },
}))

const consolidateInventory = inventory => {
  const types = {}

  for (let item of inventory) {
    const typeName = safeGet('type.name', item) || 'Unknown'
    const subTypeName = safeGet('subType.name', item) || 'Unknown'

    if (!types[typeName]) {
      types[typeName] = {
        type: item.type,
        name: typeName.toUpperCase(),
        amount: 0,
        subTypes: {},
      }
    }

    types[typeName].amount++

    if (typeName !== subTypeName) {
      if (!types[typeName].subTypes[subTypeName]) {
        types[typeName].subTypes[subTypeName] = {
          subType: item.subType,
          name: subTypeName.toUpperCase(),
          amount: 0,
        }
      }

      types[typeName].subTypes[subTypeName].amount++
    }
  }

  return Object.values(types)
}

const InventoryPanel = ({ className, inventory = [] }) => {
  const classes = useStyles()
  const [list, setList] = useState([])
  const [opened, setOpened] = useState({})

  useEffect(() => {
    setList(consolidateInventory(inventory))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory])

  return (
    <Paper className={className} elevation={8}>
      <List className={classes.list}>
        {list.length === 0 && (
          <ListItem className={classes.parentItem}>
            <ListItemIcon>
              <SystemIcon name="icn_assets" />
            </ListItemIcon>
            <ListItemText primary="ASSETS" />
            <ListItemSecondaryAction>0</ListItemSecondaryAction>
          </ListItem>
        )}
        {list.length > 0 &&
          list
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((item, index) => (
              <div key={`wrapper-index${index}`}>
                <ListItem
                  className={classes.parentItem}
                  button
                  key={`inventory-item-${index}`}
                  onClick={() =>
                    setOpened({
                      ...opened,
                      [item.name]: !opened[item.name],
                    })
                  }
                >
                  <ListItemIcon>
                    <>
                      {Object.keys(item.subTypes).length > 0 &&
                        (opened[item.name] ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        ))}

                      <AssetIcon name={safeGet('type.iconName', item)} />
                    </>
                  </ListItemIcon>
                  <ListItemText primary={item.name} className={classes.text} />
                  <ListItemSecondaryAction>
                    {item.amount}
                  </ListItemSecondaryAction>
                </ListItem>

                <Collapse
                  key={`inventory-list-collapse-${index}`}
                  className={classes.nested}
                  in={opened[item.name]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {Object.values(item.subTypes)
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(
                        (childItem, childIndex) =>
                          childItem.name !== item.name && (
                            <ListItem
                              className={classes.childItem}
                              key={`inventory-child-${index}-${childIndex}`}
                            >
                              <ListItemIcon>
                                <AssetIcon
                                  name={safeGet('subType.iconName', childItem)}
                                />
                              </ListItemIcon>
                              <ListItemText primary={childItem.name} className={classes.text} />
                              <ListItemSecondaryAction>
                                {childItem.amount}
                              </ListItemSecondaryAction>
                            </ListItem>
                          ),
                      )}
                  </List>
                </Collapse>
              </div>
            ))}
      </List>
    </Paper>
  )
}

export default InventoryPanel
