import React from 'react'
import { SvgIcon } from '@material-ui/core'
import SVG from 'react-inlinesvg'
import getIcon from '../../libs/getIcon'

const AssetIcon = ({ name, color }) => {
  let icon = getIcon(name + '.svg')
  if (typeof icon === 'undefined') {
    icon = getIcon('question_mark.svg')
  }
  return <SvgIcon color={color}>{<SVG src={icon} />}</SvgIcon>
}

export default AssetIcon
