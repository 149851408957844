import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Icons from '../../assets/icons/symbol-defs.svg'
import { SvgIcon } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  svg: {
    fill: theme.colors.asseTracOrange,
  },
}))

const ConnectionStatus = ({ connected }) => {
  const classes = useStyles()

  return (
    <>
      {!connected && (
        <SvgIcon>
          <svg className={classes.svg}>
            <use xlinkHref={Icons + '#icn_no_signal'} />
          </svg>
        </SvgIcon>
      )}
    </>
  )
}

export default ConnectionStatus
