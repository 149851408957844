import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import AsseTracAppBar from '../elements/AsseTracAppBar'
import SystemIcon from '../elements/SystemIcon'
import CreateCustomerDialog from '../dialogs/CreateCustomerDialog'
import { useCustomerActions } from '../../store/Customer/CustomerActions'
import { useStateContext } from '../../store/stateContext'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'


const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  container: {
    margin: 0,
    marginTop: theme.spacing(10),
  },
  pageWrap: {
    height: '100%',
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  selectBox: {
    textAlign: 'left',
    minWidth: theme.spacing(16),
  },
  spinnerContainer: {
    minHeight: theme.spacing(25),
  },
}))

const CustomersPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    setActiveCustomer,
    updateCustomer,
  } = useCustomerActions()
  const { state } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [selected, setSelected] = useState(0)
  const [customers, setCustomers] = useState([])

  const handleStatusChange = async (customer, status) => {
    try {
      const allowedStatuses = ['Active', 'Inactive', 'Suspended']

      if (allowedStatuses.indexOf(status) < 0) {
        console.error('Status invalid.')
        return
      }

      await cancellablePromise(updateCustomer({
        id: customer.id,
        status,
      }))
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  const handleCustomerSelect = async index => {
    try {
      setSelected(index)
      await cancellablePromise(setActiveCustomer(customers[index]))
      history.push('/')
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }


  useEffect(() => {
    setCustomers(state.customers.customerList)
    if (state.customers.activeCustomer) {
      setSelected(state.customers.customerList.findIndex(customer => customer.id === state.customers.activeCustomer.id))
    }
  }, [state.customers])


  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.pageWrap}
        width="50%"
      >
        <AsseTracAppBar title="CUSTOMERS" />
        <Container maxWidth="sm" className={classes.container}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                color="secondary"
                variant="subtitle1"
                data-test="customerTotal"
              >
                {customers.length}
                {customers.length === 1 ? (
                  <span> customer</span>
                ) : (
                  <span> total customers</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
                variant="contained"
                data-test="createCustomerButton"
              >
                <SystemIcon name="icn_add" /> ADD CUSTOMER
              </Button>
            </Grid>
          </Grid>
          {
            <Grid container spacing={2} className={classes.listContainer}>
              {customers.map((customer, index) => (
                <Grid
                  container
                  item
                  key={`customer-row-${index}`}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <Switch
                      checked={selected === index}
                      onChange={() => handleCustomerSelect(index)}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="secondary" variant="subtitle1">
                      {customer.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} className={classes.alignRight}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectBox}
                    >
                      <Select
                        onChange={e =>
                          handleStatusChange(customer, e.target.value)
                        }
                        value={customer.status}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                        <MenuItem value="Suspended">Suspended</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        </Container>
      </Grid>
      <CreateCustomerDialog
        open={createDialogOpen}
        closeForm={() => setCreateDialogOpen(false)}
      />
    </>
  )
}

export default CustomersPage
