import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import AsseTracAppBar from '../elements/AsseTracAppBar'
import ChangePasswordDialog from '../dialogs/ChangePasswordDialog'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { useUserActions } from '../../store/User/UserActions'
import { useCancellablePromise, canceledPromiseErrorName } from '../../promiseUtils'
import { formatDate } from '../../util'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(6),
  },
  label: {
    color: theme.colors.portlandCement,
  },
  btnWrap: {
    marginTop: theme.spacing(6),
  },
  date: {
    display: 'inline-block',
  },
  progress: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
}))

const UsersPage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { userState } = useAuthActions()
  const { getUser } = useUserActions()
  const { cancellablePromise } = useCancellablePromise()
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(
    false,
  )
  const [user, setUser] = useState()

  const refreshUser = async () => {
    try {
      const tempUser = await cancellablePromise(getUser(userState.username))

      return tempUser
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.log(err)
    }
  }

  const handleChangePasswordClick = () => {
    setChangePasswordDialogOpen(true)
  }

  useEffect(() => {
    cancellablePromise(refreshUser())
      .then(setUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.pageWrap}
        width="50%"
      >
        <AsseTracAppBar title="MY ACCOUNT" />
        <Container maxWidth="md" className={classes.container}>
          {userState && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6">
                  {`${userState.attributes.email}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.date}
                  color="secondary"
                  variant="body1"
                >
                  <span className={classes.label}>User since:</span>{' '}
                  {user && formatDate(user.UserCreateDate, 'M/D/Y')}
                </Typography>
                {!user && (
                  <CircularProgress
                    className={classes.progress}
                    size={12}
                    variant="indeterminate"
                  />
                )}
              </Grid>
              <Grid item xs={12} className={classes.btnWrap}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleChangePasswordClick}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </Grid>
      <ChangePasswordDialog
        closeForm={() => setChangePasswordDialogOpen(false)}
        open={changePasswordDialogOpen}
        onSave={() =>
          enqueueSnackbar('Successfully changed password.', {
            variant: 'success',
          })
        }
      />
    </>
  )
}

export default UsersPage
