import React from 'react'

import { formatDateTime } from '../../util'

export default ({ assets }) => {
  assets = assets || []

  // only care about 1 asset for now
  const asset = assets.shift() || {}

  return (
    <dl>
      <dt>Name:</dt>
      <dd>{asset.name}</dd>

      <dt>Description:</dt>
      <dd>{asset.description}</dd>

      <dt>Gateway ID:</dt>
      <dd>{asset.gatewayId}</dd>

      <dt>Version:</dt>
      <dd>{asset.version}</dd>

      <dt>First Check In</dt>
      <dd>{formatDateTime(asset.firstCheckedIn)}</dd>

      <dt>Last Check In</dt>
      <dd>{formatDateTime(asset.lastCheckedIn)}</dd>

      <dt>Activated On:</dt>
      <dd>{formatDateTime(asset.activatedOn)}</dd>
    </dl>
  )
}
