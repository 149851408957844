export const INIT_UI = 'INIT_UI'
export const SET_DRAWER_NAV_OPEN = 'SET_DRAWER_NAV_OPEN'
export const SET_ASSET_INFO_OPEN = 'SET_ASSET_INFO_OPEN'
export const SET_NEW_PROJECT_DIALOG_OPEN = 'SET_NEW_PROJECT_DIALOG_OPEN'
export const SET_UPDATE_PROJECT_DIALOG_OPEN = 'SET_UPDATE_PROJECT_DIALOG_OPEN'
export const SET_INACTIVATE_PROJECT_DIALOG_OPEN =
  'SET_INACTIVATE_PROJECT_DIALOG_OPEN'
export const SET_UPLOAD_OVERLAY_DIALOG_OPEN = 'SET_UPLOAD_OVERLAY_DIALOG_OPEN'
export const SET_SEARCH_BAR_VISIBLE = 'SET_SEARCH_BAR_VISIBLE'
export const SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN =
  'SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN'
export const SET_FULL_SCREEN_PROJECT_MAP_OPEN =
  'SET_FULL_SCREEN_PROJECT_MAP_OPEN'
export const SET_DRAWER_ANOMALY_OPEN = 'SET_DRAWER_ANOMALY_OPEN'
export const SET_RETIRE_ASSET_DIALOG_OPEN = 'SET_RETIRE_ASSET_DIALOG_OPEN'
export const SET_EDIT_ASSET_DIALOG_OPEN = 'SET_EDIT_ASSET_DIALOG_OPEN'
export const SET_DRAWER_SEARCH_OPEN = 'SET_DRAWER_SEARCH_OPEN'

export const initialState = {
  drawerNavOpen: false,
  assetInfoOpen: false,
  newProjectDialogOpen: false,
  updateProjectDialogOpen: false,
  inactivateProjectDialogOpen: false,
  uploadOverlayDialogOpen: false,
  tempDrawerMapOverlaysOpen: false,
  projectMapFullScreen: false,
  drawerAnomalyOpen: false,
  retireAssetDialogOpen: false,
  editAssetDialogOpen: false,
  drawerSearchOpen: false,
}

export const reducer = (ui = initialState, { type, payload }) => {
  switch (type) {
    case INIT_UI:
      return {
        ...initialState,
      }
    case SET_DRAWER_NAV_OPEN:
      const { drawerNavOpen } = payload

      return {
        ...ui,
        drawerNavOpen,
      }
    case SET_ASSET_INFO_OPEN:
      const { assetInfoOpen } = payload

      return {
        ...ui,
        assetInfoOpen,
      }
    case SET_NEW_PROJECT_DIALOG_OPEN:
      const { newProjectDialogOpen } = payload

      return {
        ...ui,
        newProjectDialogOpen,
      }
    case SET_UPDATE_PROJECT_DIALOG_OPEN:
      const { updateProjectDialogOpen } = payload

      return {
        ...ui,
        updateProjectDialogOpen,
      }
    case SET_INACTIVATE_PROJECT_DIALOG_OPEN:
      const { inactivateProjectDialogOpen } = payload

      return {
        ...ui,
        inactivateProjectDialogOpen,
      }
    case SET_UPLOAD_OVERLAY_DIALOG_OPEN:
      const { uploadOverlayDialogOpen } = payload

      return {
        ...ui,
        uploadOverlayDialogOpen,
      }
    case SET_SEARCH_BAR_VISIBLE:
      const { searchBarVisible } = payload

      return {
        ...ui,
        searchBarVisible,
      }
    case SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN:
      const { tempDrawerMapOverlaysOpen } = payload

      return {
        ...ui,
        tempDrawerMapOverlaysOpen,
      }
    case SET_FULL_SCREEN_PROJECT_MAP_OPEN:
      const { projectMapFullScreen } = payload

      return {
        ...ui,
        projectMapFullScreen,
      }
    case SET_DRAWER_ANOMALY_OPEN:
      const { drawerAnomalyOpen } = payload

      return {
        ...ui,
        drawerAnomalyOpen,
      }
    case SET_RETIRE_ASSET_DIALOG_OPEN:
      const { retireAssetDialogOpen } = payload

      return {
        ...ui,
        retireAssetDialogOpen,
      }
    case SET_EDIT_ASSET_DIALOG_OPEN:
      const { editAssetDialogOpen } = payload

      return {
        ...ui,
        editAssetDialogOpen,
      }
    case SET_DRAWER_SEARCH_OPEN:
      const { drawerSearchOpen } = payload

      return {
        ...ui,
        drawerSearchOpen,
      }
    default:
      return ui
  }
}
