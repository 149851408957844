import { combineReducers } from './combineReducers'
import { reducer as assetsReducer } from './Assets/AssetsReducer'
import { reducer as authReducer } from './Auth/AuthReducer'
import { reducer as customerReducer } from './Customer/CustomerReducer'
import { reducer as projectReducer } from './Project/ProjectReducer'
import { reducer as uiReducer } from './UI/UIReducer'
import { reducer as mapReducer } from './Map/MapReducer'
import { reducer as reportsReducer } from './Reports/ReportsReducer'
import { reducer as userReducer } from './User/UserReducer'
import { reducer as searchReducer } from './Search/SearchReducer'
import { reducer as constantsReducer } from './Constants/ConstantsReducer'

const [reducer, initialState] = combineReducers({
  assets: assetsReducer,
  auth: authReducer,
  customers: customerReducer,
  projects: projectReducer,
  ui: uiReducer,
  map: mapReducer,
  reports: reportsReducer,
  users: userReducer,
  search: searchReducer,
  constants: constantsReducer,
})

export { reducer, initialState }
