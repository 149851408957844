import React, { createRef, useEffect, useState } from 'react'
import { Toolbar, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ToolbarToggleButton from './ToolbarToggleButton'
import MapFilterMenu from './MapFilterMenu'
import { useAssetsActions } from '../../store/Assets/AssetsActions'
import { useStateContext } from '../../store/stateContext'

const useStyles = makeStyles(theme => ({
  toolbarWrap: {
    width: '100%',
  },
  toolbar: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottomColor: theme.colors.lightSteel,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  toolbarIconButton: {
    padding: 8,
  },
  openFavoritesDrawerButton: {
    marginLeft: 'auto',
  },
}))

const MapToolbar = ({
  projectList,
  setProjectList,
  projectsFilter,
  setProjectsFilter,
  assetsFilter,
  setAssetsFilter,
  setAnomalyFilter,
  anomalyFilter,
}) => {
  const classes = useStyles()
  const projectFilterButton = createRef()
  const assetsFilterButton = createRef()
  const [openMenu, setOpenMenu] = useState(null)
  const [assetTypeList, setAssetTypeList] = useState([])
  const { getAssetTypes } = useAssetsActions()
  const { state } = useStateContext()
  const handleToolbarButtonClick = event => {
    setOpenMenu(event.currentTarget.id)
  }
  const handleClose = () => {
    setOpenMenu(null)
  }

  const handelAnomalyFilterChange = (event, anomaly) => {
    if (anomalyFilter === anomaly) {
      setAnomalyFilter(null)
      return
    }
    setAssetsFilter([])
    setProjectsFilter({
      checkFilter: [],
      textFilter: '',
    })
    setAnomalyFilter(anomaly)
  }

  const isLit = filter => {
    if (!filter || !filter.checkFilter) return
    return filter.checkFilter.length > 0
  }

  useEffect(() => {
    const results = getAssetTypes()

    const types = results.reduce(
      (acc, type) => {
        acc.push({
          id: type.name.toLowerCase(),
          name: type.name,
          children: [type.subType],
        })
        return acc
      },
      [
        {
          id: '',
          name: 'Unknown',
        },
      ],
    )
    // TODO change to all instead of unknown
    setAssetTypeList(types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assets.assetTypes])

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <ToolbarToggleButton
        lit={isLit(projectsFilter)}
        id="projectFilter"
        icon="#icn_project"
        ref={projectFilterButton}
        onClick={handleToolbarButtonClick}
      />
      <MapFilterMenu
        open={openMenu === 'projectFilter'}
        onClose={handleClose}
        onChange={setProjectsFilter}
        getData={projectList}
        setData={setProjectList}
        anchor={projectFilterButton}
        filters={projectsFilter}
        setAnomalyFilter={setAnomalyFilter}
        defaultChecked
      />
      <ToolbarToggleButton
        lit={isLit(assetsFilter)}
        id="assetsFilter"
        icon="#icn_assets"
        ref={assetsFilterButton}
        onClick={handleToolbarButtonClick}
      />
      <MapFilterMenu
        open={openMenu === 'assetsFilter'}
        onClose={handleClose}
        onChange={setAssetsFilter}
        getData={assetTypeList}
        setData={setAssetTypeList}
        anchor={assetsFilterButton}
        filters={assetsFilter}
        setAnomalyFilter={setAnomalyFilter}
        defaultChecked
      />
      <Divider orientation="vertical" className={classes.divider} />
      <ToolbarToggleButton
        icon="#icn_battery_alert"
        lit={anomalyFilter === 'Low Battery'}
        onClick={e => {
          handelAnomalyFilterChange(e, 'Low Battery')
        }}
      />
      <ToolbarToggleButton
        icon="#icn_signal_wifi_off"
        lit={anomalyFilter === 'Lost Connection'}
        onClick={e => {
          handelAnomalyFilterChange(e, 'Lost Connection')
        }}
      />
    </Toolbar>
  )
}

export default MapToolbar
