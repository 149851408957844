import { useEffect, useRef } from 'react'
import { boundsViewport, safeGet } from '../../util'
import { useStateContext } from '../stateContext'
import {
  SET_VIEWPORT,
  UPDATE_VIEWPORT,
  SET_GEOJSONLAYERS,
  ADD_GEOJSONLAYERS,
  REMOVE_GEOJSONLAYERS,
  SET_WAYPOINTLAYERS,
  SET_PATHLAYERS,
  SET_ICONLAYERS,
  SET_TOOLTIP,
  SET_FILTERED_LAYERS,
  UPDATE_CURRENT_MAPID,
} from './MapReducer'
import MovesLayer from '../../map-layers/MovesLayer'
import BoundaryLayer from '../../map-layers/BoundaryLayer'
import OverlayLayer from '../../map-layers/OverlayLayer'
import ClustersLayer from '../../map-layers/ClustersLayer'

export const MAX_ZOOM = 16

let mapInst = null

export const useMapActions = () => {
  const { state, dispatch } = useStateContext()
  const mapRef = useRef()

  useEffect(() => {
    if (mapRef.current) {
      mapInst = mapRef.current.getMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.map.viewport, state.map.currentMapId])

  // update filteredLayers
  useEffect(() => {
    const filteredLayers = []
    // console.log(state.map.geoJsonLayers)
    //    for (let id in state.map.geoJsonLayers) {
    // console.log(state.map.geoJsonLayers[id])
    // console.log(id)
    if (state.map.geoJsonLayers && state.map.geoJsonLayers.length > 0) {
      // let features = []
      // for (const [key, value] of Object.entries(state.map.geoJsonLayers)) {
      //   console.log(`${key}: ${value}`);
      //   features.push(value)
      // }
      filteredLayers.push(
        new OverlayLayer({
          //id: `geoJson-${id}`,
          data: state.map.geoJsonLayers,
        }),
      )
    }
    //  }

    // create paths (project boundaries)
    if (state.map.pathLayers) {
      let paths = Object.values(state.map.pathLayers)

      filteredLayers.push(
        new BoundaryLayer({
          id: `boundary-layer-${filteredLayers.length}`,
          data: paths,
          pickable: true,
          suppressIcon: state.map.suppressIcon,
          onTooltip: tooltip => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      )
    }

    // create moves layers
    if (state.map.waypointLayers) {
      let waypoints = Object.values(state.map.waypointLayers)

      filteredLayers.push(
        new MovesLayer({
          id: 'waypoint-layer',
          data: waypoints,
          pickable: true,
          onTooltip: tooltip => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      )
    }

    // create icon layer
    if (state.map.iconLayers) {
      let icons = Object.values(state.map.iconLayers)

      filteredLayers.push(
        new ClustersLayer({
          id: 'clusters-layer',
          data: icons,
          pickable: true,
          onClick(info, event) {
            //console.log(info)
            const callback = safeGet('object.properties.onClick', info)
            if (typeof callback === 'function') {
              return callback(info, event)
            }
          },
          onTooltip: tooltip => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      )
    }
    //console.log(filteredLayers)
    dispatch({
      type: SET_FILTERED_LAYERS,
      payload: { filteredLayers },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.map.geoJsonLayers,
    state.map.iconLayers,
    state.map.pathLayers,
    state.map.suppressIcon,
    state.map.waypointLayers,
  ])

  const setViewport = async viewport => {
    dispatch({ type: SET_VIEWPORT, payload: { viewport } })
  }

  const updateCurrentMapId = async id => {
    dispatch({ type: UPDATE_CURRENT_MAPID, payload: { id } })
  }

  const updateViewport = async viewport => {
    dispatch({ type: UPDATE_VIEWPORT, payload: { viewport } })
  }

  const visibleOnMap = coordinates => {
    if (!mapInst) {
      return false
    }

    const bounds = mapInst.getBounds()

    if (!coordinates) {
      return false
    }

    const { latitude, longitude } = coordinates

    // filter bad results
    if (!latitude || !longitude) {
      return false
    }

    if (latitude < bounds._sw.lat || latitude > bounds._ne.lat) {
      return false
    }

    if (longitude < bounds._sw.lng || longitude > bounds._ne.lng) {
      return false
    }

    return true
  }

  const zoomToCoordinates = async coordinates => {
    const { latitude, longitude, zoom } =
      boundsViewport(coordinates, state.map.viewport) || {}

    if (
      !latitude ||
      !longitude ||
      !zoom ||
      !state.map.viewport.width ||
      !state.map.viewport.height
    ) {
      return
    }

    const viewport = {
      ...state.map.viewport,
      latitude,
      longitude,
      zoom: zoom > MAX_ZOOM ? MAX_ZOOM : zoom,
    }
    setViewport(viewport)

    return viewport
  }

  const clearLayers = () => {
    dispatch({
      type: SET_GEOJSONLAYERS,
      payload: {
        geoJsonLayers: [],
      },
    })

    dispatch({
      type: SET_WAYPOINTLAYERS,
      payload: {
        waypointLayers: {},
      },
    })

    dispatch({
      type: SET_ICONLAYERS,
      payload: {
        iconLayers: {},
      },
    })

    dispatch({
      type: SET_PATHLAYERS,
      payload: {
        pathLayers: {},
      },
    })
  }

  const addGeoJsonLayer = (id, geoJson) => {
    //console.log(id)
    dispatch({
      type: ADD_GEOJSONLAYERS,
      payload: {
        geoJsonLayer: geoJson,
      },
    })
  }

  const removeGeoJsonLayer = (id, geoJson) => {
    // console.log(id)
    dispatch({
      type: REMOVE_GEOJSONLAYERS,
      payload: {
        geoJsonLayer: geoJson,
      },
    })
  }

  const addWaypointLayer = (id, points) => {
    //console.log(points)
    dispatch({
      type: SET_WAYPOINTLAYERS,
      payload: {
        waypointLayers: {
          ...state.map.waypointLayers,
          [id]: points,
        },
      },
    })
  }

  return {
    addWaypointLayer,
    addGeoJsonLayer,
    removeGeoJsonLayer,
    clearLayers,
    mapRef,
    setViewport,
    updateCurrentMapId,
    updateViewport,
    visibleOnMap,
    zoomToCoordinates,
  }
}
