import React, { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

import ClientPicker from '../elements/ClientPicker'
import { makeStyles } from '@material-ui/core/styles'
import { useStateContext } from '../../store/stateContext'
import { useUIActions } from '../../store/UI/UIActions'
import { useProjectActions } from '../../store/Project/ProjectActions'
import { useForm } from '../../hooks/Forms'
import SpinnerButton from '../elements/SpinnerButton'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  datepicker: {
    width: '100%',
  },
}))

const UpdateProjectDialog = ({ project, onSubmit }) => {
  const classes = useStyles()
  project = project || {}

  const { closeUpdateProjectDialog } = useUIActions()
  const { updateProject } = useProjectActions()

  // app level state
  const [sending, setSending] = useState(false)
  const [projectNames, setProjectNames] = useState([])
  const { state } = useStateContext()
  const projectsState = state.projects

  // component level state
  const {
    inputs,
    setInputs,
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
    validateForm,
  } = useForm({
    name: `required|unique:${projectNames.join(',')}`,
    client: 'required',
  })
  useEffect(() => {
    // Set up the validation rules
    // for the name using the projectList
    const names = projectsState.projectList.reduce((acc, cur) => {
      acc.push(cur.name)
      return acc
    }, [])
    if (project.name) {
      names.splice(names.indexOf(project.name), 1)
    }
    setProjectNames(names)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState])

  // this const forces a validateForm when the
  // the ClientPicker finally loads
  const client = getValue('client')

  // update project name if changed
  useEffect(() => {
    setInputs({
      ...inputs,
      name: project.name,
      client: project.client,
      description: project.description,
      startDate: project.startDate,
      endDate: project.endDate,
    })
    validateForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project.name,
    project.client,
    project.description,
    project.startDate,
    project.endDate,
  ])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]) // force validateForm() when ClientPicker Loads

  // component level actions
  const clearForm = () => {
    setSending(false)
    clearFormState()
  }

  const cancelForm = () => {
    closeUpdateProjectDialog()
    clearForm()
  }

  /**
   * Submits the form
   * @param {object} form  Object containing form inputs
   */
  const submitForm = async form => {
    try {
      setSending(true)
      const updatedProject = await updateProject({
        ...form,
        id: project.id,
      })

      // call onSubmit prop
      if (typeof onSubmit === 'function') {
        onSubmit(updatedProject)
      }

      closeUpdateProjectDialog()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll="body"
      open={state.ui.updateProjectDialogOpen}
      onClose={closeUpdateProjectDialog}
    >
      <form onSubmit={e => handleSubmit(submitForm, e)}>
        <DialogContent>
          <DialogTitle>EDIT PROJECT</DialogTitle>
          {formState.error && (
            <Grid item>
              <Typography variant="body1" color="error">
                {formState.error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              autoFocus
              error={!!getFieldError('name')}
              helperText={getFieldError('name')}
              id="project-name"
              label="Project Name"
              fullWidth
              margin="normal"
              onChange={handleInputChange('name')}
              value={getValue('name', project.name)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <ClientPicker
              error={!!getFieldError('client')}
              helperText={getFieldError('client')}
              value={getValue('client', project.client)}
              onChange={handleInputChange('client')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-test="updateProjectDialogDesc"
              autoComplete="off"
              error={!!getFieldError('description')}
              fullWidth
              helperText={getFieldError('description')}
              id="project-description"
              label="Project Description"
              margin="normal"
              multiline
              onChange={handleInputChange('description')}
              rowsMax="4"
              name="description"
              value={getValue('description', project.description)}
              variant="outlined"
            />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  autoOk
                  className={classes.datepicker}
                  disableToolbar
                  format="MM/dd/yyyy"
                  id="start-date"
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  label="Start Date"
                  margin="normal"
                  name="startDate"
                  onChange={handleInputChange('startDate')}
                  value={getValue('startDate', project.startDate)}
                  variant="inline"
                  data-test="createProjectDialogStartDate"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  autoOk
                  name="endDate"
                  className={classes.datepicker}
                  disableToolbar
                  format="MM/dd/yyyy"
                  id="end-date"
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  label="End Date"
                  margin="normal"
                  onChange={handleInputChange('endDate')}
                  value={getValue('endDate', project.endDate)}
                  variant="inline"
                  data-test="createProjectDialogEndDate"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={cancelForm}>
            Cancel
          </Button>
          <SpinnerButton loading={sending} type="submit" valid={isValid()}>
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UpdateProjectDialog
