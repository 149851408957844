import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { Grid, Card, CardContent, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  note: {
    textAlign: 'center',
    color: theme.colors.portlandCement,
  },
  card: {
    minWidth: 264,
    cursor: 'pointer',
    height: '100%',
  },
  content: {
    display: 'flex',
    direction: 'row',
  },
  projectName: {
    color: theme.colors.quillFeather,
  },
  projectClient: {
    color: theme.colors.portlandCement,
  },
  controls: {
    display: 'flex',
    paddingLeft: 8,
    marginLeft: 'auto',
    alignItems: 'start',
  },
}))

export default ({ projects }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      {projects.length === 0 && (
        <Grid container item xs justify="space-around">
          <Typography className={classes.note}>
            There are no projects to display
          </Typography>
        </Grid>
      )}
      {projects.map(p => {
        return (
          <Grid item key={p.name} xs={12} sm={6} md={4} lg={3}>
            <Link to={'/Projects/' + p.id}>
              <Card className={classes.card} data-test={'project' + p.name}>
                <CardContent className={classes.content}>
                  <div className={classes.details}>
                    <Typography className={classes.projectName} variant="h6">
                      {p.name}
                    </Typography>
                    <Typography
                      className={classes.projectClient}
                      variant="subtitle1"
                    >
                      {p.client}
                    </Typography>
                  </div>
                  <div className={classes.controls} />
                </CardContent>
              </Card>
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}
