/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
      }
      supplier {
        id
        name
        createdAt
        updatedAt
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
      }
      movedTs
      movedProjectId
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        type {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        subType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        dimensions {
          id
          name
          createdAt
          updatedAt
        }
        supplier {
          id
          name
          createdAt
          updatedAt
        }
        eventTs
        receivedTs
        firstCheckedIn
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingCoordinate {
          latitude
          longitude
        }
        movingTs
        movingStartTs
        movedCoordinate {
          latitude
          longitude
        }
        movedTs
        movedProjectId
      }
      nextToken
    }
  }
`;
export const getAssetHistory = /* GraphQL */ `
  query GetAssetHistory($assetId: ID!, $eventTs: Int!) {
    getAssetHistory(assetId: $assetId, eventTs: $eventTs) {
      assetId
      eventTs
      uuid
      deviceId
      unitNumber
      customerId
      receivedTs
      coordinate {
        latitude
        longitude
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      userGroups
      adminGroups
      projectId
      assetType {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssetHistorys = /* GraphQL */ `
  query ListAssetHistorys(
    $assetId: ID
    $eventTs: ModelIntKeyConditionInput
    $filter: ModelAssetHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetHistorys(
      assetId: $assetId
      eventTs: $eventTs
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        assetId
        eventTs
        uuid
        deviceId
        unitNumber
        customerId
        receivedTs
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        userGroups
        adminGroups
        projectId
        assetType {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        assetSubType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetMovementCoordinate = /* GraphQL */ `
  query GetAssetMovementCoordinate(
    $movementId: ID!
    $source: String!
    $index: Int!
  ) {
    getAssetMovementCoordinate(
      movementId: $movementId
      source: $source
      index: $index
    ) {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const listAssetMovementCoordinates = /* GraphQL */ `
  query ListAssetMovementCoordinates(
    $movementId: ID
    $sourceIndex: ModelAssetMovementCoordinatePrimaryCompositeKeyConditionInput
    $filter: ModelAssetMovementCoordinateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetMovementCoordinates(
      movementId: $movementId
      sourceIndex: $sourceIndex
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        movementId
        source
        index
        eventTs
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetMovementReport = /* GraphQL */ `
  query GetAssetMovementReport($id: ID!) {
    getAssetMovementReport(id: $id) {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
      }
      movedFromCoordinate {
        latitude
        longitude
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssetMovementReports = /* GraphQL */ `
  query ListAssetMovementReports(
    $filter: ModelAssetMovementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetMovementReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        assetId
        startTs
        stopTs
        state
        onRoadDistance
        offRoadDistance
        totalDistanceTraveled
        movedToCoordinate {
          latitude
          longitude
        }
        movedFromCoordinate {
          latitude
          longitude
        }
        prevProjectId
        destProjectId
        customerId
        firstMove
        userGroups
        adminGroups
        assetTypeId
        assetType {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        assetSubTypeId
        assetSubType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetType = /* GraphQL */ `
  query GetAssetType($id: ID!) {
    getAssetType(id: $id) {
      id
      name
      subType {
        items {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        nextToken
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const listAssetTypes = /* GraphQL */ `
  query ListAssetTypes(
    $filter: ModelAssetTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetSubType = /* GraphQL */ `
  query GetAssetSubType($id: ID!) {
    getAssetSubType(id: $id) {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const listAssetSubTypes = /* GraphQL */ `
  query ListAssetSubTypes(
    $filter: ModelAssetSubTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetSubTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDimensions = /* GraphQL */ `
  query GetDimensions($id: ID!) {
    getDimensions(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listDimensionss = /* GraphQL */ `
  query ListDimensionss(
    $filter: ModelDimensionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimensionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        items {
          id
          name
          customerId
          client
          description
          startDate
          endDate
          status
          userGroups
          adminGroups
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          uuid
          createdAt
          updatedAt
          status
          customerId
          deviceId
          name
          userGroups
          adminGroups
          description
          version
          unitNumber
          make
          model
          year
          capacity
          activatedOn
          deactivatedOn
          toolType
          size
          annualInspection
          eventTs
          receivedTs
          firstCheckedIn
          altitude
          battery
          rssi
          accelerometer
          speed
          course
          projectId
          movingStatus
          movingTs
          movingStartTs
          movedTs
          movedProjectId
        }
        nextToken
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        description
        status
        assetsVersion
        projects {
          nextToken
        }
        assets {
          nextToken
        }
        userGroups
        adminGroups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
      }
      overlays {
        name
        file {
          bucket
          key
          region
        }
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customerId
        client
        description
        boundaries {
          latitude
          longitude
        }
        overlays {
          name
        }
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const assetByUuid = /* GraphQL */ `
  query AssetByUuid(
    $uuid: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetByUuid(
      uuid: $uuid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        type {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        subType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        dimensions {
          id
          name
          createdAt
          updatedAt
        }
        supplier {
          id
          name
          createdAt
          updatedAt
        }
        eventTs
        receivedTs
        firstCheckedIn
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingCoordinate {
          latitude
          longitude
        }
        movingTs
        movingStartTs
        movedCoordinate {
          latitude
          longitude
        }
        movedTs
        movedProjectId
      }
      nextToken
    }
  }
`;
export const assetsByCustomer = /* GraphQL */ `
  query AssetsByCustomer(
    $customerId: ID
    $projectId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByCustomer(
      customerId: $customerId
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        type {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        subType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        dimensions {
          id
          name
          createdAt
          updatedAt
        }
        supplier {
          id
          name
          createdAt
          updatedAt
        }
        eventTs
        receivedTs
        firstCheckedIn
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingCoordinate {
          latitude
          longitude
        }
        movingTs
        movingStartTs
        movedCoordinate {
          latitude
          longitude
        }
        movedTs
        movedProjectId
      }
      nextToken
    }
  }
`;
export const reportsByCustomer = /* GraphQL */ `
  query ReportsByCustomer(
    $customerId: String
    $destProjectIdStopTs: ModelAssetMovementReportByCustomerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetMovementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCustomer(
      customerId: $customerId
      destProjectIdStopTs: $destProjectIdStopTs
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        assetId
        startTs
        stopTs
        state
        onRoadDistance
        offRoadDistance
        totalDistanceTraveled
        movedToCoordinate {
          latitude
          longitude
        }
        movedFromCoordinate {
          latitude
          longitude
        }
        prevProjectId
        destProjectId
        customerId
        firstMove
        userGroups
        adminGroups
        assetTypeId
        assetType {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        assetSubTypeId
        assetSubType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectsByCustomerId = /* GraphQL */ `
  query ProjectsByCustomerId(
    $customerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        customerId
        client
        description
        boundaries {
          latitude
          longitude
        }
        overlays {
          name
        }
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchAssets = /* GraphQL */ `
  query SearchAssets(
    $filter: SearchableAssetFilterInput
    $sort: SearchableAssetSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAssets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        type {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        subType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        dimensions {
          id
          name
          createdAt
          updatedAt
        }
        supplier {
          id
          name
          createdAt
          updatedAt
        }
        eventTs
        receivedTs
        firstCheckedIn
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingCoordinate {
          latitude
          longitude
        }
        movingTs
        movingStartTs
        movedCoordinate {
          latitude
          longitude
        }
        movedTs
        movedProjectId
      }
      nextToken
      total
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: SearchableProjectSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        customerId
        client
        description
        boundaries {
          latitude
          longitude
        }
        overlays {
          name
        }
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
