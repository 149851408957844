/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset($customerId: ID!, $projectId: ID) {
    onUpdateAsset(customerId: $customerId, projectId: $projectId) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
      }
      supplier {
        id
        name
        createdAt
        updatedAt
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
      }
      movedTs
      movedProjectId
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset($customerId: ID!, $projectId: ID) {
    onCreateAsset(customerId: $customerId, projectId: $projectId) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
      }
      supplier {
        id
        name
        createdAt
        updatedAt
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
      }
      movedTs
      movedProjectId
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($customerId: ID!) {
    onUpdateProject(customerId: $customerId) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
      }
      overlays {
        name
        file {
          bucket
          key
          region
        }
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($customerId: ID!) {
    onCreateProject(customerId: $customerId) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
      }
      overlays {
        name
        file {
          bucket
          key
          region
        }
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssetMovementCoordinate = /* GraphQL */ `
  subscription OnCreateAssetMovementCoordinate {
    onCreateAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetMovementCoordinate = /* GraphQL */ `
  subscription OnUpdateAssetMovementCoordinate {
    onUpdateAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetMovementCoordinate = /* GraphQL */ `
  subscription OnDeleteAssetMovementCoordinate {
    onDeleteAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssetMovementReport = /* GraphQL */ `
  subscription OnCreateAssetMovementReport {
    onCreateAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
      }
      movedFromCoordinate {
        latitude
        longitude
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetMovementReport = /* GraphQL */ `
  subscription OnUpdateAssetMovementReport {
    onUpdateAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
      }
      movedFromCoordinate {
        latitude
        longitude
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetMovementReport = /* GraphQL */ `
  subscription OnDeleteAssetMovementReport {
    onDeleteAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
      }
      movedFromCoordinate {
        latitude
        longitude
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssetType = /* GraphQL */ `
  subscription OnCreateAssetType {
    onCreateAssetType {
      id
      name
      subType {
        items {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        nextToken
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetType = /* GraphQL */ `
  subscription OnUpdateAssetType {
    onUpdateAssetType {
      id
      name
      subType {
        items {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        nextToken
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetType = /* GraphQL */ `
  subscription OnDeleteAssetType {
    onDeleteAssetType {
      id
      name
      subType {
        items {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        nextToken
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssetSubType = /* GraphQL */ `
  subscription OnCreateAssetSubType {
    onCreateAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetSubType = /* GraphQL */ `
  subscription OnUpdateAssetSubType {
    onUpdateAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetSubType = /* GraphQL */ `
  subscription OnDeleteAssetSubType {
    onDeleteAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDimensions = /* GraphQL */ `
  subscription OnCreateDimensions {
    onCreateDimensions {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDimensions = /* GraphQL */ `
  subscription OnUpdateDimensions {
    onUpdateDimensions {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDimensions = /* GraphQL */ `
  subscription OnDeleteDimensions {
    onDeleteDimensions {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplier = /* GraphQL */ `
  subscription OnCreateSupplier {
    onCreateSupplier {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplier = /* GraphQL */ `
  subscription OnUpdateSupplier {
    onUpdateSupplier {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplier = /* GraphQL */ `
  subscription OnDeleteSupplier {
    onDeleteSupplier {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        items {
          id
          name
          customerId
          client
          description
          startDate
          endDate
          status
          userGroups
          adminGroups
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          uuid
          createdAt
          updatedAt
          status
          customerId
          deviceId
          name
          userGroups
          adminGroups
          description
          version
          unitNumber
          make
          model
          year
          capacity
          activatedOn
          deactivatedOn
          toolType
          size
          annualInspection
          eventTs
          receivedTs
          firstCheckedIn
          altitude
          battery
          rssi
          accelerometer
          speed
          course
          projectId
          movingStatus
          movingTs
          movingStartTs
          movedTs
          movedProjectId
        }
        nextToken
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        items {
          id
          name
          customerId
          client
          description
          startDate
          endDate
          status
          userGroups
          adminGroups
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          uuid
          createdAt
          updatedAt
          status
          customerId
          deviceId
          name
          userGroups
          adminGroups
          description
          version
          unitNumber
          make
          model
          year
          capacity
          activatedOn
          deactivatedOn
          toolType
          size
          annualInspection
          eventTs
          receivedTs
          firstCheckedIn
          altitude
          battery
          rssi
          accelerometer
          speed
          course
          projectId
          movingStatus
          movingTs
          movingStartTs
          movedTs
          movedProjectId
        }
        nextToken
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        items {
          id
          name
          customerId
          client
          description
          startDate
          endDate
          status
          userGroups
          adminGroups
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          uuid
          createdAt
          updatedAt
          status
          customerId
          deviceId
          name
          userGroups
          adminGroups
          description
          version
          unitNumber
          make
          model
          year
          capacity
          activatedOn
          deactivatedOn
          toolType
          size
          annualInspection
          eventTs
          receivedTs
          firstCheckedIn
          altitude
          battery
          rssi
          accelerometer
          speed
          course
          projectId
          movingStatus
          movingTs
          movingStartTs
          movedTs
          movedProjectId
        }
        nextToken
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
    }
  }
`;
