import React from 'react'
import { Link } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { debounce } from '../../util'

import { makeStyles } from '@material-ui/core/styles'

import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Divider,
  Box,
} from '@material-ui/core'

import PageTitle from './PageTitle'
import AssetIcon from './AssetIcon'
import ProjectInfo from './ProjectInfo'
import SearchDrawer from './SearchDrawer'

import Brand from '../../assets/brand/symbol-defs.svg'
import Icons from '../../assets/icons/symbol-defs.svg'
import { useUIActions } from '../../store/UI/UIActions'
import { useSearchActions } from '../../store/Search/SearchActions'
import { useStateContext } from '../../store/stateContext'
import {
  SET_QUICK_SEARCH_RESULTS,
  SET_SEARCH_CRITERIA,
} from '../../store/Search/SearchReducer'

const useStyles = makeStyles(theme => ({
  toolbar: {
    alignItems: 'start',
  },
  toolbarContents: {
    height: 48,
    flexWrap: 'nowrap',
  },
  hamburgerWrap: {
    zIndex: 1,
    marginRight: theme.spacing(-2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  assetIconWrap: {
    alignContent: 'center',
  },
  brand: {
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 6,
      left: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      paddingTop: 6,
      marginRight: 'auto',
    },
  },
  brandLockup: {
    maxHeight: 38,
    maxWidth: 173,
  },
  brandMark: {
    maxHeight: 38,
    maxWidth: 32,
    marginRight: theme.spacing(2),
  },
  searchContainer: {
    width: theme.spacing(38),
    [theme.breakpoints.up('xs')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(30),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: 'unset',
      flexGrow: 1,
    },
  },
  searchField: {
    width: theme.spacing(38),
    marginTop: theme.spacing(0.5),
    zIndex: 1210,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(30),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& input': {
      background: theme.colors.backgroundColor,
      borderRadius: 4,
    },
  },
  closeSearchButton: {
    zIndex: 1201,
  },
  divider: {
    zIndex: 2,
  },
}))

const AsseTracAppBar = ({ title = '', project = {}, icon = '' }) => {
  const theme = useTheme()
  const classes = useStyles()

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const {
    dispatch,
    state: { ui: uiState },
  } = useStateContext()
  const {
    openDrawerNav,
    openDrawerSearch,
    closeDrawerSearch,
    showSearchBar,
    hideSearchBar,
  } = useUIActions()
  const { getSearchResults } = useSearchActions()

  const handleSearchChange = e => {
    const value = e.target.value
    if (value === '') {
      closeDrawerSearch()
    } else {
      openDrawerSearch()
      dispatch({
        type: SET_QUICK_SEARCH_RESULTS,
        payload: {},
      })
      dispatch({
        type: SET_SEARCH_CRITERIA,
        payload: value,
      })
      debounce(
        'searchCriteria',
        () => {
          getSearchResults(value)
        },
        300,
      )
    }
  }

  const handleSearchFocus = e => {
    const value = e.target.value
    if (value !== '' && value !== null) {
      openDrawerSearch()
    }
  }

  return (
    <>
      <AppBar position={mdUp ? 'fixed' : 'static'} elevation={0} data-test="appBar">
        <Toolbar variant="dense">
          <Grid
            container
            justify="space-between"
            className={classes.toolbarContents}
          >
            <Grid item className={classes.hamburgerWrap}>
              <IconButton
                data-test="openDrawerButton"
                onClick={openDrawerNav}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <SvgIcon color="secondary">
                  <svg>
                    <use xlinkHref={Icons + '#icn_hamburger'} />
                  </svg>
                </SvgIcon>
              </IconButton>
            </Grid>
            <Divider className={classes.divider} orientation="vertical" />
            {}
            {((!uiState.searchBarVisible && smDown) || smUp) && title && (
              <>
                {(() => {
                  if (title === 'ProjectInfo') {
                    return <ProjectInfo project={project} />
                  }
                  if (title && icon) {
                    return (
                      <Grid container item className={classes.assetIconWrap}>
                        <AssetIcon name={icon} />
                        <PageTitle>{title}</PageTitle>
                      </Grid>
                    )
                  }
                  if (title) {
                    return <PageTitle>{title}</PageTitle>
                  }
                })()}
              </>
            )}
            {
              // color fix vscode
              <></>
            }
            {mdUp && (
              <Grid container item className={classes.brand} justify="center">
                <Grid item>
                  <Link to="/">
                    <svg className={classes.brandLockup}>
                      <use xlinkHref={Brand + '#lockup_horizontal'} />
                    </svg>
                  </Link>
                </Grid>
              </Grid>
            )}

            <Grid item className={classes.searchContainer}>
              {xsDown && !uiState.searchBarVisible && (
                <>
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton edge="end" onClick={showSearchBar}>
                    <SvgIcon>
                      <svg className={classes.brandMark}>
                        <use xlinkHref={Icons + '#icn_search'} />
                      </svg>
                    </SvgIcon>
                  </IconButton>
                </>
              )}
              {
                // color fix vscode
                <></>
              }
              {(smUp || uiState.searchBarVisible) && (
                <TextField
                  onFocus={handleSearchFocus}
                  className={classes.searchField}
                  margin="dense"
                  variant="outlined"
                  placeholder="SEARCH ALL"
                  onChange={handleSearchChange}
                />
              )}
              {xsDown && uiState.searchBarVisible && (
                <Box>
                  <IconButton
                    className={classes.closeSearchButton}
                    edge="end"
                    onClick={e => {
                      hideSearchBar()
                      closeDrawerSearch()
                    }}
                  >
                    <SvgIcon>
                      <svg className={classes.brandMark}>
                        <use xlinkHref={Icons + '#icn_x'} />
                      </svg>
                    </SvgIcon>
                  </IconButton>
                </Box>
              )}
              <SearchDrawer className={classes.searchDrawer} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {mdUp && <Toolbar variant="dense" />}
    </>
  )
}

export default AsseTracAppBar
