import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import DeckGL from 'deck.gl'
import {
  _MapContext as MapContext,
  NavigationControl,
  StaticMap,
} from 'react-map-gl'
import { makeStyles } from '@material-ui/core/styles'
import ReactResizeDetector from 'react-resize-detector'
import get from 'lodash/get'

import { useStateContext } from '../../store/stateContext'
import { useMapActions } from '../../store/Map/MapActions'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  navControls: {
    zIndex: '1',
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(14),
  },
}))

const AsseTracMap = ({ onInitialize, ...rest }) => {
  const classes = useStyles()

  const { state } = useStateContext()
  const { updateViewport, mapRef, updateCurrentMapId } = useMapActions()

  const [shouldInit, setShouldInit] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const deckGlRef = useRef(null)

  const initialize = () => {
    if (initialized) {
      return
    }

    if (typeof onInitialize === 'function') {
      onInitialize()
    }
    setInitialized(true)
  }

  const onResize = (width, height) => {
    updateViewport({ width, height })
    setShouldInit(true)
  }

  const onMapLoad = () => {
    const activeRef = get(deckGlRef, 'current.children[1].ref.current')
    if (activeRef) {
      const map = activeRef.getMap()
      updateCurrentMapId(map._mapId)
    }
  }


  // init
  useEffect(() => {
    if (shouldInit) {
      initialize()
      setShouldInit(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInit])

  return (
    <div className={classes.root}>
      {initialized && (
        <>
          <DeckGL
            layers={state.map.filteredLayers}
            controller
            viewState={state.map.viewport}
            onViewStateChange={({ viewState }) => updateViewport(viewState)}
            ref={deckGlRef}
            ContextProvider={MapContext.Provider}
          >
            <StaticMap
              reuseMap
              onLoad={() => onMapLoad()}
              mapboxApiAccessToken={state.map.config.mapboxApiAccessToken}
              mapStyle={state.map.config.mapStyle}
              ref={mapRef}
            >
              {
                <div className={classes.navControls}>
                  <NavigationControl />
                </div>
              }
            </StaticMap>
          </DeckGL>
        </>
      )}

      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
    </div>
  )
}

AsseTracMap.propTypes = {
  filters: PropTypes.array,
  onInitialize: PropTypes.func,
}

export default AsseTracMap
