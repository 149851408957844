import KmlFile from './KmlFile'
import { uniqueId } from '../util'

export default class KmlLayer {
  constructor({
    children = null,
    kmlLayerAsGeoJson = null,
    name,
    geoJson,
    parent = null,
    selected = false,
    uuid = uniqueId(),
  }) {
    this.children = children || []
    this.kmlLayerAsGeoJson = kmlLayerAsGeoJson
    this.name = kmlLayerAsGeoJson.name || ''
    this.geoJson = kmlLayerAsGeoJson.geoJson
    this.parent = parent
    this.selected = selected
    this.uuid = uuid

    if (!kmlLayerAsGeoJson) {
      return
    }


    for (let layer of kmlLayerAsGeoJson.children) {
      // console.log(layer)
      this.addChild(
        new KmlLayer({
          kmlLayerAsGeoJson: layer,
          parent: this,
        }),
      )
    }
  }


  /**
   * Lazy load kml-worker
   */
  get worker() {
    if (!this._worker) {
      this._worker = new Worker('/kml-worker.js')
    }

    return this._worker
  }

  addChild(child) {
    this.children.push(child)
  }

  // clone(parent = null) {
  //   const clone = new KmlLayer({
  //     children: [],
  //     domContext: this.domContext,
  //     name: this.name,
  //     parent,
  //     selected: this.selected,
  //     uuid: this.uuid,
  //     includeElements: this.includeElements,
  //   })

  //   for (let child of this.children) {
  //     clone.addChild(child.clone(clone))
  //   }

  //   return clone
  // }

  async convertToGeoJson() {
    const doc = this.domContext.ownerDocument
    console.log(doc)
    // clone document
    const docClone = doc.implementation.createDocument(doc, null, null)
    const newDocNode = docClone.importNode(doc.documentElement, true)
    docClone.appendChild(newDocNode)

    // remove all folders
    const folders = docClone.querySelectorAll('Folder')
    for (let folder of folders) {
      folder.parentNode.removeChild(folder)
    }

    // add selected folders
    const selectedLayers = this.getSelectedLayers()
    for (let layer of selectedLayers) {
      const newNode = docClone.importNode(layer.domContext, true)

      switch (newNode.nodeName) {
        case 'Document':
          const oldDocNode = docClone.querySelector('Document')
          oldDocNode.parentNode.removeChild(oldDocNode)

          docClone.documentElement.appendChild(newNode)
          break
        case 'Folder':
        case 'Placemark':
          docClone.querySelector('Document').appendChild(newNode)
          break
        default:
        // do nothing
      }
    }

    const kmlFile = new KmlFile(docClone)

    return kmlFile.toGeoJson()
  }

  deselect() {
    this.setSelected(false)
  }

  /**
   * Gets list of selected KmlLayers
   *
   * By default, this only gets the top selected level. If the current layer is selected, it will be returns and its
   * children will not be parsed.
   *
   * @param {bool} topLevel  Gets self if selected, or selected children
   * @return {[KmlLayer]}
   */
  getSelectedLayers(topLevel = true) {
    let nodes = []

    if (this.selected) {
      nodes.push(this)
    }

    if (!this.selected || !topLevel) {
      for (let child of this.children) {
        nodes = [...nodes, ...child.getSelectedLayers(true)]
      }
    }

    return nodes
  }

  hasSelectedChild() {
    for (let child of this.children) {
      if (child.selected || child.hasSelectedChild()) {
        return true
      }
    }

    return false
  }

  hasUnselectedChild() {
    return this.children.some(child => {
      return !child.selected || child.hasUnselectedChild()
    })
  }

  matchChildrenSelectionState() {
    this.selected = !this.hasUnselectedChild()

    // set parent
    if (this.parent instanceof KmlLayer) {
      this.parent.matchChildrenSelectionState()
    }
  }

  select() {
    this.setSelected(true)
  }

  setSelected(selected) {
    this.selected = selected

    for (let child of this.children) {
      child.setSelected(selected)
    }

    if (this.parent instanceof KmlLayer) {
      this.parent.matchChildrenSelectionState()
    }
  }

  toggle(selected) {
    this.setSelected(selected || !this.selected)
  }
}
