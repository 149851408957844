export const INIT_REPORTS = 'INIT_REPORTS'
export const SET_MOVES = 'SET_MOVES'

export const initialState = {
  moveList: [],
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_REPORTS:
      return {
        ...initialState,
      }
    case SET_MOVES:
      return {
        ...state,
        movesList: payload,
      }
    default:
      return state
  }
}
