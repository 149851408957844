import React, { useEffect, useState, useRef } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MaterialTable from 'material-table'
import moment from 'moment'

import { useReportsActions } from '../../store/Reports/ReportsActions'
import { useStateContext } from '../../store/stateContext'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'
import { safeGet } from '../../util'

const useStyles = makeStyles(theme => ({
  pageWrap: {
    marginTop: theme.spacing(2),
  },
  controls: {
    '& >div': {
      zIndex: 1,
    },
  },
  tweenLabel: {
    textAlign: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  datepicker: {
    width: '100%',
  },
  buttonContainer: {
    paddingBottom: theme.spacing(2),
  },
  tableWrap: {
    position: 'relative',
    width: '100%',
    marginTop: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: -68,
    },
    zIndex: 0,
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
  tableWrapInner: {
    position: 'absolute',
    maxWidth: '100%',
  },
}))
// const tableIcons = {
//   Export: forwardRef((props, ref) => (
//     <SaveAlt color="primary" {...props} ref={ref} />
//   )),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => (
//     <ChevronLeft {...props} ref={ref} />
//   )),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
// }

const DEFAULT_FILTER_START_DATE = moment().subtract(3, 'months')
const DEFAULT_FILTER_END_DATE = moment()
const DEFAULT_FILTER_TYPE = 'ALL'
const DEFAULT_FILTER_SUBTYPE = 'ALL'
const DEFAULT_ASSET_TYPE = { name: 'ALL', id: 'ALL', subType: { items: [] } }
const DEFAULT_ASSET_SUB_TYPE = { name: 'ALL', id: 'ALL' }

export default ({ customerId, projectId }) => {
  const inputLabelType = useRef()
  const inputLabelSubtype = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const tableIcons = theme.tableIcons
  const { cancellablePromise } = useCancellablePromise()

  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [assetTypes, setAssetTypes] = useState([DEFAULT_ASSET_TYPE])
  const [assetSubtypes, setAssetSubtypes] = useState([DEFAULT_ASSET_SUB_TYPE])

  const [filterStartDate, setFilterStartDate] = useState(
    DEFAULT_FILTER_START_DATE,
  )
  const [filterEndDate, setFilterEndDate] = useState(DEFAULT_FILTER_END_DATE)
  const [filterType, setFilterType] = useState(DEFAULT_FILTER_TYPE)
  const [filterSubtype, setFilterSubtype] = useState(DEFAULT_FILTER_SUBTYPE)

  const { listAssetMovementReports } = useReportsActions()
  const { state } = useStateContext()

  const massageData = data => {
    const formattedData = data.reduce((acc, current) => {
      let distance = safeGet('totalDistanceTraveled', current, '')
      if (typeof distance === 'number') {
        distance = distance.toFixed(2) + ' mi'
      }

      let OnRoadDistance = safeGet('onRoadDistance', current, '')
      if (typeof OnRoadDistance === 'number') {
        OnRoadDistance = OnRoadDistance.toFixed(2) + ' mi'
      }
      acc.push({
        startDate: moment
          .unix(safeGet('startTs', current, ''))
          .format('M/D/YY'),
        startTime: moment.unix(safeGet('startTs', current, '')).format('h:mma'),
        endDate: moment.unix(safeGet('stopTs', current, '')).format('M/D/YY'),
        endTime: moment.unix(safeGet('stopTs', current, '')).format('h:mm a'),
        name: safeGet('id', current, ''),
        type: safeGet('assetType.name', current, ''),
        subType: safeGet('assetSubType.name', current, ''),
        id: safeGet('id', current, ''),
        uuid: safeGet('uuid', current, ''),
        fromLat: safeGet('movedFromCoordinate.latitude', current, ''),
        fromLong: safeGet('movedFromCoordinate.longitude', current, ''),
        toLat: safeGet('movedToCoordinate.latitude', current, ''),
        toLong: safeGet('movedToCoordinate.longitude', current, ''),
        distance: distance,
        onRoadDistance: OnRoadDistance,
      })

      return acc
    }, [])
    return formattedData
  }

  const getMovesData = () => {
    setLoading(true)

    let params = {
      customerId: customerId,
      destProjectIdStopTs: {
        between: [
          { destProjectId: projectId, stopTs: moment(filterStartDate).unix() },
          { destProjectId: projectId, stopTs: moment(filterEndDate).unix() },
        ],
      },
    }
    let filter = {}
    if (filterType && filterType !== 'ALL') {
      filter = {
        ...filter,
        assetTypeId: {
          eq: filterType,
        },
      }
    }
    if (filterSubtype && filterSubtype !== 'ALL') {
      filter = {
        ...filter,
        assetSubTypeId: {
          eq: filterSubtype,
        },
      }
    }
    if (Object.keys(filter).length > 0) {
      params = {
        ...params,
        filter,
      }
    }

    cancellablePromise(listAssetMovementReports(params))
      .then(results => {
        setTableData(massageData(results))
        setLoading(false)
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })
    setLoading(false)
  }

  const handleTypeFilterChange = type => {
    setFilterType(type.id)
    if (
      type.id !== 'ALL' &&
      !type.subType.items.find(subtype => subtype.id === 'ALL')
    ) {
      type.subType.items.unshift({ name: 'ALL', id: 'ALL' })
    }
    setAssetSubtypes(type.subType.items || [])
    setFilterSubtype(type.id === 'ALL' ? '' : DEFAULT_FILTER_SUBTYPE)
  }
  const getTypeFromId = (id, subtype) => {
    if (subtype) {
      return getTypeFromId(filterType).subType.items.find(
        asset => asset.id === id,
      )
    }
    return assetTypes.find(asset => asset.id === id)
  }

  useEffect(() => {
    // console.log(customerId)
    if (customerId) {
      getMovesData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEndDate, filterStartDate, filterType, filterSubtype, customerId])

  useEffect(() => {
    const tempAssetTypes = state.constants.assetTypes
    const allItem = tempAssetTypes.find(x => x.id === 'ALL')
    if (!allItem) {
      tempAssetTypes.unshift({ name: 'ALL', id: 'ALL', subType: { items: [] } })
    }
    setAssetTypes(tempAssetTypes)
  }, [state.constants.assetTypes])

  return (
    <>
      <Container fixed>
        {loading && (
          <Grid container item justify="center" className={classes.pageWrap}>
            <CircularProgress color="primary" variant="indeterminate" />
          </Grid>
        )}
        {!loading && (
          <Grid
            container
            item
            direction="column"
            spacing={2}
            className={classes.pageWrap}
          >
            {
              // Controls container
            }
            <Grid
              container
              item
              className={classes.controls}
              justify="flex-start"
              spacing={2}
              xs={12}
            >
              {
                // Dates container
              }
              <Grid container item xs={12} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container item xs={12}>
                    <Typography className={classes.longLabel} color="secondary">
                      Moves completed on dates between
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={1}
                    alignItems="center"
                    className={classes.datesRow}
                  >
                    <Grid item xs={12} md={5}>
                      <KeyboardDatePicker
                        className={classes.datepicker}
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="start-date"
                        KeyboardButtonProps={{
                          'aria-label': 'start date',
                        }}
                        margin="normal"
                        name="startDate"
                        variant="inline"
                        value={filterStartDate}
                        onChange={e => setFilterStartDate(e)}
                      />
                    </Grid>
                    <Grid item hidden={smDown} md={2}>
                      <Typography
                        className={classes.tweenLabel}
                        color="secondary"
                      >
                        {' '}and{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <KeyboardDatePicker
                        className={classes.datepicker}
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="end-date"
                        KeyboardButtonProps={{
                          'aria-label': 'end date',
                        }}
                        margin="normal"
                        name="endDate"
                        variant="inline"
                        value={filterEndDate}
                        onChange={e => setFilterEndDate(e)}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {
                // Types container
              }
              <Grid
                container
                item
                spacing={2}
                alignItems="flex-end"
                xs={12}
                lg={4}
              >
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel ref={inputLabelType}>Type</InputLabel>
                    <Select
                      value={filterType}
                      labelWidth={
                        inputLabelType.current
                          ? inputLabelType.current.offsetWidth
                          : 0
                      }
                      onChange={e => {
                        handleTypeFilterChange(getTypeFromId(e.target.value))
                      }}
                    >
                      {assetTypes &&
                        assetTypes.map((type, index) => (
                          <MenuItem
                            key={'type-' + index}
                            value={type.id}
                            name={type.name}
                          >{`${type.name || getTypeFromId(type.id)}`}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    disabled={filterType === 'ALL'}
                  >
                    <InputLabel ref={inputLabelSubtype}>Subtype</InputLabel>
                    <Select
                      value={filterSubtype}
                      labelWidth={
                        inputLabelSubtype.current
                          ? inputLabelSubtype.current.offsetWidth
                          : 0
                      }
                      onChange={e => {
                        setFilterSubtype(getTypeFromId(e.target.value, true).id)
                      }}
                    >
                      {assetSubtypes &&
                        assetSubtypes.map((subtype, index) => (
                          <MenuItem
                            key={'subtype-' + index}
                            value={subtype.id}
                          >{`${subtype.name ||
                            getTypeFromId(subtype.id, true)}`}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {
              // Table container
            }
            <Grid item xs={12} className={classes.tableWrap}>
              {tableData && (
                <div className={classes.tableWrapInner}>
                  <MaterialTable
                    elevation={0}
                    icons={tableIcons}
                    options={{
                      search: false,
                      toolbarButtonAlignment: 'right',
                      showTitle: false,
                      exportButton: true,
                      exportAllData: true,
                      headerStyle: {
                        minHeight: '48px',
                      },
                    }}
                    columns={[
                      { title: 'Start Date', field: 'startDate' },
                      { title: 'Start Time', field: 'startTime' },
                      { title: 'End Date', field: 'endDate' },
                      { title: 'End Time', field: 'endTime' },
                      { title: 'Type', field: 'type' },
                      { title: 'Subtype', field: 'subType' },
                      { title: 'Distance', field: 'distance' },
                      { title: 'Distance On Roads', field: 'onRoadDistance' },
                      {
                        title: 'UUID',
                        field: 'uuid',
                        cellStyle: { minWidth: '136px' },
                      },
                      { title: 'From Lat', field: 'fromLat' },
                      { title: 'From Long', field: 'fromLong' },
                      { title: 'To Lat', field: 'toLat' },
                      { title: 'To Long', field: 'toLong' },
                      { title: 'Dimensions', field: 'dimensions' },
                      { title: 'Supplier', field: 'supplier' },
                      { title: 'Asset Id', field: 'assetId' },
                      { title: 'Id', field: 'id' },
                    ]}
                    data={tableData}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  )
}
