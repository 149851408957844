import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'

import { useStateContext } from '../../store/stateContext'
import { useUIActions } from '../../store/UI/UIActions'
import { useProjectActions } from '../../store/Project/ProjectActions'
import SpinnerButton from '../elements/SpinnerButton'

const InactivateProjectDialog = ({ project, onSubmit }) => {
  project = project || {}

  const { closeInactivateProjectDialog } = useUIActions()
  const { updateProject } = useProjectActions()

  // app level state
  const [sending, setSending] = useState(false)
  const { state } = useStateContext()

  // component level actions
  const clearForm = () => {
    setSending(false)
  }

  const cancelForm = () => {
    closeInactivateProjectDialog()
  }

  const submit = async () => {
    try {
      setSending(true)

      const oppositeStatus = project.status === 'active' ? 'deactivating' : 'activating'
      const updatedProject = await updateProject({
        id: project.id,
        status: oppositeStatus,
      })

      // call onSubmit prop
      if (typeof onSubmit === 'function') {
        onSubmit(updatedProject)
      }

      closeInactivateProjectDialog()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll="body"
      open={state.ui.inactivateProjectDialogOpen}
      onClose={closeInactivateProjectDialog}
    >
      {project.status === 'active' && (
        <DialogContent>
          <DialogTitle>INACTIVATE PROJECT</DialogTitle>
          <Grid item xs={12}>
            <Typography>
              Inactivating a project will remove it from the project list as
              well as the global map. Asset movement in and out of the project
              boundaries will not be recorded. You cannot edit an inactive
              project. Inactive projects will not appear in your search results.
            </Typography>
            <Typography>
              You will still be able to see the project, it's Moves Report, and
              re-activate it at any time.
            </Typography>
            <Typography>
              The project will remain in an inactivating state until all assets
              have been removed from the project.
            </Typography>
          </Grid>
        </DialogContent>
      )}
      {project.status === 'inactive' && (
        <DialogContent>
          <DialogTitle>RE-ACTIVATE PROJECT</DialogTitle>
          <Grid item xs={12}>
            <Typography>
              Re-Activating a project will place it back on the project list as
              well as the global map. Asset movement in and out of the project
              boundaries will be recorded. You can edit active projects. Active
              projects will appear in your search results.
            </Typography>
            <Typography>
              You will still be able to inactivate the project at any time.
            </Typography>
            <Typography>
              The project will remain in an activating state until any existing assets
              in the project boundary have been added.
            </Typography>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="secondary" onClick={cancelForm}>
          Cancel
        </Button>
        <SpinnerButton loading={sending} type="submit" onClick={submit} valid>
          {project.status === 'active' && 'Inactivate'}
          {project.status === 'inactive' && 'Re-Activate'}
        </SpinnerButton>
      </DialogActions>
    </Dialog>
  )
}

export default InactivateProjectDialog
