import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSearchActions } from '../../store/Search/SearchActions'
import { safeGet } from '../../util'
import moment from 'moment'

import MaterialTable from 'material-table'

import AsseTracAppBar from '../elements/AsseTracAppBar'

import { Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  searchResultsContainer: {
    marginTop: theme.spacing(2),
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
  noResults: {
    textAlign: 'center',
  },
}))

export default () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const tableIcons = theme.tableIcons

  const { searchState } = useSearchActions()
  const [dataType, setDataType] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [columnSchema, setColumnSchema] = useState(null)

  const projectColumns = [
    { title: 'Name', field: 'name' },
    { title: 'Client', field: 'client' },
    { title: 'Description', field: 'Description' },
    { title: 'Start Date', field: 'startDate' },
    { title: 'End Date', field: 'endDate' },
  ]

  const assetColumns = [
    {
      title: 'UUID',
      field: 'uuid',
      cellStyle: { minWidth: '136px' },
    },
    { title: 'Asset', field: 'name' },
    { title: 'Project', field: 'project' },
    { title: 'Type', field: 'type' },
    { title: 'Subtype', field: 'subType' },
    { title: 'Lat', field: 'latitude' },
    { title: 'Long', field: 'longitude' },
    { title: 'Version', field: 'version' },
    {
      title: 'Last Checked In',
      field: 'lastCheckedIn',
      cellStyle: { minWidth: '90px' },
    },
  ]

  const rowClick = (event, rowData) => {
    switch (dataType) {
      case 'projects':
        history.push('/Projects/' + rowData.id)
        break
      case 'assets':
        history.push('/Asset/' + rowData.id)
        break
      default:
        console.log(dataType)
    }
  }

  const massageData = (type, data) => {
    let formattedData
    // todo turn this into a switch
    switch (type) {
      case 'projects':
        formattedData = data.reduce((acc, current) => {
          acc.push({
            id: safeGet('id', current, ''),
            name: safeGet('name', current, ''),
            customerId: safeGet('customerId', current, ''),
            client: safeGet('client', current, ''),
            description: safeGet('description', current, ''),
            startDate: moment
              .unix(safeGet('startDate', current, ''))
              .format('M/D/YY'),
            endDate: moment
              .unix(safeGet('endDate', current, ''))
              .format('M/D/YY'),
            status: safeGet('status', current, ''),
            userGroups: safeGet('userGroups', current, ''),
            adminGroups: safeGet('adminGroups', current, ''),
          })

          return acc
        }, [])

        break
      case 'assets':
        formattedData = data.reduce((acc, current) => {
          acc.push({
            name: safeGet('name', current, ''),
            activatedOn: safeGet('activatedOn', current, ''),
            adminGroups: safeGet('adminGroups', current, ''),
            latitude: safeGet(
              'assetHistory.items.0.coordinate.latitude',
              current,
              '',
            ),
            longitude: safeGet(
              'assetHistory.items.0.coordinate.longitude',
              current,
              '',
            ),
            battery: safeGet('assetHistory.items.0.battery', current, ''),
            rssi: safeGet('assetHistory.items.0.rssi', current, ''),
            nextToken: safeGet('nextToken', current, ''),
            assetMovement: safeGet('assetMovement', current, ''),
            customer: safeGet('customer', current, ''),
            deactivatedOn: safeGet('deactivatedOn', current, ''),
            description: safeGet('description', current, ''),
            lastCheckedIn: moment
              .unix(safeGet('assetHistory.items.0.eventTs', current, ''))
              .format('M/D/YY'),
            firstCheckedIn: safeGet('firstCheckedIn', current, ''),
            id: safeGet('id', current, ''),
            project: safeGet('project.name', current, ''),
            subType: safeGet('subType.name', current, ''),
            type: safeGet('type.name', current, ''),
            userGroups: safeGet('userGroups', current, ''),
            uuid: safeGet('uuid', current, ''),
            version: safeGet('version', current, ''),
          })

          return acc
        }, [])

        break
      default:
        console.log(type)
    }
    return formattedData
  }
  useEffect(() => {
    if (!searchState.searchResults || !searchState.searchResults[0]) return
    if (searchState.searchResults[0].hasOwnProperty('client')) {
      // projects
      setDataType('projects')
      setTableData(massageData('projects', searchState.searchResults))
      setColumnSchema(projectColumns)
    }
    if (searchState.searchResults[0].hasOwnProperty('firstCheckedIn')) {
      // assets
      setDataType('assets')
      setTableData(massageData('assets', searchState.searchResults))
      setColumnSchema(assetColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.searchResults])

  return (
    <Grid container direction="column">
      <AsseTracAppBar title="SEARCH RESULTS" />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.searchResultsContainer}>
            {(!searchState.searchResults ||
              searchState.searchResults.length === 0) && (
              <Typography
                className={classes.noResults}
                color="secondary"
                variant="body2"
              >
                no results
              </Typography>
            )}
            {searchState.searchResults && searchState.searchResults.length > 0 && (
              <>
                {tableData && (
                  <MaterialTable
                    icons={tableIcons}
                    options={{
                      toolbar: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 25, 50, 100],
                      emptyRowsWhenPaging: false,
                      search: false,
                      showTitle: false,
                      headerStyle: {
                        minHeight: '48px',
                      },
                    }}
                    onRowClick={rowClick}
                    columns={columnSchema}
                    data={tableData}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
