import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatDateTime } from '../../util'


const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translate(-50%, -10%)',
    minWidth: theme.spacing(12),
  },
  cardContent: {
    paddingBottom: theme.spacing(2) + 'px !important',
  },
  title: {},
  client: {
    color: theme.colors.portlandCement,
  },
}))

const AssetToolTip = ({ tooltip }) => {
  const classes = useStyles()
  //console.log(tooltip)
  let speed = 'Unknown'
  if (!tooltip || !(tooltip.properties || tooltip.move)) {
    return null
  }
  if (tooltip.move && tooltip.move.speed) {
    speed = Math.floor(tooltip.move.speed / 1.609344)
  }
  //console.log(tooltip.move)
  return (
    <Card
      className={classes.root}
      elevation={12}
      style={{
        position: 'absolute',
        zIndex: 1500,
        pointerEvents: 'none',
        left: tooltip.x,
        top: tooltip.y,
      }}
    >
      {tooltip.move ? (
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" className={classes.title}>
            Unit: {tooltip.move.unitNumber}
          </Typography>
          <Typography variant="subtitle1" className={classes.client}>
          Speed: {speed} MPH
          </Typography>
          <Typography variant="subtitle1" className={classes.client}>
          Received: {formatDateTime(tooltip.move.receivedTs, 'M/D/YY h:mmA')}
          </Typography>
        </CardContent>
      ) : (
        <CardContent className={classes.cardContent}>

          <Typography variant="h6" className={classes.title}>
            Unit: {tooltip.properties.unitNumber}
          </Typography>
          <Typography variant="subtitle1" className={classes.client}>
            Received: {tooltip.properties.lastReceived}
          </Typography>
          <Typography variant="subtitle1" className={classes.client}>
            Type: {tooltip.properties.typeName}
          </Typography>
          <Typography variant="subtitle1" className={classes.client}>
            SubType: {tooltip.properties.subTypeName}
          </Typography>
        </CardContent>
      )}

    </Card>
  )
}

export default AssetToolTip
