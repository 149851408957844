import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useCustomerActions } from '../../store/Customer/CustomerActions'
import { safeGet } from '../../util'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  selectLabel: {
    top: '-18px',
  },
}))

const CustomerSelector = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    customerState,
    getCustomers,
    getActiveCustomer,
    setActiveCustomer,
  } = useCustomerActions()

  const { cancellablePromise } = useCancellablePromise()

  const defaultCustomer = customerState.customerList && customerState.customerList.length > 0
    ? customerState.customerList[0].id
    : ''

  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(defaultCustomer)

  const handleSelect = async e => {
    const selectedId = e.target.value
    setSelected(selectedId)
    try {
      const customers = await cancellablePromise(getCustomers())
      const customer = customers.find(x => x.id === selectedId)
      if (customer) {
        await cancellablePromise(setActiveCustomer(customer))
        history.push('/')
      }
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    cancellablePromise(getActiveCustomer())
      .then(customer => {
        if (customer) {
          setSelected(customer.id)
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState.activeCustomer])

  return (
    <>
      {loading && (
        <CircularProgress color="primary" size={20} variant="indeterminate" />
      )}
      {!loading && (
        <FormControl fullWidth>
          <InputLabel className={classes.selectLabel} shrink>
            As Customer
          </InputLabel>

          {safeGet('customerList', customerState, []).length === 0 && (
            <>
              <Typography color="primary" component="span">
                No customers.
              </Typography>
            </>
          )}
          {safeGet('customerList', customerState, []).length > 0 && (
            <Select
              fullWidth
              onChange={handleSelect}
              value={selected}
              variant="outlined"
            >
              {(customerState.customerList || []).map((customer, index) => (
                <MenuItem key={`customer-option-${index}`} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}
    </>
  )
}

export default CustomerSelector
