import React, { useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import KmlSelectionTree from '../../elements/KmlSelectionTree'
import { convertTuplesToCoordinates } from '../../../util'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  listWrap: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.backgroundColor,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
}))

const SelectProjectBoundariesSection = ({
  inputs,
  getFieldError,
  getValue,
  handleInputChange,
  setInputs,
  validateForm,
}) => {
  const classes = useStyles()
  const [interaction, setInteraction] = useState(false)

  // on mount
  useEffect(() => {
    // init
    if (
      !inputs.hasOwnProperty('boundaries') ||
      !inputs.hasOwnProperty('selectedLayer')
    ) {
      setInputs({
        ...inputs,
        boundaries: [],
        selectedLayer: null,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = async layer => {
    console.log('handle select')
    //layer.select()
    //const geoJson = await layer.convertToGeoJson()
    //const feature = layer.geoJson
    let boundaries = convertTuplesToCoordinates(layer.geometry.coordinates[0])
    console.log(layer)
    console.log(boundaries)
    // for (let feature of geoJson.features) {
    //   if (!feature.geometry || feature.geometry.type !== 'Polygon') {
    //     continue
    //   }


    //   break
    // }

    handleInputChange('boundaries')(boundaries)

    // only show the user an error after they have
    // interacted with the boundaries picker
    setInteraction(true)
  }

  return (
    <>
      <p>Select the element that you want to represent the project boundary.</p>
      <div
        className={clsx(classes.listWrap, {
          error: !!getFieldError('boundaries') && interaction,
        })}
      >
        <KmlSelectionTree
          kmlFile={getValue('kmlFile')}
          onSelect={handleSelect}
        />
      </div>
      {interaction && (
        <Typography color="error">{getFieldError('boundaries')}</Typography>
      )}
    </>
  )
}

export default SelectProjectBoundariesSection
