import { API, graphqlOperation } from 'aws-amplify'

import { useStateContext } from '../stateContext'
import { useUserActions } from '../User/UserActions'
import {
  INIT_ASSETS,
  UPDATE_ASSET,
  CREATE_ASSET,
  ADD_ASSETS,
  ADD_PROJECT_ASSETS,
  ADD_ASSET_TYPES,
} from '../Assets/AssetsReducer'
import {
  SET_CUSTOMERS,
  SET_ACTIVE_CUSTOMER,
  UPDATE_CUSTOMER,
  ADD_CUSTOMER,
} from './CustomerReducer'
import { CLEAR_MAP } from '../Map/MapReducer'
import {
  INIT_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  ADD_PROJECT_LIST,
  ADD_INACTIVE_PROJECT_LIST,
} from '../Project/ProjectReducer'
import { INIT_REPORTS } from '../Reports/ReportsReducer'
import { INIT_SEARCH } from '../Search/SearchReducer'
import { INIT_UI } from '../UI/UIReducer'

import * as mutations from '../../graphql/mutations'
import * as customQueries from '../../graphql/customQueries'
import * as queries from '../../graphql/queries'
import * as subscriptions from '../../graphql/subscriptions'

let customerUpdateSub
let customerCreateSub
let projectUpdateSub
let projectCreateSub
let assetUpdateSub
let assetCreateSub

export const useCustomerActions = () => {
  const {
    state,
    dispatch,
  } = useStateContext()
  const {
    addUserToGroup,
    createGroup,
    createUser,
    getUser,
  } = useUserActions()

  const subscribeProjectCreate = (customerId) => {
    if (projectCreateSub) {
      projectCreateSub.unsubscribe()
    }

    projectCreateSub = API.graphql(graphqlOperation(
      subscriptions.onCreateProject,
      { customerId: customerId })).subscribe({
      next: (data) => dispatch({
        type: ADD_PROJECT,
        payload: {
          addProject: data.value.data.onCreateProject,
        },
      }),
    })
  }

  const subscribeProjectUpdate = (customerId) => {
    if (projectUpdateSub) {
      projectUpdateSub.unsubscribe()
    }

    projectUpdateSub = API.graphql(graphqlOperation(
      subscriptions.onUpdateProject,
      { customerId: customerId })).subscribe({
      next: (data) => dispatch({
        type: UPDATE_PROJECT,
        payload: {
          project: data.value.data.onUpdateProject,
        },
      }),
    })
  }
  const subscribeAssetCreate = (customerId) => {
    if (assetCreateSub) {
      assetCreateSub.unsubscribe()
    }

    assetCreateSub = API.graphql(graphqlOperation(
      subscriptions.onCreateAsset,
      { customerId: customerId })).subscribe({
      next: (data) => dispatch({
        type: CREATE_ASSET,
        payload: {
          asset: data.value.data.onCreateAsset,
        },
      }),
    })
  }

  const subscribeAssetUpdate = (customerId) => {
    if (assetUpdateSub) {
      assetUpdateSub.unsubscribe()
    }

    assetUpdateSub = API.graphql(graphqlOperation(
      subscriptions.onUpdateAsset,
      { customerId: customerId })).subscribe({
      next: (data) => dispatch({
        type: UPDATE_ASSET,
        payload: {
          asset: data.value.data.onUpdateAsset,
        },
      }),
    })
  }

  const subscribeCustomerUpdates = () => {
    if (customerUpdateSub) {customerUpdateSub.unsubscribe()}

    customerUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdateCustomer)).subscribe({
      next: (data) => dispatch({
        type: UPDATE_CUSTOMER,
        payload: {
          updatedCustomer: data.value.data.onUpdateCustomer,
        },
      }),
    })
  }

  const subscribeCustomerCreate = () => {
    if (customerCreateSub) {
      customerCreateSub.unsubscribe()
    }

    customerCreateSub = API.graphql(graphqlOperation(subscriptions.onCreateCustomer)).subscribe({
      next: (data) => dispatch({
        type: ADD_CUSTOMER,
        payload: {
          addCustomer: data.value.data.onCreateCustomer,
        },
      }),
    })
  }

  const processAssets = (customerAssets, projectId = 'none') => {
    const assetsWithTypeOrSubType = customerAssets.filter(
      asset => asset.type || asset.subType,
    )

    const derivedAssetTypes = assetsWithTypeOrSubType.map(asset => {
      return {
        ...asset.type,
        subType: asset.subType,
      }
    })

    const assets = []
    const projectAssets = []

    customerAssets.forEach(asset => {
      if (asset.projectId === 'none') {
        assets.push(asset)
      } else if (asset.projectId === projectId) {
        projectAssets.push(asset)
      }
    })

    return {
      assets,
      projectAssets,
      derivedAssetTypes,
    }
  }

  // const getParsedCustomer = async parsedCustomer => {
  //   const existingCustomer = parsedCustomer.customer
  //   const assetsNextToken = existingCustomer.assets
  //     ? existingCustomer.assets.nextToken
  //     : null
  //   const projectsNextToken = existingCustomer.projects
  //     ? existingCustomer.projects.nextToken
  //     : null
  //   const response = await API.graphql(
  //     graphqlOperation(customQueries.getActiveCustomer, {
  //       id: existingCustomer.id,
  //       assetNextToken: assetsNextToken,
  //       projectsNextToken: projectsNextToken,
  //       assetFilter: {
  //         status: {
  //           eq: 'active',
  //         },
  //       },
  //     })
  //   )
  //   const customer = response.data.getCustomer
  //   let updatedParsedCustomer = {
  //     customer: customer,
  //     projects: customer.projects.items,
  //   }

  //   updatedParsedCustomer.assets = processAssets(customer.assets.items || [])
  //   updatedParsedCustomer.customer.hydrated = true

  //   return updatedParsedCustomer
  // }

  // const addActiveCustomerState = async parsedCustomer => {
  //   await dispatch({
  //     type: ADD_PROJECT_LIST,
  //     payload: {
  //       addProjectList: parsedCustomer.projects || [],
  //     },
  //   })
  //   await dispatch({
  //     type: ADD_ASSET_HISTORYLIST,
  //     payload: {
  //       assetHistoryList: parsedCustomer.assets.assetHistoryList || [],
  //     },
  //   })
  //   await dispatch({
  //     type: ADD_ASSET_TYPES,
  //     payload: {
  //       assetTypes: parsedCustomer.assets.derivedAssetTypes || [],
  //     },
  //   })
  //   await dispatch({
  //     type: ADD_ASSETS,
  //     payload: {
  //       addAssetList: parsedCustomer.assets.assets || [],
  //     },
  //   })
  // }

  // const getPaginatedAssetsAndProjects = async parsedCustomer => {
  //   let updatedParsedCustomer = {
  //     ...parsedCustomer,
  //   }
  //   const customer = parsedCustomer.customer
  //   if (customer.assets.nextToken || customer.projects.nextToken) {
  //     const localParsedCustomer = await getParsedCustomer(parsedCustomer)
  //     let projects = [...parsedCustomer.projects]
  //     if (customer.projects.nextToken !== null) {
  //       projects = [...parsedCustomer.projects, ...localParsedCustomer.projects]
  //     }
  //     let assets = { ...parsedCustomer.assets }
  //     if (customer.assets.nextToken !== null) {
  //       assets = {
  //         assets: [
  //           ...parsedCustomer.assets.assets,
  //           ...localParsedCustomer.assets.assets,
  //         ],
  //         derivedAssetTypes: [
  //           ...parsedCustomer.assets.derivedAssetTypes,
  //           ...localParsedCustomer.assets.derivedAssetTypes,
  //         ],
  //       }
  //     }

  //     updatedParsedCustomer = {
  //       projects: projects,
  //       assets: assets,
  //       customer: { ...localParsedCustomer.customer },
  //     }

  //     return await getPaginatedAssetsAndProjects(updatedParsedCustomer)
  //   }
  //   addActiveCustomerState(updatedParsedCustomer)

  //   return updatedParsedCustomer
  // }

  const getCustomerAssets = async (customerId, projectId = 'none') => {
    let response
    let assets = []
    let nextToken = null
    let loops = 0
    // console.log('  customerId:', customerId)
    // console.log('  projectId:', projectId)

    if (!projectId) {
      dispatch({ type: INIT_ASSETS })
    }

    do {
      response = await API.graphql(
        graphqlOperation(queries.assetsByCustomer, {
          customerId: customerId,
          projectId: { eq: projectId },
          nextToken: nextToken,
          limit: 1000,
          filter: {
            status: {
              eq: 'active',
            },
          },
        }),
      )
      nextToken = response.data.assetsByCustomer.nextToken
      // console.log(response)
      assets = [...assets, ...response.data.assetsByCustomer.items]
      //console.log(assets)

      if (loops % 3 === 0 || !nextToken) {
        const assetsAndTypes = processAssets(assets, projectId)

        dispatch({
          type: ADD_ASSET_TYPES,
          payload: {
            assetTypes: assetsAndTypes.derivedAssetTypes || [],
          },
        })

        dispatch({
          type: ADD_ASSETS,
          payload: {
            assetList: assetsAndTypes.assets,
            nextToken,
          },
        })

        if (projectId !== 'none') {
          dispatch({
            type: ADD_PROJECT_ASSETS,
            payload: {
              projectId,
              projectAssetList: assetsAndTypes.projectAssets,
              nextToken,
            },
          })
        }

        assets = []
      }

      loops++
    } while (nextToken)
  }

  const cleanBoundaries = project => {
    if (project.boundaries) {
      for (let boundary of project.boundaries) {
        Reflect.deleteProperty(boundary, '__typename')
        //Object.keys(boundary).forEach(function(key) { boundary[key] === '__typename' && delete boundary[key] })
      }
    }
  }

  const getCustomerProjects = async customerId => {
    let response
    let projects = []
    let inactiveProjects = []
    let nextToken = null
    let loops = 0

    dispatch({ type: INIT_PROJECTS })

    do {
      response = await API.graphql(
        graphqlOperation(customQueries.projectsByCustomerId, {
          customerId: customerId,
          nextToken: nextToken,
          limit: 1000,
        }),
      )
      // console.log(response)

      nextToken = response.data.projectsByCustomerId.nextToken
      for (const project of response.data.projectsByCustomerId.items) {
        cleanBoundaries(project)
        if (project.status === 'active' || project.status === 'activating') {
          projects.push(project)
        } else {
          inactiveProjects.push(project)
        }
      }

      // console.log(nextToken)
      if (loops % 3 === 0 || !nextToken) {
        if (projects) {
          dispatch({
            type: ADD_PROJECT_LIST,
            payload: {
              addProjectList: projects,
            },
          })
          projects = []
        }
        if (inactiveProjects) {
          dispatch({
            type: ADD_INACTIVE_PROJECT_LIST,
            payload: {
              addInactiveProjectList: inactiveProjects,
            },
          })
          inactiveProjects = []
        }
      }
      loops++
    } while (nextToken)
  }

  // const hydrateCustomer = async customer => {
  //   const parsedCustomer = await getParsedCustomer({ customer: customer })
  //   await addActiveCustomerState(parsedCustomer)
  //   if (
  //     parsedCustomer.customer.assets.nextToken ||
  //     parsedCustomer.customer.projects.nextToken
  //   ) {
  //     getPaginatedAssetsAndProjects(parsedCustomer)
  //   }
  //   const activeCustomer = {
  //     ...parsedCustomer.customer,
  //   }
  //   Reflect.deleteProperty(activeCustomer, 'projects')
  //   Reflect.deleteProperty(activeCustomer, 'assets')
  //   return activeCustomer
  // }

  const setActiveCustomer = async customer => {
    // console.log('set active customer called')
    let activeCustomer = {
      ...customer,
    }

    dispatch({ type: INIT_ASSETS })
    dispatch({ type: CLEAR_MAP })
    // dispatch({ type: INIT_PROJECTS })
    dispatch({ type: INIT_REPORTS })
    dispatch({ type: INIT_SEARCH })
    dispatch({ type: INIT_UI })

    //hydrateCustomer(activeCustomer)

    //intentionally fire and forgetting these as they can take a long time to complete
    getCustomerAssets(customer.id)
    getCustomerProjects(customer.id)

    dispatch({
      type: SET_ACTIVE_CUSTOMER,
      payload: {
        activeCustomer,
      },
    })

    // console.log(state)
    subscribeAssetUpdate(activeCustomer.id)
    subscribeAssetCreate(activeCustomer.id)
    // //subscribeAssetHistoryCreate()
    subscribeProjectCreate(activeCustomer.id)
    subscribeProjectUpdate(activeCustomer.id)
    subscribeCustomerCreate()
    subscribeCustomerUpdates()
    return activeCustomer
  }

  const getCustomers = async () => {
    if (
      state.customers &&
      state.customers.customerList &&
      state.customers.customerList.length > 0
    ) {
      return state.customers.customerList
    }
    try {
      const { data } = await API.graphql(
        graphqlOperation(customQueries.listCustomers, {
          filter: {
            status: {
              eq: 'Active',
            },
          },
        }),
      )
      const customerList = data.listCustomers.items || []

      dispatch({
        type: SET_CUSTOMERS,
        payload: {
          customerList,
        },
      })

      if (customerList && customerList.length > 0) {
        const activeCustomer = customerList[0]
        await setActiveCustomer(activeCustomer)
      }

      return customerList
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const createCustomer = async ({ name, email, status = 'Active' }) => {
    // create user
    try {
      // get admin user
      await getUser(email)
    } catch (err) {
      // create admin user because it doesn't exist
      await createUser(email)
    }

    const preparedName = name.replace(/ /g, '_').toLowerCase()

    const id = preparedName + '_' + new Date().getTime()
    const userGroup = `customer-${id}`
    const adminGroup = `customerAdmin-${id}`

    // create customer group
    await createGroup(userGroup)
    await createGroup(adminGroup)

    // add admin to 'customers' group
    await addUserToGroup(email, userGroup)
    await addUserToGroup(email, adminGroup)

    // add customer to DB
    const customer = API.graphql(
      graphqlOperation(mutations.createCustomer, {
        input: {
          id,
          name,
          email,
          status,
          userGroups: [userGroup],
          adminGroups: [adminGroup],
        },
      }),
    )

    return customer
  }

  const getActiveCustomer = async () => {
    if (!state.customers.activeCustomer) {
      let customers = state.customers.customerList
      if (!customers.lengh) {
        customers = (await getCustomers()) || []
      }
      let activeCustomer = customers[0]
      const hydratedCustomer = await setActiveCustomer(activeCustomer || {})

      return hydratedCustomer
    }

    return state.customers.activeCustomer
  }

  const updateCustomer = async input => {
    return API.graphql(
      graphqlOperation(mutations.updateCustomer, {
        input,
      }),
    )
  }

  return {
    createCustomer,
    customerState: state.customers,
    getCustomerAssets,
    getCustomers,
    getActiveCustomer,
    setActiveCustomer,
    updateCustomer,
  }
}
