import React from 'react'
import { useForm } from '../../hooks/Forms'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Typography } from '@material-ui/core'
import SpinnerButton from '../elements/SpinnerButton'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()

  const {
    formState,
    getFieldError,
    handleSubmit,
    handleInputChange,
    isValid,
  } = useForm({
    password: 'required',
    confirmPassword: 'matches:password',
  })

  const { resetPassword } = useAuthActions()

  return (
    <>
      <form onSubmit={e => handleSubmit(resetPassword, e)}>
        {formState.error && (
          <Grid item className={classes.errorMessage}>
            <Typography variant="body1" color="error">
              {formState.error}
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.verticalGutters}>
          <TextField
            autoFocus
            fullWidth
            margin="normal"
            label="NEW PASSWORD"
            error={!!getFieldError('password')}
            name="password"
            variant="outlined"
            onChange={handleInputChange('password')}
            type="password"
          />
        </Grid>
        <Grid item className={classes.verticalGutters}>
          <TextField
            fullWidth
            margin="normal"
            label="CONFIRM PASSWORD"
            error={!!getFieldError('confirmPassword')}
            name="confirmPassword"
            variant="outlined"
            onChange={handleInputChange('confirmPassword')}
            type="password"
          />
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <SpinnerButton
            type="submit"
            valid={isValid()}
            loading={formState.loading}
          >
            Save
          </SpinnerButton>
        </Grid>
      </form>
    </>
  )
}
