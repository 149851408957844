export const INIT_AUTH = 'INIT_AUTH'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_PASSWORD_CHANGE_REQUIRED = 'SET_PASSWORD_CHANGE_REQUIRED'
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const SET_FORGOT_PASSWORD_SUBMIT = 'SET_FORGOT_PASSWORD_SUBMIT'
export const SET_USER = 'SET_USER'

export const initialState = {
  passwordChangeRequired: false,
  forgotPassword: false,
  forgotPasswordSubmit: false,
  authenticated: false,
  user: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_AUTH:
      return {
        ...initialState,
      }
    case SET_PASSWORD_CHANGE_REQUIRED:
      const { passwordChangeRequired } = payload

      return {
        ...state,
        passwordChangeRequired,
      }
    case SET_USER:
      const { user } = payload

      return {
        ...state,
        user,
      }
    case SET_AUTHENTICATED:
      const { authenticated } = payload

      return {
        ...state,
        authenticated,
      }
    case SET_FORGOT_PASSWORD:
      const { forgotPassword } = payload

      return {
        ...state,
        forgotPassword,
      }

    case SET_FORGOT_PASSWORD_SUBMIT:
      const { forgotPasswordSubmit } = payload

      return {
        ...state,
        forgotPasswordSubmit,
      }
    default:
      return state
  }
}
