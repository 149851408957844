import React, { useState, useEffect } from 'react'
import { Popover, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import uniqBy from 'lodash/uniqBy'

import CheckboxTree from './CheckboxTree'
import { sortBy, synchronous, safeGet } from '../../util'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
    },
  },
  textFieldWrap: {
    width: '100%',
    paddingRight: '20px !important',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formGroup: {
    maxHeight: theme.spacing(26),
    padding: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'unset',
  },
  treeContainer: {
    maxHeight: theme.spacing(50),
    overflow: 'auto',
  },
}))

const MapFilterMenu = ({
  open,
  onClose,
  onChange,
  getData,
  setData = () => {},
  anchor,
  filters,
  setAnomalyFilter,
  defaultChecked,
}) => {
  const classes = useStyles()
  const { cancellablePromise } = useCancellablePromise()
  const [anchorEl, setAnchorEl] = useState(null)
  const [list, setList] = useState([])
  const [textFilter, setTextFilter] = useState('')
  const [checkFilter, setCheckFilter] = useState([])
  const [treeDefaultChecked, setTreeDefaultChecked] = useState(false)

  const getSelectedIds = list => {
    let output = []

    for (let node of list) {
      if (node.selected) {
        output = [...output, node.id]
      }

      if (node.children) {
        output = [...output, ...getSelectedIds(node.children)]
      }
    }

    return output
  }

  const restoreFilters = (currentList, currentFilters) => {
    if (!(currentList instanceof Array)) {
      return currentList
    }

    return currentList.map(item => {
      return {
        ...item,
        children: restoreFilters(safeGet('children', item), currentFilters),
        selected: currentFilters.indexOf(safeGet('id', item)) > -1,
      }
    })
  }

  useEffect(() => {
    if (anchor) {
      setAnchorEl(anchor.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor])

  useEffect(() => {
    if (!getData) {
      return
    }

    cancellablePromise(synchronous(getData))
      .then(results => {
        // alphabetize by name
        results = sortBy('name', results)

        // remove duplicates
        results = uniqBy(results, 'name')

        // keep checks after unmount
        results = restoreFilters(results, checkFilter)

        setList(results)

        // initialize the checklist by "checking" everything
        if (list.length !== results.length && defaultChecked) {
          setCheckFilter(results.map(d => d.id))
          setTreeDefaultChecked(true)
        }
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })

    setTreeDefaultChecked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  useEffect(() => {
    onChange({
      checkFilter,
      textFilter,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFilter, textFilter])

  return (
    <Popover
      className={classes.root}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item xs className={classes.textFieldWrap}>
          <TextField
            fullWidth
            autoFocus
            margin="dense"
            label="FILTER"
            value={textFilter}
            onChange={e => setTextFilter(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item className={classes.treeContainer}>
          <CheckboxTree
            selected={checkFilter}
            onChange={async list => setCheckFilter(getSelectedIds(list))}
            setSelected={setCheckFilter}
            structure={list}
            setStructure={setData}
            showActions
            defaultChecked={treeDefaultChecked}
          />
        </Grid>
      </Grid>
    </Popover>
  )
}

export default MapFilterMenu
