import React from 'react'
import { SvgIcon } from '@material-ui/core'
import Icons from '../../assets/icons/symbol-defs.svg'

const SystemIcon = ({ className, color, name }) => {
  return (
    <SvgIcon className={className} color={color}>
      <svg>
        <use xlinkHref={`${Icons}#${name}`} />
      </svg>
    </SvgIcon>
  )
}

export default SystemIcon
