export const INIT_ASSETS = 'INIT_ASSETS'
export const CREATE_ASSET = 'CREATE_ASSET'
export const UPDATE_ASSET = 'UPDATE_ASSET'
// export const ADD_ASSET_HISTORY = 'ADD_ASSET_HISTORY'
export const ADD_ASSET_TYPES = 'ADD_ASSET_TYPES'
export const ADD_ASSETS = 'ADD_ASSETS'
export const ADD_PROJECT_ASSETS = 'ADD_PROJECT_ASSETS'
// export const ADD_ASSET_HISTORYLIST = 'ADD_ASSET_HISTORYLIST'

export const initialState = {
  loading: true,
  assetList: [],
  projectAssetList: [],
  assetHistoryList: [],
  selectedAssets: [],
  assetTypes: [],
  lastProject: 'none',
}

export const reducer = (state = initialState, {
  type,
  payload,
}) => {
  switch (type) {
    case INIT_ASSETS:
      return {
        ...initialState,
      }
    case ADD_ASSET_TYPES:
      return {
        ...state,
        assetTypes: [...state.assetTypes, ...payload.assetTypes],
      }
    case ADD_ASSETS:
      return (() => {
        const payloadAssetIDs = new Set(payload.assetList.map(asset => asset.id))
        return {
          ...state,
          loading: !!payload.nextToken,
          assetList: [
            ...payload.assetList,
            ...state.assetList.filter(asset => !payloadAssetIDs.has(asset.id)), // Filter out duplicates.
          ],
        }
      })()
    case ADD_PROJECT_ASSETS:
      return (() => {
        const payloadAssetIDs = new Set(payload.projectAssetList.map(asset => asset.id))
        const projectAssetList = (payload.projectId !== state.lastProject)
          ? [...payload.projectAssetList]
          : [
            ...payload.projectAssetList,
            ...state.projectAssetList.filter(asset => !payloadAssetIDs.has(asset.id)), // Filter out duplicates.
          ]

        return {
          ...state,
          loading: !!payload.nextToken,
          projectAssetList,
          lastProject: payload.projectId,
        }
      })()
    case CREATE_ASSET:
      if (payload.asset) {
        if (payload.asset.projectId === 'none') {
          return {
            ...state,
            assetList: [...state.assetList, payload.asset],
          }
        }
        return {
          ...state,
          projectAssetList: [...state.projectAssetList, payload.asset],
        }
      }
      return state
    case UPDATE_ASSET:
      if (payload.asset) {
        if (payload.asset.projectId === 'none') {
          return {
            ...state,
            assetList: state.assetList.map(asset => asset.id === payload.asset.id ? payload.asset : asset),
          }
        }
        return {
          ...state,
          projectAssetList: state.projectAssetList.map(asset => asset.id === payload.asset.id ? payload.asset : asset),
        }
      }
      return state
    // case ADD_ASSET_HISTORY:
    //   const existingAssetHistory = state.assetHistoryList.find(
    //     assetHistory => assetHistory.assetId === payload.assetHistory.assetId
    //   )
    //   if (!existingAssetHistory) {
    //     return {
    //       ...state,
    //       assetHistoryList: [
    //         ...state.assetHistoryList,
    //         payload.assetHistory,
    //       ],
    //     }
    //   }
    //   return {
    //     ...state,
    //     assetHistoryList: state.assetHistoryList.map(assetHistory => {
    //       if (assetHistory.id === payload.assetHistory.id) {
    //         return payload.assetHistory
    //       }
    //       return assetHistory
    //     }),
    //   }
    // case ADD_ASSET_HISTORYLIST:
    //   return {
    //     ...state,
    //     assetHistoryList: [
    //       ...state.assetHistoryList,
    //       ...payload.assetHistoryList,
    //     ],
    //   }
    default:
      return state
  }
}
