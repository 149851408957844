import React from 'react'

import { useStateContext } from './store/stateContext'
import Layout from './components/Layout'
import LoginPage from './components/pages/LoginPage'

const App = () => {
  const { state } = useStateContext()

  return state.auth.authenticated ? <Layout /> : <LoginPage />
}

export default App
