import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import SpinnerButton from '../elements/SpinnerButton'
import { useForm } from '../../hooks/Forms'
import { reduceAssetTypesToNames } from '../../util'
import { useConstantsActions } from '../../store/Constants/ConstantsActions'
import AssetIconSelect from '../elements/AssetIconSelect'
import { useStateContext } from '../../store/stateContext'

const CreateAssetTypeDialog = ({
  closeForm = () => {},
  onSave = () => {},
  open,
}) => {
  const { state } = useStateContext()
  const assetTypesState = state.assets.assetTypes
  const [assetTypeNames, setAssetTypeNames] = useState([])

  const { createAssetType } = useConstantsActions()

  const {
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    name: `required|unique:${assetTypeNames.join(',')}`,
    idleThreshold: 'number:>0',
    offlineThreshold: 'number:>0',
  })

  useEffect(() => {
    // Set up the validation rules
    // for the name using the assetTypes
    setAssetTypeNames(reduceAssetTypesToNames(assetTypesState))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypesState])

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const onSubmit = async ({ name, iconName, idleThreshold, offlineThreshold }) => {
    const assetType = await createAssetType({
      name,
      iconName,
      idleThreshold,
      offlineThreshold,
    })

    closeForm()
    clearFormState()
    onSave(assetType)
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open}>
      <form onSubmit={e => handleSubmit(onSubmit, e)}>
        <DialogTitle>New Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <AssetIconSelect
                onChange={handleInputChange('iconName')}
                value={getValue('iconName')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!getFieldError('name')}
                helperText={getFieldError('name')}
                fullWidth
                label="Type"
                onChange={handleInputChange('name')}
                value={getValue('name')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!getFieldError('idleThreshold')}
                helperText={getFieldError('idleThreshold')}
                fullWidth
                label="Idle Threshold (days)"
                onChange={handleInputChange('idleThreshold', 0)}
                type="number"
                value={getValue('idleThreshold')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!getFieldError('offlineThreshold')}
                helperText={getFieldError('offlineThreshold')}
                fullWidth
                label="Offline Threshold (hours)"
                onChange={handleInputChange('offlineThreshold', 0)}
                type="number"
                value={getValue('offlineThreshold')}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type="submit"
            valid={isValid()}
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateAssetTypeDialog.propTypes = {
  closeForm: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
}

export default CreateAssetTypeDialog
