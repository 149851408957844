import React from 'react'
import { useForm } from '../../hooks/Forms'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, TextField, Typography } from '@material-ui/core'
import SpinnerButton from '../elements/SpinnerButton'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()

  const {
    formState,
    getFieldError,
    handleSubmit,
    handleInputChange,
    isValid,
  } = useForm({
    email: 'required|email',
    password: 'required',
  })

  const { signIn, forgotPassword } = useAuthActions()

  const handelForgotPassword = () => {
    forgotPassword()
  }

  return (
    <>
      <form onSubmit={e => handleSubmit(signIn, e)} data-test="loginForm">
        {formState.error && (
          <Grid item className={classes.errorMessage}>
            <Typography
              variant="body1"
              color="error"
              data-test="formErrorMessage"
            >
              {formState.error}
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.verticalGutters}>
          <TextField
            fullWidth
            autoFocus
            data-test="emailField"
            margin="normal"
            label="EMAIL"
            error={!!getFieldError('email')}
            onChange={handleInputChange('email')}
            variant="outlined"
          />
        </Grid>
        <Grid item className={classes.verticalGutters}>
          <TextField
            fullWidth
            data-test="passwordField"
            label="PASSWORD"
            error={!!getFieldError('password')}
            margin="normal"
            onChange={handleInputChange('password')}
            type="password"
            variant="outlined"
          />
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <SpinnerButton
            data-test="signinButton"
            type="submit"
            valid={isValid()}
            loading={formState.loading}
          >
            Login
          </SpinnerButton>
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <Button onClick={handelForgotPassword}>Forgot password?</Button>
        </Grid>
      </form>
    </>
  )
}
