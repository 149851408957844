import { API, graphqlOperation } from 'aws-amplify'
import { useStateContext } from '../stateContext'
import { dateToUnix } from '../../util'

import * as customQueries from '../../graphql/customQueries'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'

export const useAssetsActions = () => {
  const { state } = useStateContext()

  const getAsset = id => {
    return state.assets.assetList.find(asset => asset.id === id) ||
      state.assets.projectAssetList.find(asset => asset.id === id)
  }

  const getAssetFromDB = async id => {
    const { data } = await API.graphql(
      graphqlOperation(queries.getAsset, {
        id,
      }),
    )
    console.log(data)
    return data.getAsset
  }

  const getAssets = (getProjectAssets = true) => {
    // const { data } = await API.graphql(
    //   graphqlOperation(listAssetsQuery, {
    //     limit: DEFAULT_RECORD_LIMIT,
    //     historyLimit: 1,
    //     ...filters,
    //   })
    // )
    // const assetList = data.listAssets.items

    // dispatch({
    //   type: SET_ASSETS,
    //   payload: { assetList },
    // })

    // return assetList

    if (getProjectAssets) {
      return [...state.assets.assetList, ...state.assets.projectAssetList]
    }

    return state.assets.assetList
  }

  const getAssetsHistory = () => {
    return state.assets.assetHistoryList || []
  }

  const getAssetHistory = async (filter, asset, callbackFunction) => {
    // console.log(state.assets.assetHistoryList)

    // if (asset) {
    //   const selectedTime = filter.eventTs.ge
    //   const output = []
    //   const assetHistoryEvent = asset.assetHistory.items[0]

    //   const posVelocity = 0.001
    //   const dirVelocity = 0.5

    //   // generate fake history
    //   for (
    //     let i = 1, ts = new Date().getTime() / 1000; ts > selectedTime; i++, ts -= 86400
    //   ) {
    //     output.push({
    //       ...assetHistoryEvent,
    //       eventTs: ts,
    //       coordinate: {
    //         latitude: posVelocity * i * Math.cos(i * dirVelocity) +
    //           assetHistoryEvent.coordinate.latitude,
    //         longitude: posVelocity * i * Math.sin(i * dirVelocity) +
    //           assetHistoryEvent.coordinate.longitude,
    //       },
    //     })
    //   }

    //   return output
    // }
    let lastToken = null
    let assetHistories = []
    let requestCount = 0
    let requestsPerMapUpdate = 10

    do {
      const { data } = await API.graphql(
        graphqlOperation(customQueries.listAssetHistorys, {
          ...filter,
          nextToken: lastToken,
          limit: 1000,
        }),
      )

      // console.log(JSON.stringify(data))
      lastToken = data.listAssetHistorys.nextToken
      assetHistories = [...assetHistories, ...data.listAssetHistorys.items]

      if (requestCount % requestsPerMapUpdate === 0 && callbackFunction) {
        callbackFunction(assetHistories)
      }

      requestCount++

      //console.log(lastToken)
    } while (lastToken)

    if (requestCount % requestsPerMapUpdate !== 0) {
      callbackFunction(assetHistories)
    }

    // const assetHistoryList = data.listAssetHistorys.items.sort(
    //   (a, b) => -(a.eventTs - b.eventTs)
    // )
    // TODO deterime if this needs to be held in state
    // dispatch({
    //   type: SET_ASSETHISTORYLIST,
    //   payload: { assetHistoryList },
    // })
  }

  const getAssetTypes = filters => {
    return state.assets.assetTypes || []
  }

  const editAsset = async ({
    id,
    unitNumber,
    status,
    description,
    startDate,
    capacity,
    year,
    model,
    make,
    assetTypeId,
    assetSubTypeId,
    assetDimensionsId,
    assetSupplierId,
    annualInspection,
  }) => {
    console.log(id)
    // only query with properties in schema
    const input = {
      id,
      unitNumber,
      status,
      description,
      startDate: dateToUnix(startDate),
      capacity,
      year,
      model,
      make,
      assetTypeId,
      assetSubTypeId,
      assetDimensionsId,
      assetSupplierId,
      annualInspection,
    }

    for (let prop in input) {
      if (typeof input[prop] === 'undefined') {
        Reflect.deleteProperty(input, prop)
      }
    }

    const updatedAsset = await API.graphql(
      graphqlOperation(mutations.updateAsset, {
        input,
      }),
    )

    return updatedAsset.data.updateAsset
  }

  return {
    getAsset,
    getAssets,
    getAssetHistory,
    editAsset,
    getAssetsHistory,
    getAssetTypes,
    getAssetFromDB,
  }
}
