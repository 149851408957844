import moment from 'moment'
import WebMercatorViewport from 'viewport-mercator-project'
import uniqueSlug from 'unique-slug'

const datetimeFormat = 'MMMM Do YYYY, h:mm:ss a'
const dateFormat = 'MMMM Do YYYY'
const timeFormat = 'h:mma'
const SECONDS_IN_DAY = 60 * 60 * 24

/**
 * Formats datetime to be displayed
 * @param {int} time
 * @return {string} Formatted datetime
 */
export const formatDateTime = (time, format = datetimeFormat) => {
  if (!time && typeof time !== 'number') {
    return time
  }

  const date = typeof time === 'number' ? moment.unix(time) : moment(time)

  return date.format(format)
}

/**
 * Formats date to be displayed
 * @param {int} time
 * @return {string} Formatted date
 */
export const formatDate = (time, format = dateFormat) => {
  if (!time && typeof time !== 'number') {
    return time
  }

  const date = typeof time === 'number' ? moment.unix(time) : moment(time)

  return date.format(format)
}

/**
 * Formats time to be displayed
 * @param {int} time
 * @return {string} Formatted time
 */
export const formatTime = (time, format = timeFormat) => {
  if (!time && typeof time !== 'number') {
    return time
  }

  const date = typeof time === 'number' ? moment.unix(time) : moment(time)

  return date.format(format)
}

/**
 * Converts Date to seconds since Unix epoch
 * @param {Date} date
 * @return {number}
 */
export const dateToUnix = date => {
  if (!date) {
    return date
  }

  if (typeof date === 'number') {
    return date
  }

  if (moment.isMoment(date)) {
    return date.unix()
  }

  return Math.floor(date.getTime() / 1000)
}

/**
 * Debounce - Prevents rapid callback execution
 * @param {string}   key       Unique key for operation
 * @param {function} callback  Function to be called after delay
 * @param {number}   delay     Milliseconds to wait before executing callback
 */
const debounceTimeouts = {}
export const debounce = (key, callback, delay = 500) => {
  clearTimeout(debounceTimeouts[key])
  debounceTimeouts[key] = setTimeout(() => {
    callback()
  }, delay)
}

/**
 * Converts array of tuples to list of coordinate objects
 * @param {object<latitude, longitude>[]} coordinateList
 * @return {number[][]}
 */
export const convertTuplesToCoordinates = tupleList => {
  if (!tupleList) {
    return tupleList
  }

  return tupleList.map(item => ({
    longitude: item.longitude || item[0],
    latitude: item.latitude || item[1],
  }))
}

/**
 * Creates unique hash of specified length
 * @param {number} length  Number of characters in hash
 * @return {string} Unique ID
 */
let callCount = 0
export const uniqueId = (length = 7) => {
  let output = ''

  const t = new Date().getTime()
  let charsNeeded = length
  while (charsNeeded > 0) {
    let hash = uniqueSlug((t + callCount++).toString())
    output += hash
    charsNeeded -= hash.length
  }

  return output.substr(0, length)
}

/**
 * Filters an object based on the objects keys
 * @param {array} keys   Array of strings
 * @param {object} obj   Object to be filtered
 */
export const filterObj = (keys, obj) => {
  const newObj = {}
  for (let key in obj) {
    if (keys.includes(key)) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

/**
 * Get object property without throwing errors on undefined
 * @param {object} obj
 * @param {string} prop  Name of property or dot separated prop path
 * @return Property value, or null
 */
export const safeGet = (key, obj = window, defaultValue) => {
  let value = obj

  const props = key.split('.')
  for (let prop of props) {
    if (
      value &&
      typeof value === 'object' &&
      Object.hasOwnProperty.bind(value)(prop)
    ) {
      value = value[prop]
    } else {
      value = null
      break
    }
  }

  return value === null ? defaultValue : value
}

/**
 * Filters list of objects by specified property.
 * @param {string[]} filters  Array of filter strings
 * @param {object[]} list     Array of objects
 * @param {Function} override Callback function that keeps item if function returns true
 * @param {string[]} props    Name of property
 * @return {*[]} Filtered array
 */
export const filterBy = ({ filters, list, override, props }) => {
  if (!filters) {
    return list
  }

  if (!(props instanceof Array)) {
    props = props.split(',')
  }

  if (!(filters instanceof Array)) {
    filters = filters.split(',')
  }

  return list.filter(item => {
    if (typeof item !== 'object') {
      return false
    }

    if (typeof override === 'function' && override(item)) {
      return true
    }

    for (let prop of props) {
      var itemValue = safeGet(prop, item)
      if (itemValue === null || typeof itemValue === 'undefined') {
        itemValue = ''
      }

      if (
        filters.indexOf(itemValue) >= 0 ||
        filters.indexOf(itemValue.toString().toLowerCase()) >= 0
      ) {
        return true
      }
    }

    return false
  })
}

/**
 * Sorts list of objects by property name
 * @param {string}    prop  Property name
 * @param {object[]}  list  Array of objects
 */
export const sortBy = (prop, list) => {
  return list.sort((a, b) => (a[prop] > b[prop] ? 1 : -1))
}

/**
 * Converts synchronous data or function to Promise
 * @param {function|*} input
 * @return {Promise}
 */
export const synchronous = (input, ...args) => {
  return new Promise(resolve => {
    if (typeof input === 'function') {
      resolve(input(...args))
    }

    resolve(input)
  })
}

/**
 * Calculates number days based on given seconds
 * @param {number} seconds
 * @param {number} decimalDigits
 * @return {number}
 */
export const secondsToDays = (seconds, decimalDigits = 1) => {
  if (!seconds) {
    return 0
  }

  return Math.round(seconds / SECONDS_IN_DAY).toFixed(decimalDigits)
}

/**
 * Calculates number seconds in given days
 * @param {number} days
 * @return {number}
 */
export const daysToSeconds = days => {
  if (!days) {
    return 0
  }

  return Math.round(days * SECONDS_IN_DAY)
}

/**
 * Converts array of coordinate objects to array of tuples
 * @param {object<latitude, longitude>[]} coordinateList
 * @return {number[][]}
 */
export const convertCoordinatesToTuples = coordinateList => {
  if (!coordinateList) {
    return coordinateList
  }

  return coordinateList
    .filter(item => item.longitude && item.latitude)
    .map(item => [
      safeGet('longitude', item) || item[0],
      safeGet('latitude', item) || item[1],
    ])
}

/**
 * Calculates map position and zoom to display all points
 * @param {object} points     Coordinates to put in view
 * @param {object} viewport   Current viewport state
 * @return {object<latitude, longitude, zoom>}
 */
export const boundsViewport = (points, viewport, padding = 80) => {
  try {
    if (!points || points.length === 0) {
      return
    }

    if (!viewport || !viewport.width) {
      return
    }

    const vp = new WebMercatorViewport(viewport)

    points = convertCoordinatesToTuples(points).filter(
      point => point[1] <= 90 && point[1] >= -90,
    )

    const lngs = points.map(point => point[0])
    const lats = points.map(point => point[1])

    const tlCorner = [Math.min(...lngs), Math.min(...lats)]
    const brCorner = [Math.max(...lngs), Math.max(...lats)]

    const results = vp.fitBounds([tlCorner, brCorner], {
      padding,
    })

    return results
  } catch (err) {
    console.error(`boundsViewport() Error: ${err.message}`)
    console.log({ points, viewport, padding })
  }

  return null
}

/**
 * Takes array of asset types and returns array of just the names
 * @param {array} assetTypes
 * @return {array} names
 */
export const reduceAssetTypesToNames = assetTypes => {
  if (!assetTypes) {
    return assetTypes
  }

  const subTypes = assetTypes.reduce((acc, cur) => {
    const curSubTypes = safeGet('subType.items', cur)
    if (curSubTypes) {
      for (let index = 0; index < curSubTypes.length; index++) {
        acc.push(curSubTypes[index].name)
      }
    }
    return acc
  }, [])

  const types = assetTypes.reduce((acc, cur) => {
    acc.push(cur.name)
    return acc
  }, [])

  return types.concat(subTypes)
}

/**
 * Takes array of asset types and returns array of just the names
 * @param {int} asset receivedTs (last time it was connected)
 * @param {object} assetType (or subtype) with an idle threshold
 * @return {bool} True if still 'connected'
 */
export const isAssetConnected = (asset) => {
  let offlineThreshold = -1
  if (asset.subType && asset.subType.offlineThreshold) {
    offlineThreshold = asset.subType.offlineThreshold
  } else if (asset.type && asset.type.offlineThreshold) {
    offlineThreshold = asset.type.offlineThreshold
  } else {
    // if no offlineThresold is set, assume always online
    return true
  }
  if (!asset.receivedTs) {
    // we've never received any events from this asset, it's offline
    return false
  }

  const offlineTime = moment().subtract(offlineThreshold, 'hours')
  const timeStamp = moment.unix(asset.receivedTs)
  const isConnected = timeStamp.isAfter(offlineTime)

  return isConnected
}
