import React from 'react'
import { useForm } from '../../hooks/Forms'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Typography } from '@material-ui/core'
import SpinnerButton from '../elements/SpinnerButton'

// NOTE TO SELF
// ONCE YOU HAVE THE VERIFY EMAIL LAMBDA UP,
// YOU NEED TO FINISH BUILDING AND TESTING THIS FORM
// TO MAKE SURE IT WORKS

const useStyles = makeStyles(theme => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()

  const {
    formState,
    getFieldError,
    handleSubmit,
    handleInputChange,
    isValid,
  } = useForm({
    email: 'required|email',
    password: 'required',
  })

  const { forgotPasswordSubmit } = useAuthActions()

  return (
    <>
      <form
        onSubmit={e => handleSubmit(forgotPasswordSubmit, e)}
        data-test="loginForm"
      >
        {formState.error && (
          <Grid item className={classes.errorMessage}>
            <Typography
              variant="body1"
              color="error"
              data-test="formErrorMessage"
            >
              {formState.error}
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.verticalGutters}>
          <TextField
            autoFocus
            fullWidth
            data-test="emailField"
            margin="normal"
            label="EMAIL"
            error={!!getFieldError('email')}
            onChange={handleInputChange('email')}
            variant="outlined"
          />
        </Grid>
        <Grid item className={classes.verticalGutters}>
          <TextField
            fullWidth
            data-test="verificationCodeField"
            margin="normal"
            label="VERIFICATION CODE"
            error={!!getFieldError('verificationCode')}
            onChange={handleInputChange('verificationCode')}
            variant="outlined"
          />
        </Grid>
        <Grid item className={classes.verticalGutters}>
          <TextField
            fullWidth
            data-test="passwordField"
            label="NEW PASSWORD"
            error={!!getFieldError('password')}
            margin="normal"
            onChange={handleInputChange('password')}
            type="password"
            variant="outlined"
          />
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <SpinnerButton
            data-test="signinButton"
            type="submit"
            valid={isValid()}
            loading={formState.loading}
          >
            Login
          </SpinnerButton>
        </Grid>
      </form>
    </>
  )
}
