import React from 'react'
import { useForm } from '../../hooks/Forms'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, TextField, Typography } from '@material-ui/core'
import SpinnerButton from '../elements/SpinnerButton'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()

  const {
    formState,
    getFieldError,
    handleSubmit,
    handleInputChange,
    isValid,
  } = useForm({
    email: 'required|email',
  })

  const { recoverPassword, rememberedPassword } = useAuthActions()

  const handleBack = () => {
    rememberedPassword()
  }

  return (
    <>
      <form
        onSubmit={e => handleSubmit(recoverPassword, e)}
        data-test="forgotPasswordForm"
      >
        {formState.error && (
          <Grid item className={classes.errorMessage}>
            <Typography
              variant="body1"
              color="error"
              data-test="formErrorMessage"
            >
              {formState.error}
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.verticalGutters}>
          <TextField
            autoFocus
            fullWidth
            data-test="emailField"
            margin="normal"
            label="EMAIL"
            error={!!getFieldError('email')}
            onChange={handleInputChange('email')}
            variant="outlined"
          />
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <SpinnerButton
            data-test="forgotPasswordSubmitButton"
            type="submit"
            valid={isValid()}
            loading={formState.loading}
          >
            Submit
          </SpinnerButton>
        </Grid>
        <Grid item align="center" className={classes.verticalGutters}>
          <Button onClick={handleBack}>Back</Button>
        </Grid>
      </form>
    </>
  )
}
