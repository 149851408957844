import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Hidden,
} from '@material-ui/core'

import KmlTree from '../elements/KmlTree'
import Icons from '../../assets/icons/symbol-defs.svg'

import { useUIActions } from '../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  addOverlayButton: {
    marginLeft: 'auto',
  },
  closeOverlayDrawerButton: {
    marginLeft: 'auto',
  },
}))

export default ({ project, onChange = () => {} }) => {
  const classes = useStyles()
  const { closeTempDrawerMapOverlays, openUploadOverlayDialog } = useUIActions()

  const renderOverlays = () => {
    if (!project || !project.overlays || project.overlays.length === 0) {
      return <span>No overlays</span>
    }

    return project.overlays.map((overlay, index) => (
      <KmlTree
        key={`kml-${index}`}
        s3Object={overlay.file}
        onSelect={onChange}
      />
    ))
  }

  return !project ? (
    <div>Project not found</div>
  ) : (
    <div>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <IconButton onClick={openUploadOverlayDialog}>
            <SvgIcon color="primary">
              <svg>
                <use xlinkHref={Icons + '#icn_add'} />
              </svg>
            </SvgIcon>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">MAP OVERLAYS</Typography>
        </Grid>
        <Hidden mdUp>
          <Grid item className={classes.closeOverlayDrawerButton}>
            <IconButton onClick={closeTempDrawerMapOverlays}>
              <SvgIcon color="secondary">
                <svg>
                  <use xlinkHref={Icons + '#icn_x'} />
                </svg>
              </SvgIcon>
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container>
        <Grid className={classes.overlayListItem} item xs={12}>
          {renderOverlays()}
        </Grid>
      </Grid>
    </div>
  )
}
