import { useStateContext } from '../stateContext'
import {
  SET_DRAWER_NAV_OPEN,
  SET_ASSET_INFO_OPEN,
  SET_NEW_PROJECT_DIALOG_OPEN,
  SET_UPDATE_PROJECT_DIALOG_OPEN,
  SET_INACTIVATE_PROJECT_DIALOG_OPEN,
  SET_UPLOAD_OVERLAY_DIALOG_OPEN,
  SET_SEARCH_BAR_VISIBLE,
  SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN,
  SET_FULL_SCREEN_PROJECT_MAP_OPEN,
  SET_DRAWER_ANOMALY_OPEN,
  SET_RETIRE_ASSET_DIALOG_OPEN,
  SET_EDIT_ASSET_DIALOG_OPEN,
  SET_DRAWER_SEARCH_OPEN,
} from './UIReducer'

export const useUIActions = () => {
  const { state, dispatch } = useStateContext()

  const openDrawerNav = () => {
    dispatch({ type: SET_DRAWER_NAV_OPEN, payload: { drawerNavOpen: true } })
  }
  const closeDrawerNav = () => {
    dispatch({ type: SET_DRAWER_NAV_OPEN, payload: { drawerNavOpen: false } })
  }
  const toggleDrawerNav = () => {
    dispatch({
      type: SET_DRAWER_NAV_OPEN,
      payload: { drawerNavOpen: !state.drawerNavOpen },
    })
  }

  const openAssetInfo = () => {
    dispatch({
      type: SET_ASSET_INFO_OPEN,
      payload: { assetInfoOpen: true },
    })
  }
  const closeAssetInfo = () => {
    dispatch({
      type: SET_ASSET_INFO_OPEN,
      payload: { assetInfoOpen: false },
    })
  }
  const toggleAssetInfo = () => {
    dispatch({
      type: SET_ASSET_INFO_OPEN,
      payload: { assetInfoOpen: !state.assetInfoOpen },
    })
  }

  const openNewProjectDialog = async () => {
    dispatch({
      type: SET_NEW_PROJECT_DIALOG_OPEN,
      payload: { newProjectDialogOpen: true },
    })
  }
  const closeNewProjectDialog = async () => {
    dispatch({
      type: SET_NEW_PROJECT_DIALOG_OPEN,
      payload: { newProjectDialogOpen: false },
    })
  }

  const openUpdateProjectDialog = async () => {
    dispatch({
      type: SET_UPDATE_PROJECT_DIALOG_OPEN,
      payload: { updateProjectDialogOpen: true },
    })
  }
  const closeUpdateProjectDialog = async () => {
    dispatch({
      type: SET_UPDATE_PROJECT_DIALOG_OPEN,
      payload: { updateProjectDialogOpen: false },
    })
  }

  const openInactivateProjectDialog = async () => {
    dispatch({
      type: SET_INACTIVATE_PROJECT_DIALOG_OPEN,
      payload: { inactivateProjectDialogOpen: true },
    })
  }
  const closeInactivateProjectDialog = async () => {
    dispatch({
      type: SET_INACTIVATE_PROJECT_DIALOG_OPEN,
      payload: { inactivateProjectDialogOpen: false },
    })
  }

  const openUploadOverlayDialog = async () => {
    dispatch({
      type: SET_UPLOAD_OVERLAY_DIALOG_OPEN,
      payload: { uploadOverlayDialogOpen: true },
    })
  }
  const closeUploadOverlayDialog = async () => {
    dispatch({
      type: SET_UPLOAD_OVERLAY_DIALOG_OPEN,
      payload: { uploadOverlayDialogOpen: false },
    })
  }

  const showSearchBar = async () => {
    dispatch({
      type: SET_SEARCH_BAR_VISIBLE,
      payload: { searchBarVisible: true },
    })
  }
  const hideSearchBar = async () => {
    dispatch({
      type: SET_SEARCH_BAR_VISIBLE,
      payload: { searchBarVisible: false },
    })
  }

  const openTempDrawerMapOverlays = async () => {
    dispatch({
      type: SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN,
      payload: { tempDrawerMapOverlaysOpen: true },
    })
  }
  const closeTempDrawerMapOverlays = async () => {
    dispatch({
      type: SET_TEMP_DRAWER_MAP_OVERLAYS_OPEN,
      payload: { tempDrawerMapOverlaysOpen: false },
    })
  }

  const toggleFullScreenProjectMap = async () => {
    dispatch({
      type: SET_FULL_SCREEN_PROJECT_MAP_OPEN,
      payload: { projectMapFullScreen: !state.ui.projectMapFullScreen },
    })
  }

  const openDrawerAnomaly = () => {
    dispatch({
      type: SET_DRAWER_ANOMALY_OPEN,
      payload: { drawerAnomalyOpen: true },
    })
  }
  const closeDrawerAnomaly = () => {
    dispatch({
      type: SET_DRAWER_ANOMALY_OPEN,
      payload: { drawerAnomalyOpen: false },
    })
  }
  const toggleDrawerAnomaly = () => {
    dispatch({
      type: SET_DRAWER_ANOMALY_OPEN,
      payload: { drawerAnomalyOpen: !state.drawerAnomalyOpen },
    })
  }

  const openRetireAssetDialog = async () => {
    dispatch({
      type: SET_RETIRE_ASSET_DIALOG_OPEN,
      payload: { retireAssetDialogOpen: true },
    })
  }
  const closeRetireAssetDialog = async () => {
    dispatch({
      type: SET_RETIRE_ASSET_DIALOG_OPEN,
      payload: { retireAssetDialogOpen: false },
    })
  }

  const openEditAssetDialog = async () => {
    dispatch({
      type: SET_EDIT_ASSET_DIALOG_OPEN,
      payload: { editAssetDialogOpen: true },
    })
  }
  const closeEditAssetDialog = async () => {
    dispatch({
      type: SET_EDIT_ASSET_DIALOG_OPEN,
      payload: { editAssetDialogOpen: false },
    })
  }
  const openDrawerSearch = () => {
    dispatch({
      type: SET_DRAWER_SEARCH_OPEN,
      payload: { drawerSearchOpen: true },
    })
  }
  const closeDrawerSearch = () => {
    dispatch({
      type: SET_DRAWER_SEARCH_OPEN,
      payload: { drawerSearchOpen: false },
    })
  }
  const toggleDrawerSearch = () => {
    dispatch({
      type: SET_DRAWER_SEARCH_OPEN,
      payload: { drawerSearchOpen: !state.drawerSearchOpen },
    })
  }

  return {
    openDrawerNav,
    closeDrawerNav,
    toggleDrawerNav,
    openAssetInfo,
    closeAssetInfo,
    toggleAssetInfo,
    openNewProjectDialog,
    closeNewProjectDialog,
    openUpdateProjectDialog,
    closeUpdateProjectDialog,
    openInactivateProjectDialog,
    closeInactivateProjectDialog,
    showSearchBar,
    hideSearchBar,
    openTempDrawerMapOverlays,
    closeTempDrawerMapOverlays,
    toggleFullScreenProjectMap,
    openUploadOverlayDialog,
    closeUploadOverlayDialog,
    openDrawerAnomaly,
    closeDrawerAnomaly,
    toggleDrawerAnomaly,
    openRetireAssetDialog,
    closeRetireAssetDialog,
    openEditAssetDialog,
    closeEditAssetDialog,
    openDrawerSearch,
    closeDrawerSearch,
    toggleDrawerSearch,
  }
}
