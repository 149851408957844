import { CompositeLayer, IconLayer, TextLayer } from 'deck.gl'

import getIcon from '../libs/getIcon'
import ClusterBubble from '../assets/img/svg/cluster_bubble.svg'
import { safeGet } from '../util'

export default class AssetLayer extends CompositeLayer {
  renderLayers() {
    return [
      new IconLayer({
        data: this.props.data,
        getIcon: d => {
          var icon = ClusterBubble
          return {
            url: icon,
            width: 40,
            height: 49,
            anchorY: 49,
          }
        },
        getPosition: d => d.geometry.coordinates,
        getSize: 60,
        id: `icon-layer-${this.id}`,
        // onClick: this.props.onClick,
        //onClick: console.log,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
      new IconLayer({
        data: this.props.data,
        getIcon: d => {
          var height = 40
          var width = height
          if (d.properties.point_count) {
            height = 0
            width = 0
          }

          var icon =
            getIcon(d.properties.icon + '.svg') || getIcon('question_mark.svg')

          return {
            url: icon,
            width: height,
            height: width,
            anchorY: 44,
          }
        },
        getPosition: d => d.geometry.coordinates,
        getSize: 40,
        id: `icon-layer-${this.id}`,
        //         onClick(info) {
        //   console.log(info)
        //   if (typeof info.object.onClick === 'function') {
        //     info.object.onClick(info)
        //   }
        // },
        //onClick: console.log,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
      new TextLayer({
        data: this.props.data,
        fontWeight: 700,
        getAlignmentBaseline: 'center',
        getColor: d => [255, 255, 255, 255],
        getPixelOffset: [0, -24],
        getPosition: d => d.geometry.coordinates,
        getSize: 14,
        getText: d =>
          String(safeGet('properties.point_count_abbreviated', d) || ''),
        getTextAnchor: 'middle',
        id: `text-layer-${this.id}`,
        // onClick: console.log,
        pickable: true,
      }),
    ]
  }
}

AssetLayer.layerName = 'AssetLayer'
