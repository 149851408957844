import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import AsseTracAppBar from '../elements/AsseTracAppBar'
import SystemIcon from '../elements/SystemIcon'
import InviteUsersDialog from '../dialogs/InviteUsersDialog'
import { useCustomerActions } from '../../store/Customer/CustomerActions'
import { useStateContext } from '../../store/stateContext'
import { useUserActions } from '../../store/User/UserActions'
import { SET_USER_LIST } from '../../store/User/UserReducer'

import { formatDate, safeGet } from '../../util'
import { useCancellablePromise, canceledPromiseErrorName } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  cellLabel: {
    color: theme.colors.lightSteel,
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  rowWrap: {
    flexWrap: 'nowrap',
  },
  email: {
    paddingRight: theme.spacing(2),
    flexBasis: theme.spacing(40),
  },
  selectBox: {
    textAlign: 'left',
    minWidth: 120,
  },
  spinnerContainer: {
    minHeight: '200px',
  },
}))

const UsersPage = () => {
  const classes = useStyles()
  const {
    getUsersInGroup,
    usersState,
    addUserToGroup,
    removeUserFromGroup,
    enableUser,
    disableUser,
  } = useUserActions()
  const { customerState, getActiveCustomer } = useCustomerActions()
  const { dispatch } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()

  const [inviteUsersOpen, setInviteUsersOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const refreshUserList = async () => {
    try {
      setLoading(true)

      const activeCustomer = (await cancellablePromise(getActiveCustomer())) || {}
      const customerGroup = activeCustomer.userGroups
        .filter(group => group.indexOf('customer-') === 0)
        .pop()

      const customerAdminGroup = activeCustomer.adminGroups
        .filter(group => group.indexOf('customerAdmin-') === 0)
        .pop()

      let adminList = await cancellablePromise(getUsersInGroup(customerAdminGroup))

      let userList = await cancellablePromise(getUsersInGroup(customerGroup))

      // Distinguish admins form users
      userList = userList.map(user => {
        const loc = adminList.findIndex(a => {
          return a.Username === user.Username
        })
        if (loc !== -1) {
          adminList[loc].Admin = true
          return adminList[loc]
        }
        return user
      })

      userList = userList.reduce((acc, current) => {
        acc.push({
          ...current,
          Email: current.Attributes.find(at => {
            if (at.Name !== 'email') {
              return false
            }
            return true
          }).Value,
        })
        return acc
      }, [])

      dispatch({ type: SET_USER_LIST, payload: { userList } })
      setLoading(false)
      return userList
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.error(err)
    }

    setLoading(false)
  }

  const handleAdminChange = async (user, value) => {
    try {
      const activeCustomer = (await cancellablePromise(getActiveCustomer())) || {}
      const customerAdminGroup = activeCustomer.adminGroups
        .filter(group => group.indexOf('customerAdmin-') === 0)
        .pop()
      if (value === 'true') {
        await cancellablePromise(addUserToGroup(user.Email, customerAdminGroup))
      } else {
        await cancellablePromise(removeUserFromGroup(user.Email, customerAdminGroup))
      }
      refreshUserList()
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  const handleStatusChange = async (user, status) => {
    try {
      if (status === 'Active') {
        await cancellablePromise(enableUser(user.Email))
      } else {
        await cancellablePromise(disableUser(user.Email))
      }
      // fetch new list
      refreshUserList()
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    refreshUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState.activeCustomer])

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.pageWrap}
        width="50%"
      >
        <AsseTracAppBar title="USERS" />
        <Container maxWidth="md" className={classes.container}>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="secondary" variant="subtitle1">
                {usersState.userList.length}
                {usersState.userList.length === 1 ? (
                  <span> user</span>
                ) : (
                  <span> total users</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                color="primary"
                onClick={() => setInviteUsersOpen(true)}
                variant="contained"
              >
                <SystemIcon name="icn_add" /> INVITE USERS
              </Button>
            </Grid>
          </Grid>

          {loading && (
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.spinnerContainer}
            >
              <Grid item>
                <CircularProgress variant="indeterminate" />
              </Grid>
            </Grid>
          )}
          {!loading && (
            <Grid container className={classes.listContainer} spacing={2}>
              {usersState.userList.map((user, index) => (
                <Grid
                  container
                  item
                  className={classes.rowWrap}
                  key={`user-row-${index}`}
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs className={classes.email}>
                    <Typography color="secondary">{user.Email}</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography color="secondary">
                      <span className={classes.cellLabel}>Since: </span>
                      {formatDate(user.UserCreateDate, 'M/D/Y')}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography color="secondary">
                      <span className={classes.cellLabel}>Last Login: </span>
                      {formatDate(user.UserLastModifiedDate, 'M/D/Y')}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={classes.alignRight}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectBox}
                    >
                      <Select
                        onChange={e => handleAdminChange(user, e.target.value)}
                        value={safeGet('Admin', user, false)}
                      >
                        <MenuItem value="true">Admin</MenuItem>
                        <MenuItem value="false">User</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} className={classes.alignRight}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectBox}
                    >
                      <Select
                        onChange={e => handleStatusChange(user, e.target.value)}
                        value={user.Enabled ? 'Active' : 'Inactive'}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Grid>
      <InviteUsersDialog
        closeForm={() => setInviteUsersOpen(false)}
        open={inviteUsersOpen}
        onSave={refreshUserList}
      />
    </>
  )
}

export default UsersPage
