import React, { createRef, useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textField: {
    pointerEvents: 'none',
  },
  fileInput: {
    display: 'none',
  },
}))

const FilePicker = ({
  error,
  extensions = [],
  file = {},
  helperText,
  label,
  onChange = () => {},
}) => {
  const [selectedFile, setSelectedFile] = useState(file)
  const classes = useStyles()

  const fileInput = createRef()

  const browse = () => {
    fileInput.current.click()
  }

  const onSelect = e => {
    const file = e.target.files[0]
    // back out if user hits cancel button on finder window
    if (!file) {
      return
    }

    setSelectedFile(file)
    onChange(file)
  }

  const accept =
    extensions.length === 0 ? '*' : extensions.map(item => `.${item}`).join(',')

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={10}>
        <TextField
          className={classes.textField}
          error={error}
          fullWidth
          helperText={helperText}
          label={label || 'Select File'}
          margin="normal"
          readOnly
          value={selectedFile.name || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={2}>
        <Button variant="outlined" onClick={browse}>
          Browse
        </Button>
        <input
          accept={accept}
          className={classes.fileInput}
          onChange={onSelect}
          ref={fileInput}
          type="file"
          data-test="filePickerInput"
        />
      </Grid>
    </Grid>
  )
}

export default FilePicker
