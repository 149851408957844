export const INIT_SEARCH = 'INIT_SEARCH'
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_QUICK_SEARCH_RESULTS = 'SET_QUICK_SEARCH_RESULTS'

export const initialState = {
  searchCriteria: '',
  quickSearchResults: [],
  searchResults: [],
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_SEARCH:
      return {
        ...initialState,
      }
    case SET_SEARCH_CRITERIA:
      const { searchCriteria } = payload

      return {
        ...state,
        searchCriteria,
      }
    case SET_QUICK_SEARCH_RESULTS:
      const { quickSearchResults } = payload

      return {
        ...state,
        quickSearchResults,
      }
    case SET_SEARCH_RESULTS:
      const { searchResults } = payload

      return {
        ...state,
        searchResults,
      }
    default:
      return state
  }
}
