import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'

import ProtectedRoute from './elements/ProtectedRoute'
import NavDrawer from './elements/NavDrawer'

import DashboardPage from './pages/DashboardPage'
import NewProjectDialog from './dialogs/NewProjectDialog'
import ProjectListPage from './pages/ProjectListPage'
import ProjectPage from './pages/ProjectPage'
import AssetPage from './pages/AssetPage'
import OwnersPage from './pages/OwnersPage'
import CustomersPage from './pages/CustomersPage'
import GlobalAssetTypesPage from './pages/GlobalAssetTypesPage'
import UsersPage from './pages/UsersPage'
import MyAccountPage from './pages/MyAccountPage'
import { useCustomerActions } from '../store/Customer/CustomerActions'
import { useConstantsActions } from '../store/Constants/ConstantsActions'
import SearchResultsPage from './pages/SearchResultsPage'

export default () => {
  const { getCustomers } = useCustomerActions()
  const { getConstants } = useConstantsActions()

  useEffect(() => {
    getCustomers()
    getConstants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <NavDrawer />
      <Fade>
        <Switch>
          <Route path="/" exact component={DashboardPage} />
          <Route path="/Projects" exact component={ProjectListPage} />
          <Route path="/Projects/:id" component={ProjectPage} />
          <Route path="/Asset/:id" component={AssetPage} />
          <Route path="/Account" component={MyAccountPage} />
          <Route path="/SearchResults" component={SearchResultsPage} />
          <ProtectedRoute
            path="/Owners"
            component={OwnersPage}
            groups={'admins'}
          />
          <ProtectedRoute
            path="/Customers"
            component={CustomersPage}
            groups="admins"
          />
          <ProtectedRoute
            path="/GlobalAssetTypes"
            component={GlobalAssetTypesPage}
            groups="admins"
          />
          <ProtectedRoute
            path="/Users"
            component={UsersPage}
            groups={/admins|customerAdmin[\S]+/}
          />
        </Switch>
      </Fade>
      <NewProjectDialog />
    </Router>
  )
}
