import React, { useEffect, useRef } from 'react'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '14px',
  },
}))

const TimePopup = ({
  children,
  open,
  value,
  valueLabelDisplay,
  valueLabelFormat = x => x,
}) => {
  const popperRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update()
    }
  })

  return (
    <Tooltip
      classes={classes}
      open={open || valueLabelDisplay === 'on'}
      enterTouchDelay={0}
      placement="top"
      title={`${valueLabelFormat(value)} (± 1 Day)`}
      PopperProps={{
        popperRef,
      }}
    >
      {children}
    </Tooltip>
  )
}

export default TimePopup
