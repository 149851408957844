import React, { useState, useEffect } from 'react'
import { TreeItem, TreeView } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Storage } from 'aws-amplify'
import { Typography } from '@material-ui/core'
import KmlLayer from '../../models/KmlLayer'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'

const KmlSelectionTree = ({ kmlFile, s3Object, onSelect = () => {} }) => {
  const { cancellablePromise } = useCancellablePromise()
  const [kmlStructure, setKmlStructure] = useState()
  const [error, setError] = useState()

  /**
   * Outputs file as string
   */
  const toString = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader()
        reader.onload = e => {
          resolve(e.target.result)
        }

        reader.onerror = e => {
          reader.abort()
          reject(reader.error)
        }

        reader.readAsText(file)
      } catch (err) {
        reject(err)
      }
    })
  }

  const getStructuredGeoJsonFile = async s3Object => {
    try {
      const key = s3Object.key
      const fileName = key.split('/').pop()
      const response = await cancellablePromise(Storage.get(key, { download: true }))
      const file = new File([response.Body.buffer], fileName, {
        type: 'application/json',
      })
      const kmlAsGeoJson = JSON.parse(await cancellablePromise(toString(file)))
      return kmlAsGeoJson
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    if (kmlFile) {
      cancellablePromise(kmlFile.getAsGeoJson())
        .then(structure => setKmlStructure(structure.features.filter(feature => feature.geometry.type === 'Polygon')))
        .catch((error) => {
          if (error.name === canceledPromiseErrorName) {
            return
          }
          setError(error)
        })
    } else if (s3Object) {
      cancellablePromise(getStructuredGeoJsonFile(s3Object))
        .then(kmlAsGeoJson => {
          setKmlStructure(new KmlLayer({
            kmlLayerAsGeoJson: kmlAsGeoJson,
          }))
        })
        .catch((error) => {
          if (error.name === canceledPromiseErrorName) {
            return
          }
          setError(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmlFile, s3Object])

  /**
   * Recursively generates TreeItems
   * @param { [{ name, [children] }] } children
   * @return JSX
   */
  let itemCount = 0
  const renderItems = layer => {
    console.log(layer)
    if (!layer) {
      return null
    }
    const children = layer instanceof Array ? layer : [layer]
    console.log(children)
    const items = []

    for (let child of children) {
      let treeId = `treeitem-${itemCount++}`
      items.push(
        <TreeItem
          onClick={() => onSelect(child)}
          key={treeId}
          nodeId={treeId}
          label={child.properties.name}
        >
          {renderItems(child.children)}
        </TreeItem>,
      )
    }

    return items.length > 0 ? items : null
  }

  return (
    <>
      {error && <Typography color="error">{error.message}</Typography>}
      {!error && (
        <>
          {!kmlStructure ? (
            <p>Reading file...</p>
          ) : (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              data-test="createProjectDialogOverlayTree"
            >
              {renderItems(kmlStructure)}
            </TreeView>
          )}
        </>
      )}
    </>
  )
}

export default KmlSelectionTree
