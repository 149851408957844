import React, { useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import { useUIActions } from '../../store/UI/UIActions'
import { safeGet } from '../../util'

import {
  Grid,
  IconButton,
  Typography,
  SvgIcon,
  Button,
  Card,
  CardContent,
  CardActions,
  Collapse,
  Box,
} from '@material-ui/core'

import Icons from '../../assets/icons/symbol-defs.svg'

const useStyles = makeStyles(theme => ({
  pageInfoWrap: {
    width: 'unset',
    height: 49,
    marginLeft: -9,
    marginTop: -1,
    zIndex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      flexShrink: 1000,
      marginTop: -1,
      marginRight: theme.spacing(1),
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: -9,
      marginRight: -9,
    },
    '&.open': {
      height: 'unset',
      overflow: 'visible',
      whiteSpace: 'normal',
    },
  },
  pageInfoCard: {
    width: '100%',
    borderColor: theme.colors.backgroundColor,
    borderLeftColor: theme.colors.lightSteel,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      borderLeftColor: theme.colors.backgroundColor,
      borderTopColor: theme.colors.lightSteel,
    },
    '&.open': {
      borderColor: theme.colors.lightSteel,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(0),
      borderTopRightRadius: theme.spacing(0),
    },
  },
  cardContent: {
    paddingTop: 3,
    paddingBottom: theme.spacing(0),
  },
  cardHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  pageTitle: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 5,
    '&.open': {
      whiteSpace: 'normal',
      overflowX: 'visible',
    },
  },
  openProjectInfoButton: {
    transform: 'rotate(0deg)',
    alignSelf: 'start',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&.open': {
      transform: 'rotate(180deg)',
    },
  },
  clientName: {
    color: theme.colors.portlandCement,
  },
  projectDetails: {
    '& p': {
      marginBottom: theme.spacing(1),
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  projectDescription: {
    color: theme.colors.portlandCement,
  },
  label: {
    color: theme.colors.portlandCement,
  },
  editProjectButton: {
    marginLeft: 'auto',
  },
}))

const ProjectInfo = ({ project }) => {
  const classes = useStyles()
  const [projectInfoOpen, setProjectInfoOpen] = useState(false)
  const {
    openUpdateProjectDialog,
    openInactivateProjectDialog,
  } = useUIActions()
  const toggleProjectInfo = () => {
    setProjectInfoOpen(!projectInfoOpen)
  }

  return (
    <Grid
      container
      item
      md={4}
      direction="row"
      justify="space-between"
      className={clsx(classes.pageInfoWrap, {
        open: projectInfoOpen,
      })}
    >
      <Card
        elevation={projectInfoOpen ? 12 : 0}
        className={clsx(classes.pageInfoCard, {
          open: projectInfoOpen,
        })}
      >
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.cardHeader} direction="row">
            <IconButton
              edge="start"
              onClick={toggleProjectInfo}
              className={clsx(classes.openProjectInfoButton, {
                open: projectInfoOpen,
              })}
            >
              <SvgIcon fontSize="small">
                <svg>
                  <use xlinkHref={Icons + '#icn_caret_down'} />
                </svg>
              </SvgIcon>
            </IconButton>
            <Typography
              className={clsx(classes.pageTitle, {
                open: projectInfoOpen,
              })}
              variant="h6"
              color="inherit"
            >
              {safeGet('name', project)}
            </Typography>
          </Grid>
          <Collapse in={projectInfoOpen}>
            <Box className={classes.projectDetails}>
              <Typography
                className={classes.clientName}
                component="p"
                variant="subtitle2"
              >
                {safeGet('client', project)}
              </Typography>
              <Typography
                className={classes.projectDescription}
                component="p"
                variant="body2"
              >
                {safeGet('description', project)}
              </Typography>
              <Typography variant="body1">
                <span className={classes.label}>START DATE&nbsp;&nbsp;</span>
                {project && `${moment(project.startDate).format('MM/DD/YYYY')}`}
              </Typography>
              <Typography variant="body1">
                <span className={classes.label}>END DATE&nbsp;&nbsp;</span>
                {project && `${moment(project.endDate).format('MM/DD/YYYY')}`}
              </Typography>
            </Box>
          </Collapse>
        </CardContent>
        <Collapse in={projectInfoOpen}>
          <CardActions>
            <Button
              onClick={openInactivateProjectDialog}
              className={classes.inactivateProjectButton}
              color="secondary"
              variant="outlined"
              disabled={
                project.status === 'activating' ||
                project.status === 'deactivating'
              }
            >
              {project.status === 'active' && 'Inactivate Project'}
              {project.status === 'inactive' && 'Re-Activate Project'}
              {project.status === 'activating' && 'Project Activating'}
              {project.status === 'deactivating' && 'Project Deactivating'}
            </Button>
            <Button
              onClick={openUpdateProjectDialog}
              className={classes.editProjectButton}
              color="primary"
              variant="contained"
              disabled={
                project.status === 'inactive' ||
                project.status === 'activating' ||
                project.status === 'deactivating'
              }
            >
              Edit
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    </Grid>
  )
}

export default ProjectInfo
