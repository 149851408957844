import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import ClientPicker from '../../elements/ClientPicker'

const useStyles = makeStyles(theme => ({
  datepicker: {
    width: '100%',
  },
}))
export default ({
  getValue,
  handleInputChange,
  getFieldError,
  getFirstFieldError,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            autoFocus
            error={!!getFieldError('name')}
            helperText={getFirstFieldError('name')}
            id="project-name"
            label="Project Name"
            fullWidth
            margin="normal"
            onChange={handleInputChange('name')}
            value={getValue('name')}
            variant="outlined"
            data-test="createProjectDialogName"
          />
        </Grid>
        <Grid item xs={12}>
          <ClientPicker
            error={!!getFieldError('client')}
            helperText={getFieldError('client')}
            value={getValue('client')}
            onChange={handleInputChange('client')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-test="createProjectDialogDesc"
            autoComplete="off"
            error={!!getFieldError('description')}
            fullWidth
            helperText={getFieldError('description')}
            id="project-description"
            label="Project Description"
            margin="normal"
            multiline
            onChange={handleInputChange('description')}
            rowsMax="4"
            name="description"
            value={getValue('description')}
            variant="outlined"
          />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                autoOk
                className={classes.datepicker}
                disableToolbar
                format="MM/dd/yyyy"
                id="start-date"
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                label="Start Date"
                margin="normal"
                name="startDate"
                onChange={handleInputChange('startDate')}
                value={getValue('startDate', new Date())}
                variant="inline"
                data-test="createProjectDialogStartDate"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                autoOk
                name="endDate"
                className={classes.datepicker}
                disableToolbar
                format="MM/dd/yyyy"
                id="end-date"
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                label="End Date"
                margin="normal"
                onChange={handleInputChange('endDate')}
                value={getValue('endDate', new Date())}
                variant="inline"
                data-test="createProjectDialogEndDate"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </>
  )
}
