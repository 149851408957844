import { Auth } from 'aws-amplify'

import { useStateContext } from '../stateContext'
import { INIT_ASSETS } from '../Assets/AssetsReducer'
import {
  INIT_AUTH,
  SET_USER,
  SET_PASSWORD_CHANGE_REQUIRED,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_SUBMIT,
  SET_AUTHENTICATED,
} from './AuthReducer'
import { INIT_CONSTANTS } from '../Constants/ConstantsReducer'
import { INIT_CUSTOMERS } from '../Customer/CustomerReducer'
import { INIT_MAP } from '../Map/MapReducer'
import { INIT_PROJECTS } from '../Project/ProjectReducer'
import { INIT_REPORTS } from '../Reports/ReportsReducer'
import { INIT_SEARCH } from '../Search/SearchReducer'
import { INIT_UI } from '../UI/UIReducer'
import { INIT_USERS } from '../User/UserReducer'
import { safeGet } from '../../util'

export const useAuthActions = () => {
  const { state, dispatch } = useStateContext()

  const getGroups = () => {
    return (
      safeGet(
        'auth.user.signInUserSession.accessToken.payload.cognito:groups',
        state,
      ) || []
    )
  }

  const memberOf = groupQueries => {
    const userGroups = getGroups()
    if (!(groupQueries instanceof Array)) {
      groupQueries = [groupQueries]
    }

    return !groupQueries.some(
      groupQuery =>
        !userGroups.find(userGroup => userGroup.match(groupQuery)) &&
        userGroups.indexOf(groupQuery) < 0,
    )
  }

  const resetPassword = async ({ password }) => {
    try {
      const user = state.auth.user
      await Auth.completeNewPassword(user, password)
      dispatch({
        type: SET_PASSWORD_CHANGE_REQUIRED,
        payload: { passwordChangeRequired: false },
      })
      dispatch({
        type: SET_AUTHENTICATED,
        payload: { authenticated: true },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const changePassword = async ({ oldPassword, password }) => {
    const user = state.auth.user
    await Auth.changePassword(user, oldPassword, password)
  }

  const restoreSession = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      dispatch({ type: SET_USER, payload: { user } })
      dispatch({ type: SET_AUTHENTICATED, payload: { authenticated: true } })
    } catch (err) {
      // fail silently: not signed in
    }
  }

  const signIn = async ({ email, password }) => {
    const user = await Auth.signIn(email, password)
    dispatch({ type: SET_USER, payload: { user } })
    if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      // signed in successfully
      dispatch({
        type: SET_AUTHENTICATED,
        payload: { authenticated: true },
      })
    } else {
      // requires password reset
      dispatch({
        type: SET_PASSWORD_CHANGE_REQUIRED,
        payload: { passwordChangeRequired: true },
      })
    }
  }

  const signOut = async () => {
    await Auth.signOut()

    dispatch({ type: INIT_ASSETS })
    dispatch({ type: INIT_AUTH })
    dispatch({ type: INIT_CONSTANTS })
    dispatch({ type: INIT_CUSTOMERS })
    dispatch({ type: INIT_MAP })
    dispatch({ type: INIT_PROJECTS })
    dispatch({ type: INIT_REPORTS })
    dispatch({ type: INIT_SEARCH })
    dispatch({ type: INIT_UI })
    dispatch({ type: INIT_USERS })
  }

  const forgotPassword = async email => {
    try {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: true },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const recoverPassword = async email => {
    await Auth.forgotPassword(email.email).then(data => {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: false },
      })
      dispatch({
        type: SET_FORGOT_PASSWORD_SUBMIT,
        payload: { forgotPasswordSubmit: true },
      })
      console.log(data)
    })
  }

  const forgotPasswordSubmit = async forgotPasswordForm => {
    await Auth.forgotPasswordSubmit(
      forgotPasswordForm.email,
      forgotPasswordForm.verificationCode,
      forgotPasswordForm.password,
    ).then(data => {
      dispatch({
        type: SET_FORGOT_PASSWORD_SUBMIT,
        payload: { forgotPasswordSubmit: false },
      })
      console.log(data)
    })
  }

  const rememberedPassword = async username => {
    try {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: false },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    getGroups,
    memberOf,
    resetPassword,
    changePassword,
    restoreSession,
    signIn,
    signOut,
    forgotPassword,
    recoverPassword,
    forgotPasswordSubmit,
    rememberedPassword,
    userState: state.auth.user,
  }
}
