import {
  CompositeLayer,
  PointCloudLayer,
  // LineLayer,
  COORDINATE_SYSTEM,
} from 'deck.gl'
import { safeGet } from '../util'

export default class MovesLayer extends CompositeLayer {
  renderLayers() {
    // const linePoints = this.state.points.filter(point => !point.asset.type.name.toLowerCase().includes('truck'))

    return [
      // new LineLayer({
      //   id: `${this.id}-line-1`,
      //   data: linePoints,
      //   getSourcePosition: d => d.prevLocation,
      //   getTargetPosition: d => d.location,
      //   getColor: [251, 253, 251, 255],
      //   getWidth: 5,
      //   opacity: 1,
      // }),

      // new LineLayer({
      //   id: `${this.id}-line-2`,
      //   data: linePoints,
      //   getSourcePosition: d => d.prevLocation,
      //   getTargetPosition: d => d.location,
      //   getColor: [236, 79, 54, 255],
      //   getWidth: 2,
      //   opacity: 1,
      // }),

      new PointCloudLayer({
        id: `${this.id}-point-1`,
        data: this.state.points,
        pickable: true,
        coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        pointSize: 10,
        getColor: [251, 253, 251, 255],
        getPosition: d => d.location,
        opacity: 1,
        material: false,
      }),

      new PointCloudLayer({
        id: `${this.id}-point-2`,
        data: this.state.points,
        pickable: true,
        coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        pointSize: 8,
        getColor: [236, 79, 54],
        getPosition: d => d.location,
        opacity: 1,
        material: false,
      }),
    ]
  }

  updateState({ props, changeFlags }) {
    if (!changeFlags.dataChanged) {
      return
    }

    const points = []

    if (props.data && props.data instanceof Array) {
      for (let layer of props.data) {
        if (!layer.currentLocation) {
          continue
        }

        let prevLocation = [
          layer.currentLocation.longitude,
          layer.currentLocation.latitude,
        ]

        for (let move of layer.moves) {
          let location = [move.coordinate.longitude, move.coordinate.latitude]
          let point = {
            prevLocation,
            location,
            move,
            asset: layer.asset,
          }

          points.push(point)

          // store location for next loop
          prevLocation = location
        }
      }
    }

    this.setState({ points })
  }

  getPickingInfo({ info, mode }) {
    if (mode === 'hover') {
      const onTooltip = safeGet('layer.props.onTooltip', info) || (() => {})

      onTooltip(info.object ? { ...info.object, x: info.x, y: info.y } : null)
    }
  }
}

MovesLayer.layerName = 'MovesLayer'

MovesLayer.defaultProps = {
  pickable: true,
}
