import React from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SystemIcon from '../SystemIcon'
import { useAuthActions } from '../../../store/Auth/AuthActions'
import { useUIActions } from '../../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    marginLeft: theme.spacing(3),
  },
  nestedList: {
    position: 'relative',
    marginLeft: theme.spacing(5),
    '&::before': {
      content: '""',
      display: 'block',
      width: '6px',
      height: '91%',
      position: 'absolute',
      left: 0,
      borderRadius: '3px',
      backgroundColor: theme.colors.lightSteel,
    },
  },
}))

const AccountNav = () => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { closeDrawerNav } = useUIActions()
  const open = true

  return (
    <>
      {memberOf('admins') && (
        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <SystemIcon name="icn_global_settings" />
            </ListItemIcon>
            <ListItemText>OWNER SETTINGS</ListItemText>
          </ListItem>
          <Collapse in={open}>
            <List className={classes.nestedList} onClick={closeDrawerNav}>
              <Link to="/Owners">
                <ListItem
                  className={classes.nested}
                  data-test="userNavButton"
                  button
                >
                  <ListItemText>OWNERS</ListItemText>
                </ListItem>
              </Link>

              <Link to="/customers">
                <ListItem
                  className={classes.nested}
                  data-test="customerNavButton"
                  button
                >
                  <ListItemText>CUSTOMERS</ListItemText>
                </ListItem>
              </Link>

              <Link to="/GlobalAssetTypes">
                <ListItem
                  className={classes.nested}
                  data-test="assetTypeNavButton"
                  button
                >
                  <ListItemText>GLOBAL ASSET TYPES</ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      )}

      <List className={classes.list}>
        <ListItem>
          <ListItemIcon>
            <SystemIcon name="icn_compass" />
          </ListItemIcon>
          <ListItemText>SETUP</ListItemText>
        </ListItem>
        <Collapse in={open}>
          <List className={classes.nestedList} onClick={closeDrawerNav}>
            {memberOf(/admins|customerAdmin[\S]+/) && (
              <Link to="/Users">
                <ListItem
                  className={classes.nested}
                  data-test="userNavButton"
                  button
                >
                  <ListItemText>USERS</ListItemText>
                </ListItem>
              </Link>
            )}
            <Link to="/Account">
              <ListItem
                className={classes.nested}
                data-test="accountNavButton"
                button
              >
                <ListItemText>MY ACCOUNT</ListItemText>
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>
    </>
  )
}

export default AccountNav
