export const INIT_USERS = 'INIT_USERS'
export const SET_USER_LIST = 'SET_USER_LIST'

export const initialState = {
  userList: [],
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_USERS:
      return {
        ...initialState,
      }
    case SET_USER_LIST:
      return {
        ...state,
        userList: payload.userList || [],
      }
    default:
      return state
  }
}
