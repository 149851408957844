import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import SpinnerButton from '../elements/SpinnerButton'
import { useForm } from '../../hooks/Forms'
import {
  reduceAssetTypesToNames,
} from '../../util'
import { useConstantsActions } from '../../store/Constants/ConstantsActions'
import AssetIconSelect from '../elements/AssetIconSelect'
import { useStateContext } from '../../store/stateContext'

const EditAssetTypeDialog = ({
  assetType = {},
  closeForm = () => {},
  onSave = () => {},
  open,
}) => {
  const { state } = useStateContext()
  const assetTypesState = state.constants.assetTypes
  const [assetTypeNames, setAssetTypeNames] = useState([])
  const { updateAssetType } = useConstantsActions()

  const {
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
    validateForm,
  } = useForm({
    name: `required|unique:${assetTypeNames.join(',')}`,
    iconName: 'required',
    idleThreshold: 'number:>0',
    offlineThreshold: 'number:>0',
  })

  useEffect(() => {
    // Set up the validation rules
    // for the name using the assetTypes
    const allTypes = reduceAssetTypesToNames(assetTypesState)
    if (assetType.name) {
      allTypes.splice(allTypes.indexOf(assetType.name), 1)
    }
    setAssetTypeNames(allTypes)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypesState, assetType.name])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypeNames])

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const onSubmit = async ({ name, iconName, idleThreshold, offlineThreshold }) => {
    const newAssetType = await updateAssetType({
      assetType,
      name,
      iconName,
      idleThreshold,
      offlineThreshold,
    })

    closeForm()
    clearFormState()
    onSave(newAssetType)
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open}>
      <form onSubmit={e => handleSubmit(onSubmit, e)}>
        <DialogTitle>Edit {assetType.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <AssetIconSelect
                onChange={handleInputChange('iconName')}
                value={getValue('iconName', assetType.iconName)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!getFieldError('name')}
                helperText={getFieldError('name')}
                fullWidth
                label="Type"
                onChange={handleInputChange('name')}
                value={getValue('name', assetType.name)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!getFieldError('idleThreshold')}
                helperText={getFieldError('idleThreshold')}
                fullWidth
                label="Idle Threshold (days)"
                onChange={handleInputChange('idleThreshold')}
                type="number"
                value={getValue(
                  'idleThreshold', assetType.idleThreshold,
                )}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={!!getFieldError('offlineThreshold')}
                helperText={getFieldError('offlineThreshold')}
                fullWidth
                label="Offline Threshold (hours)"
                onChange={handleInputChange('offlineThreshold')}
                type="number"
                value={getValue(
                  'offlineThreshold', assetType.offlineThreshold,
                )}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type="submit"
            valid={isValid()}
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

EditAssetTypeDialog.propTypes = {
  assetType: PropTypes.object,
  closeForm: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
}

export default EditAssetTypeDialog
