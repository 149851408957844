import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Brand from '../../assets/brand/symbol-defs.svg'
import Hero from '../../assets/img/cable-clouds-danger-dawn-171428.jpg'

import { Grid, Card, CardContent } from '@material-ui/core'

import LoginForm from '../forms/LoginForm'
import ResetPasswordForm from '../forms/ResetPasswordForm'
import ForgotPasswordForm from '../forms/ForgotPasswordForm'
import ForgotPasswordSubmitForm from '../forms/ForgotPasswordSubmitForm'

import { useStateContext } from '../../store/stateContext'
import { useAuthActions } from '../../store/Auth/AuthActions'

const useStyles = makeStyles(theme => ({
  hero: {
    position: 'absolute',
    width: '100vw',
    height: 420,
    backgroundImage: 'url(' + Hero + ')',
    backgroundSize: 'cover',
    backgroundPosition: '0% 94%',
    zIndex: -1,
  },
  card: {
    marginTop: 160,
  },
  brand: {
    textAlign: 'center',
    marginBottom: 8,
    marginTop: 8,
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
  logo: {
    maxHeight: 67,
    maxWidth: '77vw',
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))

export default () => {
  const classes = useStyles()
  const { state } = useStateContext()

  const { restoreSession } = useAuthActions()

  useEffect(() => {
    restoreSession()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={2} justify="center">
      <div className={classes.hero} />
      <Grid item xs={11} sm={8} md={6} lg={4} xl={3}>
        <Card className={classes.card} elevation={16}>
          <CardContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} className={classes.brand}>
                <svg className={classes.logo}>
                  <use xlinkHref={Brand + '#lockup_horizontal_with_tagline'} />
                </svg>
              </Grid>
              {state.auth.passwordChangeRequired && <ResetPasswordForm />}
              {state.auth.forgotPassword && <ForgotPasswordForm />}
              {state.auth.forgotPasswordSubmit && <ForgotPasswordSubmitForm />}
              {!state.auth.passwordChangeRequired &&
                !state.auth.forgotPassword &&
                !state.auth.forgotPasswordSubmit && <LoginForm />}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
