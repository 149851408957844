import { CompositeLayer } from 'deck.gl'
import SuperCluster from 'supercluster'

import AssetLayer from './AssetLayer'
import { MAX_ZOOM } from '../store/Map/MapActions'
import { safeGet } from '../util'

export default class ClustersLayer extends CompositeLayer {
  renderLayers() {
    const { data } = this.state

    const sameCoords = {};
    for (var i = 0; i < data.length; i++) {
      const d = data[i];
      const key = d.geometry.coordinates[0] + '_' + d.geometry.coordinates[1];
      if (sameCoords[key]) {
        sameCoords[key].push(d);
      } else {
        sameCoords[key] = [d];
      }
    }
    for (const [key, value] of Object.entries(sameCoords)) {
      if (value.length > 1) {
        for (var i = 0; i < value.length; i++) {
          value[i].geometry.coordinates[0] = value[i].geometry.coordinates[0] + (0.00001 * i)
          value[i].geometry.coordinates[1] = value[i].geometry.coordinates[1] + (0.00001 * i)
        }
      }
    }

    return [
      new AssetLayer(
        this.getSubLayerProps({
          id: `asset-layer-${this.id}`,
          data,
          pickable: true,
          getPosition: d => {
            return d.geometry.coordinates
          },
        }),
      ),
    ]
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale

    if (rebuildIndex) {
      const index = new SuperCluster({ maxZoom: MAX_ZOOM, radius: 60 })
      index.load(
        props.data
          .filter(d => d.coordinates) // prevent missing coordinates from breaking page
          .map(d => ({
            geometry: {
              coordinates: [d.coordinates.longitude, d.coordinates.latitude, 0],
            },
            properties: d,
          })),
      )
      this.setState({ index })
      //console.log(props)
    }

    const z = Math.floor(this.context.viewport.zoom)
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      })
    }
  }
  getPickingInfo({ info, mode, sourceLayer }) {
    // console.log(info)
    // console.log(mode)
    // console.log(sourceLayer)

    switch (mode) {
      case 'hover':
        const onTooltip = safeGet('layer.props.onTooltip', info) || (() => {})
        if (info.object && info.object.properties && !info.object.properties.cluster) {
          onTooltip(info.object ? { ...info.object, x: info.x, y: info.y } : null)
        } else {
          onTooltip(null)
        }
        break
      case 'query':
        if (typeof this.onClick === 'function') {
          this.onClick(info)
        }
        const onTooltipQuery = safeGet('layer.props.onTooltip', info) || (() => {})
        onTooltipQuery(null)
        break
      default:
        console.log(mode)
    }
  }
}

ClustersLayer.layerName = 'ClustersLayer'
