import { CompositeLayer, GeoJsonLayer, TextLayer } from 'deck.gl'
import hexRgb from 'hex-rgb'
import { safeGet } from '../util'

export default class OverlayLayer extends CompositeLayer {
  renderLayers() {
    return [
      new TextLayer({
        data: this.props.data,
        fontWeight: 700,
        getAlignmentBaseline: 'center',
        getColor: d => [255, 255, 255, 255],
        getPixelOffset: [0, -24],
        getPosition: d => d.geometry.coordinates,
        getSize: 16,
        getText: d =>
          String(safeGet('properties.name', d) || ''),
        getTextAnchor: 'middle',
        id: `text-layer-${this.id}`,
        // onClick: console.log,
        pickable: true,
      }),
      new GeoJsonLayer({
        data: this.props.data,
        stroked: false,
        //filled: true,
        // extruded: true,
        //lineWidthScale: 20,
        lineWidthMinPixels: 2,
        // getFillColor: d => {
        //   //if (!d.properties.fill) {
        //   return [0, 0, 0, 0]
        //   //}

        //   const colors = hexRgb(d.properties.fill)

        //   const rgba = [
        //     colors.red,
        //     colors.green,
        //     colors.blue,
        //     255 * (d.properties['fill-opacity'] || 0),
        //   ]

        //   return rgba
        // },
        getFillColor: [0, 0, 0, 0],
        getLineColor: d => {
          if (!d.properties.stroke) {
            return [0, 0, 0, 255]
          }

          const colors = hexRgb(d.properties.stroke)

          const rgba = [
            colors.red,
            colors.green,
            colors.blue,
            255,
            // 255 * (d.properties['stroke-opacity'] || 0),
          ]

          return rgba
        },
        getLineWidth: d => d.properties['stroke-width'] || 0,
        getRadius: 100,
        getElevation: 30,
      }),
    ]
  }
}

OverlayLayer.layerName = 'OverlayLayer'
