import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  Grid,
} from '@material-ui/core'
import { TreeItem, TreeView } from '@material-ui/lab'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import cloneDeep from 'lodash/cloneDeep'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTreeItem-iconContainer': {
      marginRight: theme.spacing(1),
    },
  },
  child: {
    '&.last .MuiTreeItem-iconContainer': {
      display: 'none',
    },
    '& .MuiIconButton-label': {
      pointerEvents: 'none',
    },
    '&.last .MuiButtonBase-root': {
      marginLeft: theme.spacing(4),
    },
  },
  actionContainer: {
    marginBottom: theme.spacing(1),
  },
}))

/**
 * Tests tree item for selected children
 * @param {object} node
 * @return boolean Returns true if has selected child, or false
 */
const hasSelectedChild = node => {
  if (typeof node === 'object' && node.children instanceof Array) {
    for (let child of node.children) {
      if (child.selected || hasSelectedChild(child)) {
        return true
      }
    }
  }

  return false
}

const CheckboxTree = ({
  onChange = () => {},
  onSelect = () => {},
  structure = [],
  setStructure = () => {},
  showActions,
  defaultChecked,
  selected = [],
  setSelected = () => {},
}) => {
  const classes = useStyles()
  const [scopedStructure] = useState(() => {
    let tempStructure = structure.clone ? structure.clone() : cloneDeep(structure)
    if (Array.isArray(tempStructure)) {
      tempStructure = tempStructure.map(item => {
        if (selected.includes(item.id)) {
          item.selected = true
        }

        return item
      })
    }

    return tempStructure
  }
  )

  const isChildSelected = (layer) => {
    if (layer.children) {
      for (let childLayer of layer.children) {
        if (childLayer.children && isChildSelected(childLayer)) {
          return true
        } else {
          return childLayer.selected
        }
      }
    }
    return layer.selected
  }

  //console.log(structure)
  const onLayerToggle = async (layers, isChecked, root = true) => {
    layers = layers instanceof Array ? layers : [layers]

    let tempSelected = []

    if (selected) {
      tempSelected = [...selected]
    }

    for (let layer of layers) {
      if (!layer.geoJson && !layer.selected) {
        // this is a parent, are any children selected
        if (isChildSelected(layer)) {
          //console.log(layer)
          isChecked = false
        }
      }

      layer.selected = isChecked

      if (selected) {
        if (isChecked) {
          tempSelected = [...tempSelected, layer.id]
        } else {
          tempSelected = tempSelected.filter(item => item !== layer.id)
        }
      }


      if (layer.children) {
        for (let child of layer.children) {
          onLayerToggle(child, isChecked, false)
        }
      }
    }
    // console.log(isChecked)
    // console.log(layers)

    if (selected) {
      setSelected(tempSelected)
    }

    if (layers[0].geoJson) {
      onSelect(layers[0].uuid, layers[0].geoJson, isChecked)
    }
  }

  const selectAll = async () => {
    onLayerToggle(scopedStructure, true)
  }

  const selectNone = async () => {
    onLayerToggle(scopedStructure, false)
  }

  useEffect(() => {
    onChange(scopedStructure)

    return () => {
      setStructure(scopedStructure)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedStructure])

  // useEffect(() => {
  //   if (defaultChecked) {
  //     selectAll()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  /**
   * Recursively generates TreeItems
   * @param { [{ name, [children] }] } children
   * @return JSX
   */
  let itemCount = 0

  const renderItems = layer => {
    if (!layer || layer.length === 0) {
      return null
    }
    //  console.log(layer)

    const children = layer instanceof Array ? layer : [layer]

    const items = children.map(child => {
      const treeId = `treeitem-${itemCount++}`

      return (
        <TreeItem
          key={treeId}
          nodeId={treeId}
          className={clsx(classes.child, {
            last: !child.children || child.children.length === 0,
          })}
          data-test="treeitem"
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={child.selected}
                  indeterminate={!child.selected && hasSelectedChild(child)}
                  onChange={async e => onLayerToggle(child, e.target.checked)}
                />
              }
              label={child.name}
            />
          }
        >
          {renderItems(child.children)}
        </TreeItem>
      )
    })

    return items
  }

  return (
    <>
      {showActions && (
        <>
          <Grid
            className={classes.actionContainer}
            container
            justify="center"
            spacing={1}
          >
            <Grid item>
              <Button onClick={selectAll} variant="outlined" color="secondary">
                All
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={selectNone} variant="outlined" color="secondary">
                None
              </Button>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" />
        </>
      )}

      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderItems(scopedStructure)}
      </TreeView>
    </>
  )
}

CheckboxTree.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  structure: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}

export default CheckboxTree
