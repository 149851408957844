import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useUIActions } from '../../store/UI/UIActions'
import { useProjectActions } from '../../store/Project/ProjectActions'
import { useAuthActions } from '../../store/Auth/AuthActions'
import { useStateContext } from '../../store/stateContext'

import AsseTracAppBar from '../elements/AsseTracAppBar'
import ProjectList from '../elements/ProjectList'

import { Container, Grid, Button, Typography } from '@material-ui/core'
import SystemIcon from '../elements/SystemIcon'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  addProjBtn: {
    marginLeft: 'auto',
  },
  Typography: {
    color: theme.colors.portlandCement,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  projectListContainer: {
    marginTop: theme.spacing(2),
  },
  inactiveContainer: {
    marginTop: theme.spacing(14),
  },
}))

export default () => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { openNewProjectDialog } = useUIActions()
  const { getProjectList } = useProjectActions()
  const { state } = useStateContext()
  const projectsState = state.projects


  useEffect(() => {
    getProjectList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState.projectList])

  return (
    <Grid container direction="column">
      <AsseTracAppBar title="PROJECTS" />
      <Container maxWidth="lg" className={classes.container}>
        {(projectsState.projectList.length === 0 && projectsState.inactiveProjectList.length === 0) && (
          <Grid container justify="center">
            {memberOf(/admins|customerAdmin[\S]+/) && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    align="center"
                    data-test="initialProjectBanner"
                    className={classes.Typography}
                  >
                        Looks like we're going to have to start fresh
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    data-test="initialProjectCreateButton"
                    onClick={openNewProjectDialog}
                  >
                        Get to Work!
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {(projectsState.projectList.length > 0 || projectsState.inactiveProjectList.length > 0) && (
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="h5" color="secondary">
                    ACTIVE PROJECTS
              </Typography>
              {memberOf(/admins|customerAdmin[\S]+/) && (
                <Button
                  color="primary"
                  className={classes.addProjBtn}
                  variant="contained"
                  data-test="projectCreateButton"
                  onClick={openNewProjectDialog}
                >
                  <SystemIcon name="icn_add" color="secondary" />
                      ADD PROJECT
                </Button>
              )}
            </Grid>
            <Grid item xs={12} className={classes.projectListContainer}>
              <ProjectList projects={projectsState.projectList} />
            </Grid>
            <Grid
              container
              item
              className={classes.inactiveContainer}
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="h5" color="secondary">
                    INACTIVE PROJECTS
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.projectListContainer}>
              <ProjectList projects={projectsState.inactiveProjectList} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Grid>
  )
}
