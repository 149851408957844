import {
  API,
  graphqlOperation,
  Storage,
} from 'aws-amplify'
import awsConfig from '../../aws-exports'
import * as moment from 'moment'
import {
  useStateContext,
} from '../stateContext'
import * as mutations from '../../graphql/mutations'
import {
  dateToUnix,
  safeGet,
} from '../../util'
import {
  useCustomerActions,
} from '../Customer/CustomerActions'

export const useProjectActions = () => {
  const {
    state,
  } = useStateContext()
  const {
    getActiveCustomer,
  } = useCustomerActions()

  const getProjectList = () => {
    return state.projects.projectList
  }
  const getInactiveProjectList = () => {
    return state.projects.inactiveProjectList
  }

  const getClientList = async () => {
    const data = getProjectList()
    var clients = data.reduce((acc, curr) => {
      acc.push(curr.client.trim())
      return acc
    }, [])

    // de-dupe the array
    clients = [...new Set(clients)]
    return clients
  }

  const getProject = id => {
    // const { data, errors } = await API.graphql(
    //   graphqlOperation(queries.getProject, { id })
    // )

    const projectList = safeGet('projects.projectList', state)
    const inactiveProjectList = safeGet('projects.inactiveProjectList', state)
    // console.log(tempList)
    let project = projectList.find(project => project.id === id)
    if (!project) {
      project = inactiveProjectList.find(project => project.id === id)
    }
    //console.log(project)
    if (project && project.startDate && !moment.isMoment(project.startDate)) {
      project.startDate = moment.unix(project.startDate)
    }
    if (project && project.endDate && !moment.isMoment(project.endDate)) {
      project.endDate = moment.unix(project.endDate)
    }
    //console.log(project)

    return project
  }

  const createProject = async ({
    name,
    client,
    description,
    boundaries,
    overlays,
    startDate,
    endDate,
    status,
  }) => {
    const customer = await getActiveCustomer()

    const id = name.trim().replace(/ /g, '_').toLowerCase() + '_' + new Date().getTime()
    // only query with properties in schema
    const input = {
      id: id,
      customerId: customer.id,
      name: name.trim(),
      client: client.trim(),
      description,
      boundaries,
      overlays,
      startDate: dateToUnix(startDate),
      endDate: dateToUnix(endDate),
      status: 'activating',
      userGroups: customer.userGroups,
      adminGroups: customer.adminGroups,
    }

    const project = await API.graphql(
      graphqlOperation(mutations.createProject, {
        input,
      }),
    )

    return project
  }

  const updateProject = async ({
    id,
    name,
    client,
    description,
    boundaries,
    overlays,
    startDate,
    endDate,
    status,
  }) => {
    // only query with properties in schema
    const input = {
      id,
      name,
      client,
      description,
      boundaries,
      overlays,
      startDate: dateToUnix(startDate),
      endDate: dateToUnix(endDate),
      status: status || 'active',
    }

    for (let prop in input) {
      if (typeof input[prop] === 'undefined') {
        Reflect.deleteProperty(input, prop)
      }
    }

    const project = await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input,
      }),
    )

    return project
  }

  const uploadOverlay = async ({
    name,
    file,
    structure,
    directory,
    project,
    progressCallback,
  }) => {
    const nameNoExtension = file.name.replace(/\.[^/.]+$/, '')
    const fileName = `${directory}/${nameNoExtension}/${file.name}`
    await Storage.put(fileName, file, {
      progressCallback,
    })
    const geoJsonFileName = `${directory}/${nameNoExtension}/${nameNoExtension}.json`
    const geoJsonS3Obj = await Storage.put(geoJsonFileName, JSON.stringify(structure), {
      progressCallback,
      contentType: 'json',
    })
    if (project.overlays && project.overlays.some(overlay => overlay.name === name)) {
      // this is an update of an existing overlay, no need to update the project
      return project
    }
    geoJsonS3Obj.bucket = awsConfig.aws_user_files_s3_bucket
    geoJsonS3Obj.region = awsConfig.aws_user_files_s3_bucket_region

    const newOverlay = {
      name,
      file: geoJsonS3Obj,
    }

    const input = {
      id: project.id,
      name: project.name,
      description: project.description,
      boundaries: project.boundaries,
      startDate: dateToUnix(project.startDate),
      endDate: dateToUnix(project.endDate),
      status: project.status,

      overlays: [...(project.overlays || []), newOverlay],
    }
    console.log(input)
    const {
      data,
    } = await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input,
      }),
    )

    return data.updateProject
  }

  return {
    createProject,
    getProject,
    getProjectList,
    getClientList,
    updateProject,
    uploadOverlay,
    getInactiveProjectList,
  }
}
