import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AsseTracAppBar from '../elements/AsseTracAppBar'
import { useConstantsActions } from '../../store/Constants/ConstantsActions'
import AssetIcon from '../elements/AssetIcon'
import SystemIcon from '../elements/SystemIcon'
import CreateAssetTypeDialog from '../dialogs/CreateAssetTypeDialog'
import CreateSubTypeDialog from '../dialogs/CreateSubTypeDialog'
import EditAssetTypeDialog from '../dialogs/EditAssetTypeDialog'
import EditAssetSubTypeDialog from '../dialogs/EditAssetSubTypeDialog'
import { useCancellablePromise, canceledPromiseErrorName } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  addSubtypeIcon: {
    color: theme.colors.asseTracOrange,
  },
  addSubtypeText: {
    color: theme.colors.asseTracOrange,
  },
  assetLabel: {
    color: theme.colors.quillFeather,
    textTransform: 'uppercase',
    marginRight: '10px',
    maxWidth: '250px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  assetTypeList: {
    width: '700px',
    color: theme.colors.portlandCement,
  },
  container: {
    marginTop: theme.spacing(4),
  },
  editButton: {
    margin: 'auto',
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  editIcon: {
    color: theme.colors.lightSteel,
  },
  listAction: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  listActionText: {
    display: 'inline-block',
    margin: '0 10px',
    color: theme.colors.quillFeather,
    textTransform: 'uppercase',
  },
  nestedList: {
    paddingLeft: theme.spacing(4),
  },
  lastItem: {
    marginBottom: theme.spacing(4),
  },
  spinnerContainer: {
    minHeight: '200px',
  },
  rowItem: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const GlobalAssetTypesPage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { cancellablePromise } = useCancellablePromise()

  const [loading, setLoading] = useState(true)
  const [assetTypes, setAssetTypes] = useState()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [subTypeDialogOpen, setSubTypeDialogOpen] = useState()
  const [editTypeDialogOpen, setEditTypeDialogOpen] = useState()
  const [editSubTypeDialogOpen, setEditSubTypeDialogOpen] = useState()

  const { getAssetTypes } = useConstantsActions()

  const refreshList = async () => {
    setLoading(true)
    try {
      const results = await cancellablePromise(getAssetTypes())
      setAssetTypes(results)
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    refreshList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container direction="column" className={classes.pageWrap}>
        <AsseTracAppBar title="GLOBAL ASSET TYPES" />
        <Container maxWidth="md" className={classes.container}>
          {loading && (
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.spinnerContainer}
            >
              <Grid item>
                <CircularProgress variant="indeterminate" />
              </Grid>
            </Grid>
          )}
          {!loading && (
            <>
              <Grid container>
                <Grid container item justify="space-between">
                  <Typography />
                  <Button
                    className={classes.newAssetButton}
                    color="primary"
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                  >
                    <SystemIcon name="icn_add" color="secondary" />
                    New Type
                  </Button>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <List className={classes.assetTypeList}>
                  {assetTypes.length === 0 && (
                    <ListItem>
                      <ListItemText>No asset types found</ListItemText>
                    </ListItem>
                  )}
                  {assetTypes.length > 0 &&
                    assetTypes.map(assetType => (
                      <div key={`assetType-${assetType.id}`}>
                        <ListItem className={classes.rowItem}>
                          <ListItemIcon>
                            <AssetIcon name={assetType.iconName} />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.assetLabel}
                            primary={assetType.name}
                          />
                          <div className={classes.listAction}>
                            <Typography
                              className={classes.listActionText}
                              component="span"
                            >
                              Idle {assetType.idleThreshold || 0} Days
                            </Typography>
                            <Typography
                              className={classes.listActionText}
                              component="span"
                            >
                              Offline {assetType.offlineThreshold || 0} Hours
                            </Typography>
                            <button
                              className={classes.editButton}
                              onClick={() => setEditTypeDialogOpen(assetType)}
                            >
                              <SystemIcon
                                className={classes.editIcon}
                                name="icn_pencil"
                              />
                            </button>
                          </div>
                        </ListItem>
                        <List className={classes.nestedList}>
                          {assetType.subType &&
                            assetType.subType.items &&
                            assetType.subType.items.map(assetSubType => (
                              <ListItem
                                className={classes.rowItem}
                                key={`assetSubType-${assetSubType.id}`}
                              >
                                <ListItemIcon>
                                  <AssetIcon name={assetSubType.iconName} />
                                </ListItemIcon>
                                <ListItemText
                                  className={classes.assetLabel}
                                  primary={assetSubType.name}
                                />
                                <div className={classes.listAction}>
                                  <Typography
                                    className={classes.listActionText}
                                    component="span"
                                  >
                                    Idle {assetSubType.idleThreshold || 0} Days
                                  </Typography>
                                  <Typography
                                    className={classes.listActionText}
                                    component="span"
                                  >
                                    Offline {assetSubType.offlineThreshold || 0}{' '}
                                    Hours
                                  </Typography>
                                  <button
                                    className={classes.editButton}
                                    onClick={() =>
                                      setEditSubTypeDialogOpen(assetSubType)
                                    }
                                  >
                                    <SystemIcon
                                      className={classes.editIcon}
                                      name="icn_pencil"
                                    />
                                  </button>
                                </div>
                              </ListItem>
                            ))}
                          <ListItem
                            className={classes.lastItem}
                            button
                            onClick={() => setSubTypeDialogOpen(assetType)}
                          >
                            <ListItemIcon>
                              <SystemIcon
                                className={classes.addSubtypeIcon}
                                name="icn_add"
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={classes.addSubtypeText}
                              primary="Add Subtype"
                            />
                          </ListItem>
                        </List>
                      </div>
                    ))}
                </List>
              </Grid>
            </>
          )}
        </Container>
      </Grid>

      <CreateAssetTypeDialog
        closeForm={() => setCreateDialogOpen(false)}
        onSave={newType => {
          refreshList()
          enqueueSnackbar('Successfully created type.', {
            variant: 'success',
          })
        }}
        open={createDialogOpen}
      />
      <EditAssetTypeDialog
        assetType={editTypeDialogOpen}
        closeForm={() => setEditTypeDialogOpen()}
        onSave={newType => {
          refreshList()
          enqueueSnackbar('Successfully updated type.', {
            variant: 'success',
          })
        }}
        open={!!editTypeDialogOpen}
      />

      <CreateSubTypeDialog
        assetType={subTypeDialogOpen}
        closeForm={() => setSubTypeDialogOpen()}
        onSave={newSubType => {
          refreshList()
          enqueueSnackbar('Successfully created subtype.', {
            variant: 'success',
          })
        }}
        open={!!subTypeDialogOpen}
      />
      <EditAssetSubTypeDialog
        assetSubType={editSubTypeDialogOpen}
        closeForm={() => setEditSubTypeDialogOpen()}
        onSave={newSubType => {
          refreshList()
          enqueueSnackbar('Successfully updated subtype.', {
            variant: 'success',
          })
        }}
        open={!!editSubTypeDialogOpen}
      />
    </>
  )
}

export default GlobalAssetTypesPage
