import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuthActions } from '../../store/Auth/AuthActions'

const ProtectedRoute = ({
  children,
  component,
  groups = [],
  path,
  redirect = '/',
  render,
}) => {
  const { memberOf } = useAuthActions()

  groups = groups instanceof Array ? groups : [groups]
  if (groups.some(group => !memberOf(group))) {
    // redirect to home page
    return <Redirect to={redirect} />
  }

  return (
    <Route
      children={children}
      component={component}
      path={path}
      render={render}
    />
  )
}

export default ProtectedRoute
