import { API, graphqlOperation } from 'aws-amplify'
import { useStateContext } from '../stateContext'
import { SET_MOVES } from './ReportsReducer'

import * as queries from '../../graphql/queries'

const DEFAULT_RECORD_LIMIT = 100000

export const useReportsActions = () => {
  const { dispatch } = useStateContext()

  const listAssetMovementReports = async filters => {
    const { data } = await API.graphql(
      graphqlOperation(queries.reportsByCustomer, {
        limit: DEFAULT_RECORD_LIMIT,
        sortDirection: 'DESC',
        ...filters,
      }),
    )
    const movesList = data.reportsByCustomer.items

    dispatch({
      type: SET_MOVES,
      payload: { movesList },
    })

    return movesList
  }

  return {
    listAssetMovementReports,
  }
}
