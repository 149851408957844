import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
  DialogContent,
  Button,
} from '@material-ui/core'

import SpinnerButton from '../elements/SpinnerButton'
import { useForm } from '../../hooks/Forms'
import { useCustomerActions } from '../../store/Customer/CustomerActions'
import { useStateContext } from '../../store/stateContext'

const CreateCustomerDialog = ({ open, closeForm = () => {} }) => {
  const { createCustomer } = useCustomerActions()

  const { state } = useStateContext()
  const customersState = state.customers.customerList
  const [customerNames, setCustomerNames] = useState([])

  const validationRules = {
    name: `required|unique:${customerNames.join(',')}`,
    email: 'required|email',
  }

  const {
    clearFormState,
    formState,
    getFieldError,
    getFirstFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm(validationRules)

  useEffect(() => {
    // Set up the validation rules
    // for the name using the customers
    const names = customersState.reduce((acc, cur) => {
      acc.push(cur.name)
      return acc
    }, [])
    setCustomerNames(names)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState])

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const submitForm = async fields => {
    await createCustomer(fields)

    closeForm()
    clearFormState()
  }

  return (
    <Dialog fullWidth open={open} onClose={closeForm}>
      <form onSubmit={e => handleSubmit(submitForm, e)}>
        <DialogTitle>CREATE CUSTOMER</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="Customer Name"
                onChange={handleInputChange('name')}
                value={getValue('name')}
                data-test="creatCustomerDialogName"
                error={!!getFieldError('name')}
                helperText={getFirstFieldError('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Admin Email"
                onChange={handleInputChange('email')}
                value={getValue('email')}
                data-test="creatCustomerDialogEmail"
                error={!!getFieldError('email')}
                helperText={getFirstFieldError('email')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleCancelForm}
            data-test="creatCustomerDialogCancelButton"
          >
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type="submit"
            valid={isValid()}
            data-test="creatCustomerDialogSubmitButton"
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateCustomerDialog
