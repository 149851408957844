import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'center',
    flexGrow: 9999999999,
    paddingLeft: theme.spacing(1),
  },
}))

export default props => {
  const classes = useStyles()

  return (
    <Typography className={classes.root} variant="h6">
      {props.children}
    </Typography>
  )
}
