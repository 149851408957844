import { API, Auth } from 'aws-amplify'
import { useStateContext } from '../stateContext'
import { SET_USER_LIST } from './UserReducer'

const apiName = 'AdminQueries'

export const useUserActions = () => {
  const { state, dispatch } = useStateContext()

  const addUserToGroup = async (username, groupname) => {
    const path = '/addUserToGroup'
    const request = {
      body: {
        username,
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  const removeUserFromGroup = async (username, groupname) => {
    const path = '/removeUserFromGroup'
    const request = {
      body: {
        username,
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  const enableUser = async username => {
    const path = '/enableUser'
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  const disableUser = async username => {
    const path = '/disableUser'
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  const createGroup = async groupname => {
    const path = '/createGroup'
    const request = {
      body: {
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  const createUser = async username => {
    const path = '/createUser'
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    const user = await API.post(apiName, path, request)

    return user
  }

  const getUser = async username => {
    const path = '/getUser'
    const request = {
      queryStringParameters: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    const user = await API.get(apiName, path, request)
    return user
  }

  const getUsers = async () => {
    const path = '/listUsers'
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    const userList = await API.get(apiName, path, request)

    dispatch({ type: SET_USER_LIST, payload: { userList } })

    return userList
  }

  const getUsersInGroup = async groupname => {
    const path = '/listUsersInGroup'
    const request = {
      queryStringParameters: {
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    const { Users: userList } = await API.get(apiName, path, request)

    dispatch({ type: SET_USER_LIST, payload: { userList } })

    return userList
  }

  const inviteUsers = async ({ customerGroup, emails, userType = 'User' }) => {
    if (!customerGroup || !emails || !userType) {
      throw new Error('customerGroup, emails, and userType required.')
    }

    const path = '/customerInviteUsers'
    const request = {
      body: {
        customerGroup,
        userType,
        emails,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }

    return await API.post(apiName, path, request)
  }

  return {
    addUserToGroup,
    removeUserFromGroup,
    enableUser,
    disableUser,
    createGroup,
    createUser,
    getUser,
    getUsers,
    getUsersInGroup,
    inviteUsers,
    usersState: state.users,
  }
}
