import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import AsseTracAppBar from '../elements/AsseTracAppBar'
import SystemIcon from '../elements/SystemIcon'
import AddOwnersDialog from '../dialogs/AddOwnersDialog'
import { useStateContext } from '../../store/stateContext'
import { useCustomerActions } from '../../store/Customer/CustomerActions'
import { useUserActions } from '../../store/User/UserActions'
import { formatDate } from '../../util'
import { SET_USER_LIST } from '../../store/User/UserReducer'
import { useCancellablePromise, canceledPromiseErrorName } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  cellLabel: {
    color: theme.colors.lightSteel,
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  email: {
    paddingRight: theme.spacing(2),
  },
  selectBox: {
    textAlign: 'left',
    minWidth: 120,
  },
  spinnerContainer: {
    minHeight: '200px',
  },
}))

const OwnersPage = () => {
  const classes = useStyles()
  const { getUsersInGroup, usersState } = useUserActions()
  const { customerState } = useCustomerActions()
  const { dispatch } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()

  const [addOwnersOpen, setAddOwnersOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const refreshOwnerList = async () => {
    try {
      setLoading(true)
      let userList = await cancellablePromise(getUsersInGroup('admins'))

      userList = userList.reduce((acc, current) => {
        acc.push({
          ...current,
          Email: current.Attributes.find(at => {
            if (at.Name !== 'email') {
              return false
            }
            return true
          }).Value,
        })
        return acc
      }, [])

      dispatch({ type: SET_USER_LIST, payload: { userList } })
      setLoading(false)
      return userList
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.error(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    refreshOwnerList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState.activeCustomer])

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.pageWrap}
        width="50%"
      >
        <AsseTracAppBar title="OWNERS" />
        <Container maxWidth="md" className={classes.container}>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="secondary" variant="subtitle1">
                {usersState.userList.length}
                {usersState.userList.length === 1 ? (
                  <span> owner</span>
                ) : (
                  <span> total owners</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                color="primary"
                onClick={() => setAddOwnersOpen(true)}
                variant="contained"
              >
                <SystemIcon name="icn_add" /> ADD OWNERS
              </Button>
            </Grid>
          </Grid>

          {loading && (
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.spinnerContainer}
            >
              <Grid item>
                <CircularProgress variant="indeterminate" />
              </Grid>
            </Grid>
          )}
          {!loading && (
            <Grid container className={classes.listContainer} spacing={2}>
              {usersState.userList.map((user, index) => (
                <Grid
                  container
                  item
                  key={`user-row-${index}`}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item md className={classes.email}>
                    <Typography color="secondary">{user.Email}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="secondary">
                      <span className={classes.cellLabel}>Since: </span>
                      {formatDate(user.UserCreateDate, 'M/D/Y')}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography color="secondary">
                      <span className={classes.cellLabel}>Last Login: </span>
                      {formatDate(user.UserLastModifiedDate, 'M/D/Y')}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Grid>
      <AddOwnersDialog
        closeForm={() => setAddOwnersOpen(false)}
        open={addOwnersOpen}
        onSave={refreshOwnerList}
      />
    </>
  )
}

export default OwnersPage
