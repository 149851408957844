import React, { forwardRef } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import {
  SaveAlt,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  ArrowDownward,
} from '@material-ui/icons'

export const colorPalette = {
  asseTracOrange: '#EC4F36',
  portlandCement: '#E8DCDA',
  quillFeather: '#FBFDFB',
  lightSteel: '#666564',
  backgroundColor: '#3A3938',
  canary: '#ECAE36',
  midnightBlue: '#0A1034',
}
const tableIcons = {
  Export: forwardRef((props, ref) => (
    <SaveAlt color="primary" {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
}

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colorPalette.asseTracOrange,
    },
    secondary: {
      main: colorPalette.quillFeather,
    },
    error: {
      main: colorPalette.canary,
    },
  },
  typography: {
    fontFamily: [
      'Interstate',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      // Style sheet name
      root: {
        // Name of the rule
        borderRadius: 50, // Some CSS
      },
      text: {
        color: colorPalette.asseTracOrange,
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colorPalette.backgroundColor,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: colorPalette.lightSteel,
        borderStyle: 'solid',
      },
      root: {
        '& .MuiToolbar-root': {
          '& .MuiDivider-vertical': {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 8,
            marginRight: 8,
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        '& .MuiDivider-vertical': {
          alignSelf: 'stretch',
          height: 'auto',
          margin: 8,
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: colorPalette.backgroundColor,
      },
      rounded: {
        borderRadius: 8,
      },
    },
    MuiCard: {
      root: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorPalette.lightSteel,
      },
    },
    MuiPopover: {
      paper: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorPalette.portlandCement,
      },
    },
    MuiDialog: {
      paper: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorPalette.lightSteel,
      },
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: colorPalette.lightSteel,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colorPalette.lightSteel,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: colorPalette.quillFeather,
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
    },
    MuiTablePagination: {
      root: {
        '&:last-child': {
          border: 'none',
        },
      },
    },
    MuiSnackbar: {
      root: {
        '& .MuiPaper-root': {
          background: colorPalette.asseTracOrange,
          color: colorPalette.quillFeather,
        },
      },
    },
  },
  colors: colorPalette,
  tableIcons,
})
