import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import SpinnerButton from '../elements/SpinnerButton'
import { useForm } from '../../hooks/Forms'
import Validator from '../../libs/Validator'
import { makeStyles } from '@material-ui/styles'
import { useUserActions } from '../../store/User/UserActions'

const validator = new Validator({
  email: 'email',
})

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: theme.palette.error.main,
  },
}))

const AddOwnersDialog = ({ closeForm, onSave = () => {}, open }) => {
  const classes = useStyles()

  const {
    clearFormState,
    formState,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    emails: 'required|email',
  })

  const [formError, setFormError] = useState()
  const { inviteUsers } = useUserActions()

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const handleBeforeAdd = email => {
    if (!email) {
      return false
    }

    const error = validator.validate({ email })

    setFormError(error && error.email[0])

    return error === null
  }

  const onSubmit = async fields => {
    const customerGroup = 'admins'

    await inviteUsers({
      ...fields,
      userType: 'Admin',
      customerGroup,
    })

    closeForm()
    clearFormState()
    onSave()
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open}>
      <form onSubmit={e => handleSubmit(onSubmit, e)}>
        <DialogTitle>ADD OWNERS</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ChipInput
                  blurBehavior="add"
                  fullWidth
                  onBeforeAdd={handleBeforeAdd}
                  onChange={handleInputChange('emails')}
                  label="EMAIL ADDRESSES"
                  variant="outlined"
                />
                <FormHelperText className={classes.errorMsg}>
                  {formError}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type="submit"
            valid={isValid()}
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddOwnersDialog
