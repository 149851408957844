import React from 'react'
import { Button } from '@material-ui/core'
import SystemIcon from './SystemIcon'
import { makeStyles } from '@material-ui/core/styles'
import { useUIActions } from '../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: '50%',
    marginLeft: -70,
  },
}))

export default () => {
  const classes = useStyles()
  const { openDrawerAnomaly } = useUIActions()

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      elevation={12}
      className={classes.root}
      onClick={openDrawerAnomaly}
    >
      <SystemIcon name="icn_report" color="secondary" />
      Generate Report
    </Button>
  )
}
