import validator from 'validator'

/**
 * Class for validating forms
 *
 * Usage:
 *   const rules = {
 *     email: 'required|email',
 *     password: 'required',
 *     confirmPassword: 'required|match:password',
 *   }
 *   const validator = new Validator(rules)
 *
 * To create new rules, add a new method following this pattern:
 *   myRule(value, arg1, arg2, ...) {
 *     if (value === arg1 && value !== arg2) {
 *       return 'There was an error'
 *     }
 *     return null
 *   }
 */
export default class Validator {
  inputs = {}

  constructor(rules = {}) {
    this.rules = rules
  }

  /**
   * Tests input for defined rules
   * @param {object} inputs  Object keyed by input name and valued by input value
   */
  validate(inputs = {}) {
    this.inputs = inputs
    let errors = null

    let values
    for (let name in this.rules) {
      values = inputs[name] || inputs[name] === 0 ? inputs[name] : ''

      // convert to array
      if (!(values instanceof Array)) {
        values = [values]
      }

      // fill with empty value
      if (values.length === 0) {
        values = ['']
      }

      let rules = this.rules[name]

      if (typeof rules === 'string') {
        // convert rules to array
        rules = rules.split('|')
      } else if (!(rules instanceof Array)) {
        throw new Error(
          'Validator rules must be pipe separated string or an array',
        )
      }

      if (rules.length === 0) {
        return true
      }

      let fieldErrors = []

      let args, rule, error
      for (let rawRule of rules) {
        args = rawRule.split(':')
        rule = args.shift()

        if (typeof this[rule] !== 'function') {
          // unknown rule
          continue
        }

        for (let value of values) {
          error = this[rule](value, ...args)

          if (error) {
            fieldErrors.push(error)
          }
        }
      }

      if (fieldErrors.length > 0) {
        errors = errors || {}
        errors[name] = errors[name] || []
        errors[name].push(fieldErrors)
      }
    }

    return errors
  }

  /**
   * Check that value is NOT empty
   * @param {string} value
   * @return {string} Returns error, or null
   */
  required(value) {
    if (!value) {
      return 'Required'
    }

    return null
  }

  /**
   * Compares that value matches other input
   * @param {?} value
   * @param {string} foreignInputName
   * @return {string} Returns error, or null
   */
  matches(value, foreignInputName) {
    if (value !== this.inputs[foreignInputName]) {
      return 'Does not match'
    }

    return null
  }

  /**
   * Checks if value is a valid email address
   * @param {?} value
   * @return {string} Returns error, or null
   */
  email(value) {
    if (!validator.isEmail(value)) {
      return 'Must be a valid email address'
    }

    return null
  }

  /**
   * Checks if value is a valid password
   * @param {?} value
   * @return {string} Returns error, or null
   */
  password(value) {
    if (value.length < 8) {
      return 'Password must be at least 8 characters long'
    }

    return null
  }

  /**
   * Check that value is a Date
   * @param {?} value
   * @return {string} Returns error, or null
   */
  date(value) {
    if (!(value instanceof Date)) {
      return 'Must be a date'
    }

    return null
  }

  /**
   * Check that value is a File
   * @param {?} value
   * @return {string} Returns error, or null
   */
  file(value, types) {
    if (!(value instanceof File)) {
      return 'Must be a file'
    }

    types = types.split(',')
    if (types.indexOf(value.type) < 0) {
      return 'Invalid file format'
    }

    return null
  }

  /**
   * Checks that value is a number
   * @param {number} value
   * @param {string} condition  >{number}, <{number}, >=
   * @return {string} Returns error, or null
   */
  number(value, condition) {
    if (value === '' || value === null || isNaN(value)) {
      return 'Must be a number'
    }

    if (condition) {
      const [, operator, comparison] = condition.match(
        /([><=][=]*)[\s]*([0-9]+)/,
      )
      const allowedOperators = ['<', '>', '<=', '>=', '==']

      // DANGER: JS injection possible without this condition
      if (allowedOperators.indexOf(operator) < 0 || isNaN(comparison)) {
        return 'Condition invalid'
      }

      const statement = `${value} ${operator} ${comparison}`
      // eslint-disable-next-line
      if (!eval(statement)) {
        return `Must be ${operator} ${comparison}`
      }
    }

    return null
  }

  /**
   * Check that value is a list of coordinates
   * @param {?} value
   * @return {string} Returns error, or null
   */
  coordinates(value) {
    const error = 'Invalid coordinates'

    if (!value) {
      return error
    }

    if (!(value instanceof Array)) {
      value = [value]
    }

    for (let item of value) {
      if (
        typeof item !== 'object' ||
        typeof item.longitude !== 'number' ||
        typeof item.latitude !== 'number'
      ) {
        return error
      }
    }

    return null
  }

  /**
   * Compares value against comma separated list of values for unique status
   * @param {?} value
   * @param {string} list
   * @return {string} Returns error, or null
   */
  unique(value, list) {
    list = list.split(',')
    list.map(Function.prototype.call, String.prototype.trim)
    if (list.indexOf(value.trim()) > -1) {
      return 'Must be unique'
    }

    return null
  }
}
