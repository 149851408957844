import { API, graphqlOperation } from 'aws-amplify'
import { useStateContext } from '../stateContext'
import { SET_CONSTANTS } from './ConstantsReducer'

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import * as customQueries from '../../graphql/customQueries'

export const useConstantsActions = () => {
  const { dispatch, state } = useStateContext()

  const getAssetTypesGraphQL = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(customQueries.listAssetTypesAndSubTypes, {
          limit: 1000,
        }),
      )
      return data.listAssetTypes.items
    } catch (error) {
      throw error
    }
  }
  const getDimensionsGraphQL = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(queries.listDimensionss, { limit: 1000 }),
      )
      return data.listDimensionss.items
    } catch (error) {
      throw error
    }
  }
  const getSuppliersGraphQL = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(queries.listSuppliers, { limit: 1000 }),
      )
      return data.listSuppliers.items
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const getConstants = async reFetch => {
    if (reFetch || state.constants.assetTypes.length === 0) {
      const assetTypes = await getAssetTypesGraphQL()
      const dimensions = await getDimensionsGraphQL()
      const suppliers = await getSuppliersGraphQL()
      const constants = {
        assetTypes: assetTypes,
        dimensions: dimensions,
        suppliers: suppliers,
      }
      dispatch({
        type: SET_CONSTANTS,
        payload: constants,
      })
      return constants
    }

    return state.constants
  }

  const getAssetTypes = async () => {
    const constants = await getConstants(true)
    return constants.assetTypes || []
  }

  const createAssetType = async ({ name, iconName, idleThreshold, offlineThreshold }) => {
    const { data, errors } = await API.graphql(
      graphqlOperation(mutations.createAssetType, {
        input: {
          name,
          iconName,
          idleThreshold,
          offlineThreshold,
        },
      }),
    )

    if (errors) {
      throw errors
    }

    return data.createAssetType
  }

  const updateAssetType = async ({
    assetType,
    name,
    iconName,
    idleThreshold,
    offlineThreshold,
  }) => {
    const { data, errors } = await API.graphql(
      graphqlOperation(mutations.updateAssetType, {
        input: {
          id: assetType.id,
          name,
          iconName,
          idleThreshold,
          offlineThreshold,
        },
      }),
    )

    if (errors) {
      throw errors
    }

    return data.updateAssetType
  }

  const createSubType = async ({
    assetType,
    name,
    iconName,
    idleThreshold,
  }) => {
    if (typeof assetType !== 'object' || !assetType.id) {
      throw new Error('Must provide assetType object')
    }

    const { data, errors } = await API.graphql(
      graphqlOperation(mutations.createAssetSubType, {
        input: {
          name,
          iconName,
          idleThreshold,
          typeId: assetType.id,
        },
      }),
    )

    if (errors) {
      throw errors
    }

    return data.createAssetType
  }

  const updateAssetSubType = async ({
    assetSubType,
    name,
    iconName,
    idleThreshold,
  }) => {
    const { data, errors } = await API.graphql(
      graphqlOperation(mutations.updateAssetSubType, {
        input: {
          id: assetSubType.id,
          name,
          iconName,
          idleThreshold,
        },
      }),
    )

    if (errors) {
      throw errors
    }

    return data.updateAssetType
  }

  return {
    createAssetType,
    createSubType,
    getConstants,
    getAssetTypes,
    updateAssetType,
    updateAssetSubType,
  }
}
