import React, { useEffect, useState, useRef } from 'react'
import {
  Divider,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SystemIcon from './SystemIcon'
import { useProjectActions } from '../../store/Project/ProjectActions'
import { canceledPromiseErrorName, useCancellablePromise } from '../../promiseUtils'

const useStyles = makeStyles(theme => ({
  textField: {},
  clientSelect: {},
  menuItemButtonWrap: {
    pointerEvents: 'none',
    '&:hover': {
      background: 'none',
    },
    '& button': {
      pointerEvents: 'all',
    },
  },
  cancelButtonWrap: {
    marginTop: 19,
  },
}))

const ClientPicker = ({ error, helperText, value, onChange = () => {} }) => {
  const classes = useStyles()
  const inputLabelClient = useRef()
  const { cancellablePromise } = useCancellablePromise()
  const [addClientMode, setAddClientMode] = useState()
  const [clientList, setClientList] = useState()

  const { getClientList } = useProjectActions()

  const handleChange = event => {
    onChange(event.target.value)
  }

  const newClient = () => {
    setAddClientMode(true)
    onChange('')
  }

  const cancelNewClient = () => {
    setAddClientMode(false)
    onChange('')
  }

  useEffect(() => {
    cancellablePromise(getClientList())
      .then(results => {
        setClientList(results)
        if (value && results.indexOf(value) < 0) {
          setAddClientMode(true)
        }
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container item spacing={1} alignItems="flex-start">
      {!addClientMode && (
        <>
          <Grid item xs>
            <FormControl
              error={error}
              className={classes.formControl}
              variant="outlined"
              fullWidth
              margin="normal"
            >
              <InputLabel ref={inputLabelClient} id="client-select-label">
                Client
              </InputLabel>
              <Select
                id="client-select"
                value={value}
                onChange={handleChange}
                displayEmpty
                className={classes.clientSelect}
                labelWidth={
                  inputLabelClient.current
                    ? inputLabelClient.current.offsetWidth
                    : 0
                }
              >
                {clientList &&
                  clientList.length > 0 &&
                  clientList.map(client => (
                    <MenuItem
                      key={client}
                      value={client}
                    >{`${client}`}</MenuItem>
                  ))}
                {clientList && clientList.length > 0 && (
                  <Divider orientation="horizontal" />
                )}
                <MenuItem
                  disableRipple
                  value={null}
                  className={classes.menuItemButtonWrap}
                >
                  <Button onClick={newClient}>New Client</Button>
                </MenuItem>
              </Select>
              {helperText && <FormHelperText>{`${helperText}`}</FormHelperText>}
            </FormControl>
          </Grid>
        </>
      )}
      {addClientMode && (
        <>
          <Grid item xs>
            <TextField
              autoFocus
              className={classes.textField}
              error={error}
              fullWidth
              helperText={helperText}
              label={'New Client'}
              margin="normal"
              value={value}
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item className={classes.cancelButtonWrap}>
            <IconButton
              data-test="cancelNewClientButton"
              onClick={cancelNewClient}
            >
              <SystemIcon name="icn_x" color="secondary" />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ClientPicker
