export const INIT_PROJECTS = 'INIT_PROJECTS'
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_LIST = 'ADD_PROJECT_LIST'
export const ADD_INACTIVE_PROJECT_LIST = 'ADD_INACTIVE_PROJECT_LIST'

export const initialState = {
  projectList: [],
  inactiveProjectList: [],

}

export const reducer = (project = initialState, {
  type,
  payload,
}) => {
  switch (type) {
    case INIT_PROJECTS:
      return {
        ...initialState,
      }
    case SET_PROJECT_LIST:
      const {
        projectList,
      } = payload

      return {
        ...project,
        projectList,
      }
    case ADD_PROJECT_LIST:
      const {
        addProjectList,
      } = payload

      return {
        ...project,
        projectList: [...project.projectList, ...addProjectList],
      }
    case UPDATE_PROJECT:
      console.log(payload)
      const foundActiveProject = project.projectList.find(project => project.id === payload.project.id)
      // is this an update to an active project
      if (foundActiveProject && (payload.project.status === 'active' || payload.project.status === 'activating')) {
        return {
          ...project,
          projectList: project.projectList.map(p => {
            if (p.id === payload.project.id) {
              return payload.project
            }
            return p
          }),
        }
      } else if (
        !foundActiveProject &&
        (payload.project.status === 'active' || payload.project.status === 'activating')) {
      // this is an inactive project that has been made active
        const indexOf = project.inactiveProjectList.map(project => project.id).indexOf(payload.project.id)
        if (indexOf >= 0) {
          project.inactiveProjectList.splice(indexOf, 1)
        }
        return {
          ...project,
          projectList: [...project.projectList, payload.project],
        }
      } else if (
        foundActiveProject &&
        (payload.project.status === 'inactive' || payload.project.status === 'deactivating')) {
      // the project has been made inactive
        const indexOf = project.projectList.map(project => project.id).indexOf(payload.project.id)
        if (indexOf >= 0) {
          project.projectList.splice(indexOf, 1)
        }
        return {
          ...project,
          //projectList: [...projectList],
          inactiveProjectList: [...project.inactiveProjectList, payload.project],
        }
      }
      // if we got here an inactive project has been updated, shouldn't happen but if it does
      return {
        ...project,
        //projectList: [...projectList],
        inactiveProjectList: project.inactiveProjectList.map(p => {
          if (p.id === payload.project.id) {
            return payload.project
          }
          return p
        }),
      }

    case ADD_PROJECT:
      console.log(project)
      return {
        ...project,
        projectList: [...project.projectList, payload.addProject],
      }
    case ADD_INACTIVE_PROJECT_LIST:
      return {
        ...project,
        inactiveProjectList: [...project.inactiveProjectList, ...payload.addInactiveProjectList],
      }
    default:
      return project
  }
}
