/**
 * Combines reducers of many modules into single reducer
 * @param {object} reducers  Object of reducer functions keyed by module name
 * @return {array} [reducer, initialState]
 */

//import lodash from 'lodash'

export const combineReducers = reducers => {
  // integrate with Redux Dev Tools
  // const devtools = window && window.__REDUX_DEVTOOLS_EXTENSION__
  // if (devtools) {
  //   devtools.connect()
  // }

  const reducer = (state, action) => {
    const newState = Object.keys(reducers).reduce((acc, key) => {
      acc[key] = reducers[key](state[key], action)
      return acc
    }, {})

    // //log event to Redux Dev Tools
    // if (devtools) {
    //   // devtools.send({
    //   //   ...action
    //   // }, newState)
    //   devtools.send({
    //     ...action,
    //   }, newState, {
    //     actionSanitizer(action) {
    //       return { ...action, data: lodash.clone(action.data) }
    //     },
    //   })
    // }

    // limit to 3 levels for newstate

    return newState
  }

  return [reducer, reducer({}, {
    type: '@@INIT',
  })]
}
