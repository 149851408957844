import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import dotenv from 'dotenv'
import { SnackbarProvider } from 'notistack'

import { StateProvider } from './store/stateContext'
import { ThemeProvider } from '@material-ui/styles'

import awsmobile from './aws-exports'
import * as serviceWorker from './serviceWorker'
import App from './App'
import './App.scss'
import { theme } from './theme'

/* MAP STYLES */
import '../node_modules/mapbox-gl/dist/mapbox-gl.css'
/* BASE STYLES */
import './index.css'

Amplify.configure(awsmobile)
dotenv.config()

document.addEventListener('DOMContentLoaded', event => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <StateProvider>
          <App />
        </StateProvider>
      </SnackbarProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// support path prefixed with #! for use with S3 redirection
if (window && window.location.hash.indexOf('#!') === 0) {
  window.history.pushState({}, 'entry page', window.location.hash.substring(3))
}
