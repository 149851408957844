import React, { useEffect, useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import moment from 'moment'

import {
  Container,
  Drawer,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core'

import SystemIcon from './SystemIcon'
import MaterialTable from 'material-table'

import { useStateContext } from '../../store/stateContext'
import { safeGet } from '../../util'
import { useUIActions } from '../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDrawer-paper': {
      maxHeight: '83vh',
      borderTopWidth: 1,
      borderTopColor: theme.colors.lightSteel,
      borderTopStyle: 'solid',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    color: theme.colors.portlandCement,
    textTransform: 'uppercase',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  tableWrap: {
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
}))

export default ({ anomaly, data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const tableIcons = theme.tableIcons
  const { closeDrawerAnomaly } = useUIActions()
  const {
    state: { ui: uiState },
  } = useStateContext()
  const [tableData, setTableData] = useState(null)
  const massageData = data => {
    const formattedData = data.reduce((acc, current) => {
      const lastCheckedIn = moment
        .unix(safeGet('eventTs', current, ''))

      acc.push({
        name: safeGet('name', current, ''),
        activatedOn: safeGet('activatedOn', current, ''),
        latitude: safeGet(
          'coordinate.latitude',
          current,
          '',
        ),
        longitude: safeGet(
          'coordinate.longitude',
          current,
          '',
        ),
        battery: safeGet('battery', current, ''),
        rssi: safeGet('rssi', current, ''),
        nextToken: safeGet('nextToken', current, ''),
        assetMovement: safeGet('assetMovement', current, ''),
        customer: safeGet('customerId', current, ''),
        deactivatedOn: safeGet('deactivatedOn', current, ''),
        description: safeGet('description', current, ''),
        lastCheckedIn: lastCheckedIn.format('M/D/YY hh:mm:ss'),
        firstCheckedIn: moment
          .unix(safeGet('firstCheckedIn', current, ''))
          .format('M/D/YY hh:mm:ss'),
        id: safeGet('id', current, ''),
        project: safeGet('projectId', current, ''),
        subType: safeGet('subType.name', current, ''),
        type: safeGet('type.name', current, ''),
        uuid: safeGet('uuid', current, ''),
        offlineFor: lastCheckedIn.fromNow(),
        deviceNumber: safeGet('deviceNumber', current, ''),
        unitNumber: safeGet('unitNumber', current, ''),
      })

      return acc
    }, [])
    return formattedData
  }
  useEffect(() => {
    if (anomaly === null) setTableData(null)

    if (anomaly) setTableData(massageData(data.sort((a, b) => b.eventTs - a.eventTs)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Drawer
      className={classes.root}
      anchor="bottom"
      open={uiState.drawerAnomalyOpen}
      onClose={closeDrawerAnomaly}
    >
      <Container fixed>
        <Grid container spacing={0}>
          <Grid container item direction="row" justify="space-between">
            <Grid item>
              <Typography className={classes.title}>
                {tableData && `${tableData.length}`} ASSETS {`${anomaly}`}
              </Typography>
            </Grid>
            <Grid item className={classes.closeButton}>
              <IconButton
                data-test="closeDrawerButton"
                onClick={closeDrawerAnomaly}
                color="inherit"
              >
                <SystemIcon name="icn_x" color="secondary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tableWrap}>
            {tableData && (
              <MaterialTable
                icons={tableIcons}
                options={{
                  search: false,
                  toolbarButtonAlignment: 'left',
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  headerStyle: {
                    minHeight: '48px',
                  },
                }}
                columns={[

                  { title: 'UnitId', field: 'unitNumber' },
                  { title: 'Type', field: 'type' },
                  { title: 'Subtype', field: 'subType' },
                  {
                    title: 'Time Since Last Checkin',
                    field: 'offlineFor',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    title: 'Last Checked Date Time',
                    field: 'lastCheckedIn',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    title: 'Initial Checkin',
                    field: 'firstCheckedIn',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    title: 'UUID',
                    field: 'uuid',
                    cellStyle: { minWidth: '136px' },
                  },
                  { title: 'Lat', field: 'latitude' },
                  { title: 'Long', field: 'longitude' },
                  { title: 'ProjectId', field: 'project' },
                ]}
                data={tableData}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <div className={classes.drawerContentWrap} />
    </Drawer>
  )
}
