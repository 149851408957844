import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core'

import AccountNav from './AccountNav'
import SystemIcon from '../../elements/SystemIcon'
import CustomerSelector from '../../elements/CustomerSelector'

import { useStateContext } from '../../../store/stateContext'
import { useAuthActions } from '../../../store/Auth/AuthActions'
import { useUIActions } from '../../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  list: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  drawerContentWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  customerSelectorWrap: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export default () => {
  const classes = useStyles()
  const history = useHistory()
  const { memberOf, signOut } = useAuthActions()
  const { closeDrawerNav } = useUIActions()
  const {
    state: { projects: projectsState, ui: uiState },
  } = useStateContext()

  const handleSignOut = () => {
    signOut()
    history.push('/')
  }

  return (
    <Drawer open={uiState.drawerNavOpen} onClose={closeDrawerNav}>
      <div className={classes.drawerContentWrap}>
        {memberOf('admins') && (
          <div className={classes.customerSelectorWrap}>
            <CustomerSelector />
          </div>
        )}
        <List className={classes.list} onClick={closeDrawerNav}>
          <Link to="/">
            <ListItem button>
              <ListItemIcon>
                <SystemIcon color="secondary" name="icn_map" />
              </ListItemIcon>
              <ListItemText>MAP</ListItemText>
            </ListItem>
          </Link>
          {(memberOf(/admins|customerAdmin[\S]+/) || projectsState.projectList.length > 0) && (
            <Link to="/Projects">
              <ListItem button to="/Projects" data-test="projectsButton">
                <ListItemIcon>
                  <SystemIcon color="secondary" name="icn_project" />
                </ListItemIcon>
                <ListItemText>PROJECTS</ListItemText>
              </ListItem>
            </Link>
          )}
        </List>
        <AccountNav />
        <Button
          color="secondary"
          className={classes.button}
          data-test="signoutButton"
          onClick={handleSignOut}
        >
          Sign out
        </Button>
      </div>
    </Drawer>
  )
}
