function importIcons(r) {
  let images = {}
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item)
    return null
  })
  return images
}
const icons = importIcons(
  require.context('../assets/icons/types', false, /\.(png|jpe?g|svg)$/),
)
export default function getIcon(filename) {
  return icons[filename]
}
