import React, { useState, createRef } from 'react'
import {
  IconButton,
  SvgIcon,
  Divider,
  Toolbar,
  FormControlLabel,
  Switch,
  Hidden,
  Tabs,
  Tab,
} from '@material-ui/core'
import Icons from '../../assets/icons/symbol-defs.svg'
import { makeStyles } from '@material-ui/core/styles'

import { useStateContext } from '../../store/stateContext'
import ToolbarToggleButton from './ToolbarToggleButton'
import MapFilterMenu from './MapFilterMenu'
import SystemIcon from './SystemIcon'
import { useUIActions } from '../../store/UI/UIActions'

const useStyles = makeStyles(theme => ({
  toolbar: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottomColor: theme.colors.lightSteel,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  toolbarIconButton: {
    padding: 8,
  },
  fullScreenToggle: {
    marginLeft: 'auto',
  },
  openOverlayDrawerButton: {
    marginLeft: 'auto',
  },
  tabs: {},
  tab: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    minHeight: 'unset',
    minWidth: theme.spacing(9),
    '& svg': {
      marginBottom: '0 !important',
      width: 20,
      height: 20,
    },
  },
}))

const ProjectToolbar = ({
  assetsFilter,
  setAssetsFilter,
  assetTypeList,
  setAnomalyFilter,
  anomalyFilter,
  handleTabChange,
  setAssetTypeList,
  curTab,
}) => {
  const classes = useStyles()
  const assetsFilterButton = createRef()
  const { state } = useStateContext()
  const [openMenu, setOpenMenu] = useState(null)
  const {
    openTempDrawerMapOverlays,
    toggleFullScreenProjectMap,
  } = useUIActions()
  const handleToolbarButtonClick = event => {
    setOpenMenu(event.currentTarget.id)
  }
  const handleClose = () => {
    setOpenMenu(null)
  }

  const handelAnomalyFilterChange = (event, anomaly) => {
    if (anomalyFilter === anomaly) {
      setAnomalyFilter(null)
      return
    }
    setAssetsFilter([])
    setAnomalyFilter(anomaly)
  }
  const isLit = filter => {
    if (!filter || !filter.checkFilter) return
    return filter.checkFilter.length > 0
  }
  const curTabMap = curTab === 0

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <Tabs
        className={classes.tabs}
        value={curTab}
        onChange={handleTabChange}
        variant="standard"
        indicatorColor="primary"
        textColor="secondary"
      >
        <Tab
          className={classes.tab}
          label="Map"
          icon={<SystemIcon name="icn_map" />}
        />
        <Tab
          className={classes.tab}
          label="Moves"
          icon={<SystemIcon name="icn_moves" />}
        />
      </Tabs>
      <Divider orientation="vertical" className={classes.divider} />
      {curTabMap && (
        <>
          <ToolbarToggleButton
            lit={isLit(assetsFilter)}
            id="assetsFilter"
            icon="#icn_assets"
            ref={assetsFilterButton}
            onClick={handleToolbarButtonClick}
          />
          <MapFilterMenu
            open={openMenu === 'assetsFilter'}
            onClose={handleClose}
            onChange={setAssetsFilter}
            getData={assetTypeList}
            setData={setAssetTypeList}
            anchor={assetsFilterButton}
            filters={assetsFilter}
            setAnomalyFilter={setAnomalyFilter}
            defaultChecked
          />
          <Divider orientation="vertical" className={classes.divider} />
          <ToolbarToggleButton
            icon="#icn_battery_alert"
            lit={anomalyFilter === 'Low Battery'}
            onClick={e => {
              handelAnomalyFilterChange(e, 'Low Battery')
            }}
          />
          <ToolbarToggleButton
            icon="#icn_signal_wifi_off"
            lit={anomalyFilter === 'Lost Connection'}
            onClick={e => {
              handelAnomalyFilterChange(e, 'Lost Connection')
            }}
          />
          <Hidden smDown>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={state.ui.projectMapFullScreen}
                  onChange={toggleFullScreenProjectMap}
                />
              }
              className={classes.fullScreenToggle}
              label="Full Screen"
              labelPlacement="start"
            />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              size="small"
              onClick={openTempDrawerMapOverlays}
              className={classes.openOverlayDrawerButton}
            >
              <SvgIcon color="secondary">
                <svg>
                  <use xlinkHref={Icons + '#icn_tree'} />
                </svg>
              </SvgIcon>
            </IconButton>
          </Hidden>
        </>
      )}
    </Toolbar>
  )
}

export default ProjectToolbar
